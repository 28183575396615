import {
    AfterModelInit,
    Cast,
    CastType,
    IDataModel,
    Ignore,
    InitialValue,
    Model,
    OnExport,
} from '@klickdata/core/application';
import { Customer } from '@klickdata/core/customer';
import { ResourceTagData } from '@klickdata/core/resource';
import { Staff } from '@klickdata/core/resource/src/resource.model';
import { User } from '@klickdata/core/user/src/user.model';

export interface TaskData extends IDataModel {
    id?: number;
    created_at?: string;
    deadline?: string;
    author?: any;
    contact?: any;
    tags?: ResourceTagData[];
    customers?: Customer[];
    creator?: User;
    body?: string;
    user_id?: number;
    customer_id?: number;
    customer_ids?: number[];
    action?: string;
    type?: string;
    priority?: string;
    communication?: string;
    note?: string;
    todoTask?: string[];
    done?: boolean;
    public?: boolean;
    private?: boolean;
    children_count?: number;
    parent_id?: number;
    tag_ids?: number[];
    staff?: Staff;
    managers?: any[];
    client_contact?: {};
    user_contact?: {};
}
export class Task extends Model<TaskData> implements AfterModelInit, OnExport {
    id: number;
    body: string;
    author: any;
    creator: User;
    contact: any;
    // @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    updated_at: moment.Moment;
    // @Cast(CastType.MOMENT)
    deadline: string;
    @InitialValue([])
    action: string;
    type: string;
    priority: string;
    communication: string;
    todoTask: string[];
    customers?: Customer[];
    assignees?: User[];
    done: boolean;
    public: boolean;
    @Ignore()
    private: boolean;
    children_count: number;
    @Ignore()
    loading: boolean;
    @Ignore()
    client_contact: any;
    @Ignore()
    user_contact: any;
    @Ignore()
    displayTaskNotes: boolean;
    @Ignore()
    tags: ResourceTagData[];
    parent_id: number;
    managers: any[];
    tag_ids: number[];
    @Cast(CastType.OBJECT)
    staff: Staff;

    afterModelInit(data: TaskData): void {
        if ('public' in data) {
            this.private = !data.public;
        }
    }

    beforeExport(data: TaskData): void {
        data.public = !data.private;
    }
    afterExport(data: TaskData): void {}
}
