import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LanguageService } from '@klickdata/core/localization';
import { ResourceTag } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { Subject, Observable, map, of, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'app-table-tags-on-the-fly',
    templateUrl: './table-tags-on-the-fly.component.html',
    styleUrls: ['./table-tags-on-the-fly.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTagsOnTheFlyComponent implements OnInit, AfterViewInit, OnDestroy {
    private _tagsIds: number[];
    @Input() set tags(tags: ResourceTag[] | number[]) {
        if (!!tags?.length && typeof tags[0] === 'number') {
            this._tagsIds = tags as number[];
            this.tagsControl.patchValue(this._tagsIds);
        } else if (!!tags?.length) {
            this._tagsIds = tags.map((tag) => tag.id);
            this.tagsControl.patchValue(this._tagsIds);
        }
    }
    @Input() currentLaguageId: number;
    @Input() canRemoveTags = false;
    @Output() onUpdateTags: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() onTagClick: EventEmitter<ResourceTag> = new EventEmitter<ResourceTag>();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public tagsControl = new FormControl([]);
    public currentLaguageId$: Observable<number>;
    constructor(protected langService: LanguageService) {}

    ngOnInit(): void {
        this.currentLaguageId$ = Number(this.currentLaguageId)
            ? of(this.currentLaguageId)
            : this.langService
                  .getLanguageByKey(this.langService.getCurrentLanguage().value)
                  .pipe(map((lang) => lang.id));
    }
    ngAfterViewInit(): void {
        this.tagsControl.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                filter((ids) => !Utils.arraysEqual(ids, this._tagsIds))
            )
            .subscribe((tagsIds) => this.onUpdateTags.emit(tagsIds));
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
