import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Host,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { AddLinkDialogComponent } from '@klickdata/core/application/src/add-link-dialog/add-link-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { MediaType } from '@klickdata/core/media/src/media-type';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { MessageService } from '@klickdata/core/message';
import { AppScope, Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItemData } from '@klickdata/core/resource-item';
import { Currency, ResourceEventType } from '@klickdata/core/resource/src/resource.model';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    share,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { EventManagerCoreComponent } from '../event-manager-core.component';

@Component({
    selector: 'app-event-manager-core-secondary-controls',
    templateUrl: './event-manager-core-secondary-controls.component.html',
    styleUrls: ['./event-manager-core-secondary-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class EventManagerCoreSecondaryControlsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() resource: Resource;
    public brochureRadio = new FormControl('file');
    public trailerRadio = new FormControl('file');
    public itemsHaveTests: boolean;
    public mandatoryTestResourceItems: Observable<ResourceItemData[]>;
    public resourceForm: FormGroup;
    public defaultResourceSummaryCount: number;
    public defaultResourceOnlineEventId: number;
    public maxResourceInstructors: number;
    public defaultEventGoalCount: number;
    public isArticleCodeValid: Observable<boolean>;
    public currencies: Currency[];
    ResourceStaffRoles = ResourceStaffRoles;
    public playTimeOptions = {
        zeroValue: null,
        showPreview: false,
        showYears: false,
        showMonths: false,
        showDays: false,
        showWeeks: false,
    };
    public durationOptions = {
        zeroValue: null,
        showPreview: false,
        showYears: false,
        showSeconds: false,
    };
    dropSize = '200px';
    public get dropStyle() {
        return {
            height: this.dropSize,
            width: this.dropSize,
            flex: `0 1 ${this.dropSize}`,
        };
    }
    vaildArticleCode: Observable<number>;
    public instructorUploading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    AppScope = AppScope;
    ResourceTypes = ResourceTypes;
    public eventTypes: { value: ResourceTypes; label: string }[];
    public destroy: Subject<boolean> = new Subject<boolean>();
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public unlimited_seats = new FormControl(true);

    constructor(
        @Host() protected host: EventManagerCoreComponent,
        protected parentFormDirective: FormGroupDirective,
        protected configService: ConfigService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected auth: AuthService,
        protected s3mediaService: S3MediaService,
        protected mediaService: MediaService,
        protected bottomSheet: MatBottomSheet,
        protected messageService: MessageService
    ) {
        this.currencies = Utils.getAllCurrencies();
        this.defaultResourceSummaryCount = this.configService.config.defaultResourceSummaryCharCount;
        this.defaultEventGoalCount = this.configService.config.defaultResourceGoalCharCount;
        this.defaultResourceOnlineEventId = this.configService.config.defaultResourceOnlineEventId;
        this.maxResourceInstructors = this.configService.config.maxResourceInstructors;
        this.resourceForm = parentFormDirective.form;
        this.eventTypes = ResourceTypes.getEventTypes();
        this.buildForm(this.resourceForm);
    }

    ngOnInit(): void {
        this.vaildArticleCode = this.resourceForm.get('article_code').valueChanges.pipe(
            filter((query) => !!query && typeof query === 'string'),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((query) => {
                if (query) {
                    return this.resourceService.checkFieldValidaty({ article_code: query });
                }
            }),
            map((res) => (res ? 1 : -1)),
            share(),
            tap((isValidCode) =>
                this.resourceForm.get('article_code').setErrors(isValidCode === -1 ? { incorrect: true } : null)
            ),
            takeUntil(this.destroy)
        );
        this.unlimited_seats.valueChanges.pipe(takeUntil(this.destroy)).subscribe((unlimited) => {
            if (unlimited) {
                this.resourceForm.get('total_seats').setValue(0);
                this.resourceForm.get('total_seats').disable();
            } else {
                this.resourceForm.get('total_seats').enable();
            }
            this.resourceForm.get('total_seats').markAsDirty();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.resource) {
            this.updateForm();
        }
    }

    public toggleLoading(ev: boolean) {
        ev ? this.instructorUploading.next(true) : this.instructorUploading.next(false);
    }

    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }
    public addLink() {
        this.dialog
            .open(AddLinkDialogComponent, {
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result),
                switchMap((url) => {
                    return this.mediaService.uploadMediaByLink({ link: url, type: MediaType.LINK });
                })
            )
            .subscribe(
                (media) => {
                    // this.saving.emit(false);
                    // this.mediaItems.push(media);
                    // this.mediaIds.push(Number(media.id));
                    // this.onUploadOrRemoveCompleted.emit(true);
                    this.cdRef.markForCheck();
                },
                (err) => {
                    if (err.error.error) {
                        // this.onResponseError(err);
                        // this.onError.emit(err);
                        // this.onUploadOrRemoveCompleted.emit(true);
                    }
                }
            );
    }
    public prepareFile(event, controlName) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.fileHandler(fileList[0], controlName);
        }
    }

    public fileHandler(file: File, controlName: string) {
        this.s3mediaService
            .uploadMediaToS3(file, this.progress$)
            .pipe(
                takeUntil(this.destroy),
                switchMap((media) =>
                    this.mediaService.uploadMediaByLink({
                        ...media.getData(),
                        scope_id: AppScope.EVENT,
                    })
                )
            )
            .subscribe(
                (media) => {
                    // this.saving = false;
                    if (media) {
                        this.resourceForm.get('medias').get(controlName).patchValue([media.id]);
                        this.resourceForm.markAsDirty();
                        // this.media = mediaKey;
                        this.cdRef.markForCheck();
                    }
                },
                (err) => {} // TODO on error
            );
    }
    private buildForm(resForm: FormGroup) {
        resForm.addControl('goal', new FormControl(''));
        resForm.addControl('time_limit', new FormControl(''));
        resForm.addControl('duration', new FormControl(''));
        resForm.addControl('other_info', new FormControl(''));
        resForm.addControl('price', new FormControl(''));
        resForm.addControl('promo_code', new FormControl(''));
        resForm.addControl('finish_msg', new FormControl());
        resForm.addControl('article_code', new FormControl(''));
        resForm.addControl('subtitles', new FormControl(''));
        resForm.addControl('show_publisher_logo', new FormControl(0));
        resForm.addControl('total_seats', new FormControl({ value: '', disabled: true }));
        resForm.addControl('audience', new FormControl(''));
        resForm.addControl('pre_skills', new FormControl(''));
        resForm.addControl('episode', new FormControl(''));
        resForm.addControl('reply_msg', new FormControl(''));
        resForm.addControl('extra_seats', new FormControl(''));
        resForm.addControl('copyrights', new FormControl(''));
        resForm.addControl('priority', new FormControl(''));
        resForm.addControl('external_document', new FormControl(''));
        resForm.addControl('video_document', new FormControl(''));
        resForm.addControl('currency', new FormControl('USD'));
        resForm.addControl(
            'examination',
            this.fb.group({
                type: ['Participation'],
                grade_system_id: [],
                final_test_id: [],
                diploma_level: [],
            })
        );
        resForm.addControl(
            'collaboration',
            this.fb.group({
                view: [true],
                mandatory: [false],
                enroll_approval_required: [true],
                signoff_required: [false],
            })
        );
    }
    private updateForm() {
        const data = this.resource.getData();
        if (data.duration) {
            this.resourceForm.patchValue({ duration: moment.duration({ s: <number>data.duration }).toISOString() });
        }
        if (data.time_limit) {
            this.resourceForm.patchValue({ time_limit: moment.duration({ s: <number>data.time_limit }).toISOString() });
        }
        this.resourceForm.patchValue(data);
        FormHelper.resetForm(this.resourceForm);
    }

    authorizeUsers() {
        this.host.openCollaborationSheet();
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
