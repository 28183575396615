import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { IModel } from '@klickdata/core/application';
import { CanComponentDeactivate } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { TableFilterComponent, TableHttpService, TableSource } from '@klickdata/core/table';
import { Subject } from 'rxjs';
import { filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class SelectModelBaseDirective<T extends IModel>
    implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy
{
    protected abstract sync_all_key: string;
    protected abstract attachKey: string;
    protected abstract detachKey: string;
    protected abstract columns: string[];
    protected abstract accessForm: FormGroup;
    protected abstract referenceFilter: string;
    protected referenceIdKey = 'id';

    public saved = false;
    public saving = false;
    protected destroy = new Subject<boolean>();
    public dataSource = new TableSource<T>();
    // Table components
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    private totalAttached: number;
    private totalDisabled: number;
    isMobile: boolean;
    private loaded: boolean;
    private masterChecked: boolean;
    @Output() onLatestData: EventEmitter<T[]> = new EventEmitter();
    @Input() defaultSorting = 'checked';

    constructor(protected datatableService: TableHttpService<T>, protected mobileService: MobileService) {}

    /**
     *
     */
    public ngOnInit(): void {
        this.mobileService
            .isMobile()
            .pipe(shareReplay(), takeUntil(this.destroy))
            .subscribe((mobile) => (this.isMobile = mobile));

        this.dataSource.service = this.datatableService;
        this.dataSource.latestData.pipe(takeUntil(this.destroy)).subscribe((res) => {
            this.totalAttached = res.more?.reference_count || 0;
            this.totalDisabled = res.more?.disabled_count || 0;
        });
    }

    // handled by child
    protected abstract refresh(id: any): void;

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.filter = this.filter;
        this.dataSource.paginator = this.paginator;

        // init from current id
        this.initFromCurrentId();

        // handle filters and active
        this.handleActiveFilters();

        this.detectIdKeyValueChange();

        this.dataSource.latestData.pipe(takeUntil(this.destroy)).subscribe((res) => this.onLatestData.emit(res.data));
    }

    protected detectIdKeyValueChange() {
        this.idKey.valueChanges
            .pipe(
                takeUntil(this.destroy),
                filter((id) => !!id)
            )
            .subscribe((id) => {
                if (!this.loaded) {
                    this.refresh(id);
                    this.loaded = true;
                } else {
                    this.dataSource.refresh();
                }
            });
    }

    /**
     * Return reference IdKey form control
     */
    private get idKey() {
        return this.accessForm.get(this.referenceIdKey);
    }

    private handleActiveFilters() {
        const filterForm = new FormGroup({});
        this.accessForm.setControl('filters', filterForm, { emitEvent: false });
        this.filter?.filterChange.pipe(takeUntil(this.destroy)).subscribe((filtersApplied) => {
            filtersApplied.filters.forEach((f) =>
                filterForm.setControl(f.property, new FormControl(f.items), { emitEvent: false })
            );
        });
    }

    private initFromCurrentId() {
        const id = this.accessForm.value[this.referenceIdKey];
        if (id) {
            this.refresh(id);
            this.loaded = true;
        }
    }

    selectedFilterChanged() {
        this.accessForm.patchValue({
            [this.sync_all_key]: null,
            [this.attachKey]: [],
            [this.detachKey]: [],
        });
    }

    onSortDataChages(ev: Sort) {
        if (ev.direction === '') {
            this.sort.active = this.defaultSorting;
        }
    }

    reset() {
        if (!this.markAllPristine) {
            this.dataSource.data.forEach((item) => (item.checked = this.mark_all));
        }
        this.selectedFilterChanged();
    }

    masterToggle() {
        this.masterChecked = !this.isChecked();
        this.totalAttached = this.masterChecked ? this.total : 0;
        this.accessForm.patchValue({
            [this.sync_all_key]: this.masterChecked,
            [this.attachKey]: [],
            [this.detachKey]: [],
        });
    }

    selectToggle(model: T) {
        if (this.mark_all || (this.markAllPristine && (model.checked || this.isDetached(model.id)))) {
            const detach = this.toggle(model.id, this.models_detach, this.accessForm.get(this.detachKey));
            this.totalAttached += detach ? -1 : +1;
        } else {
            const attach = this.toggle(model.id, this.models_attach, this.accessForm.get(this.attachKey));
            this.totalAttached += attach ? +1 : -1;
        }
        model.checked = !model.checked;
    }

    private toggle(id: number | string, ids: (number | string)[], control: AbstractControl): boolean {
        const index = ids.findIndex((uid) => uid === id);
        let status = false;
        if (index === -1) {
            ids.push(id);
            status = true;
        } else {
            ids.splice(index, 1);
        }
        control.patchValue(ids);

        return status;
    }

    isChecked(): boolean {
        return this.masterChecked ?? this.selected === this.total;
    }

    modelChecked(model: T) {
        return this.mark_all
            ? !this.isDetached(model.id) && !model.disabled
            : this.markAllPristine
            ? model.checked || this.isAttached(model.id)
            : this.isAttached(model.id);
    }

    isIndeterminate(): boolean {
        return !!this.selected && this.selected < this.total;
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    get selected(): number {
        return this.mark_all
            ? this.total - this.models_detach.length - this.totalDisabled
            : this.markAllPristine
            ? this.totalAttached
            : this.models_attach.length;
    }

    private isAttached(id: number | string) {
        return this.models_attach.indexOf(id) !== -1;
    }

    private isDetached(id: number | string) {
        return this.models_detach.indexOf(id) !== -1;
    }

    get total(): number {
        return this.dataSource?.paginator?.length;
    }

    get models_attach(): (number | string)[] {
        return this.accessForm.get(this.attachKey)?.value || [];
    }

    get models_detach(): (number | string)[] {
        return this.accessForm.get(this.detachKey)?.value || [];
    }

    get mark_all(): boolean {
        return this.accessForm.get(this.sync_all_key).value;
    }

    get markAllPristine(): boolean {
        return typeof this.mark_all !== 'boolean';
    }

    public canDeactivate(): boolean {
        return !(this.models_attach.length || this.models_detach.length || this.mark_all !== null);
    }
}
