import {
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import {
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { ResourceBuilderComponent } from '@klickdata/shared-components';
import { ResourceAccessControlSheetComponent } from '@klickdata/shared-components/src/resource-assignment/resource-access-control-sheet/resource-access-control-sheet.component';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive()
export abstract class ResourceComposerDirective implements OnInit, OnDestroy, OnChanges, CanComponentDeactivate {
    @ViewChild('contentTemplateToBeInParent') contentTemplateToBeInParent: TemplateRef<any>;
    @Input() resource: Resource;
    @Input() isAutoSave = true;
    @Input() disableRouting = false;
    @Output() resourceChange = new EventEmitter<Resource>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onResKeysValidatyChanges: EventEmitter<{ [key: string]: string | boolean }[]> = new EventEmitter<
        { [key: string]: string | boolean }[]
    >();

    public destroy: Subject<boolean> = new Subject<boolean>();
    public abstract typeId: ResourceTypes;
    public isAdmin$: Observable<boolean>;
    public formats: { [key: string]: string };
    public resourceForm: FormGroup;
    public autoSubmitAndDeactivate: boolean;
    public publish: boolean;
    abstract get resourceBuilder(): ResourceBuilderComponent;

    constructor(
        protected resourceService: ResourceService,
        protected categoryService: ResourceCategoryService,
        protected auth: AuthService,
        protected parentFormDirective: FormGroupDirective,
        protected messageService: MessageService,
        protected router: Router,
        protected bottomSheet: MatBottomSheet
    ) {
        this.formats = {
            title: $localize`:@@title:Title`,
            description: $localize`:@@description:Description`,
            grade_system_id: $localize`:@@gradeSystem:Grade system`,
            categories: $localize`:@@categories:Categories`,
            start_date: $localize`:@@startDate:Start date`,
            end_date: $localize`:@@endDate:End date`,
            bullets: $localize`:@@summary:Summary`,
            article_code: $localize`:@@courseCode:Course code`,
            agenda: $localize`:@@agenda:Agenda`,
        };
    }
    ngOnInit(): void {
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isAdmin())
        );
        this.resourceForm = this.parentFormDirective.form;
        this.resourceForm.valueChanges
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.onResKeysValidatyChanges.emit(this.getResKeysValidaty()));
        if (this.isAutoSave) {
            this.setupAutoSave();
        }
    }
    setupAutoSave() {
        this.resourceForm.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(5000),
                filter(() => this.resourceForm.dirty),
                // Don't next as long as condition is equality
                distinctUntilChanged((f1, f2) => Utils.isEqual(f1, f2))
            )
            .subscribe(() => {
                this.isAutoSave = true;
                this.updatePublishStatus();
                this.onSubmit();
            });
    }

    public submit() {
        this.isAutoSave = false;
        this.updatePublishStatus();
        this.onSubmit();
    }
    private updatePublishStatus() {
        this.publish =
            this.resourceForm.value?.publish ||
            this.resourceForm.value.users_attach?.length ||
            this.resourceForm.value.users_detach?.length ||
            this.resourceForm.value.groups_attach?.length ||
            this.resourceForm.value.groups_detach?.length ||
            this.resourceForm.value.sync_all_users ||
            this.resourceForm.value.sync_all_groups;
    }

    public performResSubmit(params?: { [key: string]: any }) {
        if (this.autoSubmitAndDeactivate) {
            this.autoSubmitAndDeactivate = false;
            return;
        }
        this.prepareSubmit(params)
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => this.updateResource(resource));
    }

    public abstract onSubmit(): void;
    public abstract getResKeysValidaty(): { [key: string]: string | boolean }[];
    public abstract prepareSubmit(params?: { [key: string]: any }): Observable<Resource>;
    public abstract checkSubmitValid(): Observable<boolean>;

    protected updateResource(resource: Resource, fromSocket = false) {
        const isNewResource = !this.resource || this.resource.id !== resource.id;
        const updates = !isNewResource ? this.resource.getData(resource.getData()) : resource.getData();
        this.resource = resource;
        this.resourceForm.patchValue(updates, { emitEvent: false });
        if (isNewResource) {
            this.handleRouting();
            FormHelper.resetForm(this.resourceForm);
        } else if (!fromSocket) {
            FormHelper.resetForm(this.resourceForm);
        } else {
            Object.keys(updates)
                .map((key) => this.resourceForm.get(key))
                .filter((ctrl) => !!ctrl)
                .forEach((ctrl) => FormHelper.resetForm(ctrl));
        }

        this.resourceChange.emit(resource);
        this.loading.emit(false);
        this.onResourceChanged(isNewResource);

        if (!this.isAutoSave) {
            this.messageService.openMessage(MessageSavedComponent);
            this.saved.emit(this.resource);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resource && this.resource) {
            const { previousValue: prevResource, currentValue: currResource } = changes.resource;
            const isNewResource = (!prevResource && currResource) || prevResource?.id !== currResource?.id;
            this.onResourceChanged(isNewResource);
        }
    }

    public handleRouting() {
        if (!this.disableRouting) {
            const segments = this.router.url.split('/');
            segments.pop();
            segments.push(this.resource.id.toString());
            this.router.navigate(segments);
        }
    }

    public setDefaultCategory() {
        this.categoryService
            .getSuggestedCategory(this.typeId)
            .pipe(
                takeUntil(this.destroy),
                filter((cat) => cat && !!cat.id)
            )
            .subscribe((category) => {
                if (!this.resourceForm.controls.category_ids.value?.length) {
                    this.resourceForm.patchValue({
                        category_ids: [category.id],
                    });
                    FormHelper.resetForm(<FormControl>this.resourceForm.controls.category_ids);
                }
            });
    }
    public onResourceSuccess(resource: Resource) {
        this.loading.emit(false);
        if (this.isAutoSave && !this.resourceForm.value.id && resource.id) {
            const segments = this.router.url.split('/');
            segments.pop();
            segments.push(resource.id.toString());
            this.router.navigate(segments);
        }
        FormHelper.resetForm(this.resourceForm);
        if (!this.isAutoSave) {
            this.messageService.openMessage(MessageSavedComponent);
            this.resourceForm.reset();
            this.saved.emit(resource);
        }
    }
    public openMessage(component: any, message?: string): void {
        if (!this.isAutoSave) {
            this.messageService.openMessage(component, message);
        }
    }
    public canDeactivate(): boolean {
        const prisitneControls = Object.entries(this.resourceForm.controls).filter((val) => !val[1].pristine);
        if (prisitneControls.length === 0) {
            this.resourceForm.markAsPristine();
        }
        return this.resourceForm.pristine;
    }

    public saveAndDeactivate(): Observable<boolean> {
        this.autoSubmitAndDeactivate = true;
        this.updatePublishStatus();
        return this.checkSubmitValid().pipe(
            switchMap((isValid: boolean) => (isValid ? this.prepareSubmit().pipe(map(() => true)) : of(false)))
        );
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    abstract onResourceChanged(created: boolean): void;

    openCollaborationSheet() {
        this.prepareSubmit()
            .pipe(
                first(),
                tap((resource) => this.updateResource(resource)),
                switchMap((resource) => {
                    const sheet = this.bottomSheet.open(ResourceAccessControlSheetComponent, {
                        data: {
                            context: 'access_control',
                            contextTitle: $localize`Access Control`,
                            contextIcon: 'manage_accounts',
                            resource: resource,
                            title: resource.title,
                        },
                        panelClass: 'sheet-wrapper',
                    });
                    return sheet.afterDismissed();
                }),
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe(() => {
                this.messageService.openMessage(MessageSavedComponent);
                this.resourceForm.markAsDirty();
            });
    }

    protected createOrUpdate(data: ResourceData, params: { [key: string]: any } = {}) {
        if (data.id) {
            const { eager = [] } = params;
            const parsedEager = typeof eager === 'string' ? eager.split(',') : eager;

            return this.patchResource(data, {
                ...params,
                eager: [...parsedEager, 'publication', 'publishment'],
            });
        } else {
            return this.storeResource(data, params);
        }
    }

    protected storeResource(data: ResourceData, params?: { [key: string]: any }): Observable<Resource> {
        return this.resourceService.store(data, params);
    }

    protected patchResource(data: ResourceData, params?: { [key: string]: any }): Observable<Resource> {
        return this.resourceService.update(data, true, params);
    }
}
