<div class="tags-parent" *ngIf="currentLaguageId$ | async; let currentLaguageId">
    <div class="shadow-maker"></div>
    <app-tag-chip-select
        class="w-100 tags-wrap-on-the-fly"
        [language_id]="currentLaguageId"
        [formControl]="tagsControl"
        [removable]="canRemoveTags"
        (onTagClick)="onTagClick.emit($event)"
    ></app-tag-chip-select>
</div>
