import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-custom-fields-switch',
    templateUrl: './custom-fields-switch.component.html',
    styleUrls: ['./custom-fields-switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsSwitchComponent {
    @Input() customFieldForm: FormGroup;

    constructor() {}
}
