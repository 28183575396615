import { Clipboard } from '@angular/cdk/clipboard';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
} from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { HttpErrorService } from '@klickdata/core/http';
import { RecordingOutput } from '@klickdata/core/media/src/media-type';
import { RecorderService } from '@klickdata/core/media/src/recorder.service';
import { MessageFormErrorComponent, MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceMediaTypes } from '@klickdata/core/question/src/question-type/question-type';
import {
    AppScope,
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceSocketService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemData, ResourceItemService, ResourceItemTypes } from '@klickdata/core/resource-item';
import { ResourceComposerDirective } from '@klickdata/core/resource/src/resource-composer-directive';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { ITextCountable, TextCountableDirective, Utils } from '@klickdata/core/util';
import moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-materials-manager-core',
    templateUrl: './materials-manager-core.component.html',
    styleUrls: ['./materials-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MaterialsManagerCoreComponent extends ResourceComposerDirective implements OnInit {
    @ViewChildren(TextCountableDirective) textCountables: QueryList<ITextCountable>;
    // @ContentChildren(MaterialsManagerCoreComponent, { descendants: true })
    // materialComps: QueryList<MaterialsManagerCoreComponent>;
    typeId = ResourceTypes.MATERIAL;
    @Input() active: boolean;
    public recorderOutput: RecordingOutput;
    public selection = new SelectionModel<any>(true, []);
    public showPrompter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public prompt = new FormControl('');
    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    @ViewChild(MatAccordion) accordion?: MatAccordion;
    public totalCharsAndWordsLength = { characters: 0, words: 0 };
    public isMobile: boolean;
    public loadingMaterialsCreation: boolean;
    public expandAll: boolean;
    public opened = 0;
    public duplicatedIndices = [];
    Utils = Utils;
    model_name: string;

    get resourceBuilder() {
        return null;
    }

    constructor(
        protected categoryService: ResourceCategoryService,
        protected auth: AuthService,
        protected parentFormDirective: FormGroupDirective,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected resourceSocketService: ResourceSocketService,
        protected itemService: ResourceItemService,
        protected fb: FormBuilder,
        protected error: HttpErrorService,
        protected clipboard: Clipboard,
        protected mobileService: MobileService,
        protected cdr: ChangeDetectorRef,
        protected element: ElementRef,
        protected recordingService: RecorderService,
        protected router: Router,
        protected bottomSheet: MatBottomSheet
    ) {
        super(resourceService, categoryService, auth, parentFormDirective, messageService, router, bottomSheet);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.resourceForm.addControl('resource_items', new FormArray([]));
        this.items?.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => this.buildTextCount())
            )
            .subscribe();
        this.onDownloadHandler();
        this.mobileService
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMob) => (this.isMobile = isMob));
    }

    public addMaterial() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_QUESTIONS,
            data: {
                type: AppScope.MATERIAL,
                buildingItems: [
                    ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL,
                    ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL,
                    ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL,
                    ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL,
                    ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL,
                ],
                context: 'materialCreation',
                icon: 'add_circle_outline',
                title: $localize`Add Material`,
                resource: this.resource ?? {},
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === AppScope.MATERIAL),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data.resource) {
                    this.saved.emit(data.resource);
                } else {
                    if (data.value === ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL) {
                        this.onRecordMaterial();
                        this.cdr.markForCheck();
                    } else {
                        this.onAddNewMaterial(data.value);
                    }
                }
            });
    }
    public onAddNewMaterial(data: any) {
        switch (data) {
            case ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL:
                this.addNewItem({ item_type_value: 'doc_material' });
                break;
            case ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL:
                this.addNewItem({ item_type_value: 'url_material' });
                break;

            case ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL:
                this.addNewItem({ item_type_value: 'text_material' });
                break;
            case ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL:
                this.showPrompter.next(true);
                break;
            default:
                break;
        }
        this.cdr.markForCheck();
    }

    public onRecordMaterial(): void {
        this.prepareSubmit()
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                this.updateResource(resource);
                this.recordingService.setCurrentResourceId(resource.id);
                this.recordingService.clickRecorderOptionsBtn();
            });
    }
    addLink() {
        this.addNewItem({ item_type_value: 'url_material' }).markAsDirty();
    }
    addFile() {
        this.addNewItem({ item_type_value: 'doc_material' }).markAsDirty();
    }
    addText() {
        this.addNewItem({ item_type_value: 'text_material' }).markAsDirty();
    }
    addImg() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_MEDIA,
            data: {
                icon: 'add_photo_alternate',
                title: $localize`Add an image`,
                description: $localize`Please select your preferred way to add an image.`,
                type: ResourceItemTypes.MATERIAL,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === ResourceItemTypes.MATERIAL),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                this.initImgTB(data);
            });
    }
    private initImgTB(data: any) {
        if (data.value === ResourceMediaTypes.FILE) {
            const imgFormGroup = this.addNewItem({ name: data.media.path, item_type_value: 'doc_material' });
            imgFormGroup.markAsDirty();
        } else {
            this.addAIImg(data.aiText);
        }
    }
    addAIImg(prompt: string) {
        const promptFormGroup = this.addNewItem({ title: prompt, item_type_value: 'doc_material' });
        promptFormGroup.markAsDirty();
        const promptIndex = this.items.length - 1;
        this.prepareResource([promptIndex])
            .pipe(
                takeUntil(this.destroy),
                switchMap((ids) => {
                    this.updateItemsPromptStatus(ids);
                    return this.itemService.regenerateItems(ids, 'image', this.model_name);
                })
            )
            .subscribe();
    }
    handleAIRequestFailure(err) {
        if (err && err.error && err.error.error) {
            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
        }
    }
    private buildTextCount() {
        // this.totalCharsAndWordsLength = this.textCountables.reduce(
        //     (acc, item) => {
        //         acc.characters += item.characters;
        //         acc.words += item.words;
        //         return acc;
        //     },
        //     { characters: 0, words: 0 }
        // );

        this.totalCharsAndWordsLength = { characters: 0, words: 0 };
        this.items.controls.forEach((control: FormGroup) => {
            const name = control.get('name')?.value;
            if (control.get('item_type_value')?.value === 'text_material' && typeof name === 'string') {
                const characters = name.length;
                const words = name.split(/\s+/).length;
                this.totalCharsAndWordsLength.characters += characters;
                this.totalCharsAndWordsLength.words += words;
            }
        });
        this.cdr.markForCheck();
    }

    get tooltipInfo() {
        return [
            $localize`Characters: ${this.totalCharsAndWordsLength.characters}`,
            $localize`words: ${this.totalCharsAndWordsLength.words}`,
        ].join('\n');
    }

    toggleAll(expandAll: boolean) {
        if (expandAll) {
            this.accordion.closeAll();
        } else {
            this.accordion.openAll();
        }
    }

    addFUQ() {
        const promptFormGroup = this.addNewItem({ title: this.prompt.value, item_type_value: 'text_material' });
        promptFormGroup.markAsDirty();
        this.prompt.setValue('');
        const promptIndex = this.items.length - 1;
        this.prepareResource([promptIndex])
            .pipe(
                takeUntil(this.destroy),
                switchMap((ids) => {
                    this.updateItemsPromptStatus(ids);
                    return this.itemService.regenerateItems(ids, 'prompt', this.model_name);
                })
            )
            .subscribe();
    }

    get isItemsProcessing(): boolean {
        return this.items?.controls?.some((ctrl) => ctrl.disabled);
    }

    private updateItemsPromptStatus(ids: number[]) {
        setTimeout(() => ids.forEach((id) => this.items.controls.find((group) => group.value.id === id)?.disable()));
    }

    private prepareResource(indices: number[]): Observable<number[]> {
        const resourceItemsIsDirty = this.items.controls.some(
            (ctl) => ctl.dirty && (ctl.value.id || ctl.value.name || ctl.value.title)
        );
        const shouldPrepareSubmit = !this.resource?.id || resourceItemsIsDirty;
        const obs = shouldPrepareSubmit ? this.prepareSubmit({ eager: 'items' }) : of(this.resource);
        /**
         * Reset opened state
         * @todo Make collapse smooth
         * */
        this.resetOpened();

        return <Observable<number[]>>obs.pipe(
            tap((resource) => this.updateResource(resource)),
            map(() => this.idsFromIndices(indices))
        );
    }

    private resetOpened() {
        this.opened = -1;
        setTimeout(() => {
            this.accordion?.closeAll();
        }, 1000);
    }

    private buildSockets() {
        /**
         * Resource Items socket
         * Items that completed will only return.
         * So It'll be replaced
         * */
        this.itemService
            .listenToResourceItemsUpdate(this.resource)
            .pipe(
                takeUntil(this.destroy),
                filter((items) => !!items.length)
            )
            .subscribe((items) => {
                items.forEach((item) => {
                    if (item.prompt_status == 'ERROR') {
                        this.openMessage(MessageErrorComponent);
                    }
                    this.replaceFormGroupAndOpenItem(item);
                });
                this.selection.clear();
                this.items.markAsDirty();
                this.cdr.markForCheck();
            });

        this.resourceSocketService
            .listenToResourceUpdates({ res_id: this.resource.id })
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => this.updateResource(resource, true));
    }

    replaceFormGroupAndOpenItem(item: ResourceItem) {
        const indexToReplace = this.items.controls.findIndex((control: FormGroup) => control.value.id === item.id);
        if (indexToReplace !== -1) {
            this.items.setControl(indexToReplace, this.createItemFormGroup(item));
            this.openItem(indexToReplace);
        }
    }

    private removeFormArrayIds(ids: number[]) {
        /** itemsToKeep */
        this.items.controls = this.items.controls.filter((item) => !ids.includes(item.value.id));
    }

    get selectedIndices(): number[] {
        return this.selection.selected;
    }

    clearSelection() {
        this.selection.clear();
    }

    download() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.DOWNLOAD,
            data: {
                data: [
                    { value: 'resource_item_csv', label: $localize`Download CSV`, canSee: true },
                    { value: 'resource_item_pdf', label: $localize`Download PDF`, canSee: true },
                    { value: 'resource_item_doc', label: $localize`Download document`, canSee: true },
                ],
                returnValue: true,
            },
        });
    }
    private onDownloadHandler() {
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                takeUntil(this.destroy)
            )
            .subscribe((result) => {
                const ids = this.idsFromIndices(this.selectedIndices);
                if (!Utils.isEmpty(result?.value) && !Utils.isEmpty(ids)) {
                    this.itemService.downlaod(ids, result.value.join()).subscribe((res) => {
                        const file = new Blob([res], { type: res.type });
                        const fileUrl = URL.createObjectURL(file);
                        const anchor = document.createElement('a');
                        anchor.href = fileUrl;
                        anchor.download = `KLMS_${moment().format('yMD_hms')}`;
                        anchor.click();
                        this.clearSelection();
                    });
                }
            });
    }

    delete(indices: number[]) {
        const idsToDelete = this.items.controls
            .filter((item, index) => indices.includes(index) && item.value.id)
            .map((item) => item.value.id);
        /** itemsToKeep */
        this.items.controls = this.items.controls.filter((item, index) => !indices.includes(index) || item.value.id);
        if (idsToDelete.length) {
            this.loading.emit(true);
            this.itemService
                .destroy(idsToDelete)
                .pipe(
                    tap(() => this.removeFormArrayIds(idsToDelete)),
                    tap(() => this.loading.emit(false))
                )
                .pipe(takeUntil(this.destroy))
                .subscribe(() => this.clearSelection());
        } else {
            this.clearSelection();
        }
    }

    makeMaterials() {
        const ids = this.idsFromIndices(this.selection.selected).map((id) => ({ id: id }));
        this.resourceService
            .duplicate(this.resource, { resource_items: ids })
            .pipe(takeUntil(this.destroy))
            .subscribe((newResource) => {
                if (newResource) {
                    this.messageService.openMessage(MessageSavedComponent, $localize`Material created.`);
                }
            });
    }

    /**
     * Translate set of items
     * @param langId language id of the output
     * @param indices index of items to translate
     */
    translate(data: { language_id: number; indices: number[] }) {
        this.loading.emit(true);

        const itemsToTranslate: { index: number; item: ResourceItem }[] = this.items.controls
            .map((ctrl, i) => ({ control: ctrl, index: i }))
            .filter((res) => data.indices.includes(res.index))
            .map((res, i) => ({
                index: res.index + i + 1,
                item: new ResourceItem({
                    ...res.control.value,
                    language_id: data.language_id,
                    parent_id: res.control.value.id,
                }),
            }));

        itemsToTranslate.forEach((itemToTranslate) => this.duplicateItem(itemToTranslate.item, itemToTranslate.index));

        this.prepareResource(itemsToTranslate.map((item) => item.index))
            .pipe(
                switchMap((ids: number[]) => {
                    this.updateItemsPromptStatus(ids);
                    return this.itemService.translateItems(ids, data.language_id, this.model_name);
                }),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.loading.emit(false);
                this.selection.clear();
            });
    }

    regenerate(indices: number[]) {
        this.loading.emit(true);

        this.items.controls
            .filter((ctrl) => !!ctrl.value.id)
            .map((ctrl, i) => ({ control: ctrl, index: i }))
            .filter((res) => indices.includes(res.index))
            .map((res, i) => ({
                index: res.index,
                item: new ResourceItem({ ...res.control.value, parent_id: res.control.value.id }),
            }))
            .forEach((itemToRegenerate) => this.replaceItem(itemToRegenerate.item, itemToRegenerate.index));

        this.prepareResource(indices)
            .pipe(
                switchMap((ids: number[]) => {
                    this.updateItemsPromptStatus(ids);
                    return this.itemService.regenerateItems(ids, 'regenerate', this.model_name);
                })
            )
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.loading.emit(false);
                this.clearSelection();
            });
    }

    /**
     * Duplicate by index.
     * @param index of the item.
     */
    duplicate(index: number) {
        const item = this.items.controls[index].value;
        this.duplicateItem(item, index);
    }

    duplicateItem(item: ResourceItem, index: number) {
        const newItemCtl = this.createItemFormGroup(item);
        this.items.insert(index, newItemCtl);
        newItemCtl.patchValue({ id: null });
        newItemCtl.markAsDirty();
    }

    replaceItem(item: ResourceItem, index: number) {
        this.items.controls[index].get('deleted').patchValue(true);
        this.duplicateItem(item, index);
    }

    merge(indices: number[]) {
        this.loading.emit(true);
        this.prepareResource(indices)
            .pipe(
                takeUntil(this.destroy),
                switchMap((ids: number[]) =>
                    this.itemService.mergeItems(ids).pipe(map((item) => ({ ids: ids, item: item })))
                )
            )
            .subscribe((res) => this.mergeItems(res.ids, res.item));
    }

    public mergeItems(ids: number[], item: ResourceItem) {
        this.clearSelection();
        this.loading.emit(false);
        this.removeFormArrayIds(ids);
        this.items.push(this.createItemFormGroup(item));
        this.openItem();
        this.cdr.markForCheck();
    }

    idsFromIndices(indices: number[]): number[] {
        return this.controlsFromIndices(indices).map((ctl, i) => ctl.value.id || indices[i]);
    }

    controlsFromIndices(indices: number[]): AbstractControl[] {
        return this.items.controls.filter((item, index) => indices.includes(index));
    }

    copyAll() {
        const content = this.controlsFromIndices(this.selectedIndices)
            .reduce((acc, item) => {
                acc.push(
                    new DOMParser().parseFromString(item.value.title, 'text/html').body.textContent,
                    new DOMParser().parseFromString(item.value.name, 'text/html').body.textContent
                );
                return acc;
            }, [])
            .join('\n');
        this.clipboard.copy(content);
        this.messageService.openMessage(
            MessageSavedComponent,
            $localize`Item texts have been copied to the clipboard.`
        );
        this.clearSelection();
    }

    copy(data: ResourceItemData) {
        let plainText = data.name;
        // Replace multiple spaces with a single space
        plainText = plainText.replace(/\s+/g, ' ');

        // Trim leading and trailing spaces
        plainText = plainText.trim();

        // Replace consecutive new lines with a single new line
        plainText = plainText.replace(/\n+/g, '\n');

        // Remove leading and trailing new lines
        plainText = plainText.replace(/^\n|\n$/g, '');

        // Replace HTML tags with appropriate plain text representation
        plainText = plainText.replace(/<\/?(?:p|div|h\d|ul|ol|li|br)>/gi, '\n');

        // Remove remaining HTML tags
        plainText = plainText.replace(/<[^>]+>/g, '');

        this.clipboard.copy(
            new DOMParser().parseFromString(data.title + ' ' + plainText, 'text/html').body.textContent
        );
        this.messageService.openMessage(MessageSavedComponent, $localize`Text copied to clipboard`);
    }
    public addItemsFromChildren(items: ResourceItemData[]) {
        items.forEach((item) => this.addNewItem(item));
    }
    private addNewItem(item: ResourceItemData = {}): FormGroup {
        const group = this.createItemFormGroup(new ResourceItem(item));
        this.items.push(group);
        this.openItem();
        return group;
    }

    private openItem(index: number = -1) {
        this.opened = index !== -1 ? index : this.items.length - 1;
        if (this.prompt.value === '') {
            setTimeout(() => {
                const element =
                    this.element.nativeElement.getElementsByClassName('on-hover-mat-item')[
                        index === -1 && this.isMobile ? this.items.length - 1 : index
                    ];

                // if (element) {
                //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                // }
            }, 500);
        }
        this.cdr.markForCheck();
    }

    public onFollowUp(isFirstTrigger?: boolean) {
        this.showPrompter.next(true);
        setTimeout(() => {
            // if (isFirstTrigger) {
            //     document.getElementById('promptInput').scrollIntoView({ behavior: 'smooth', block: 'center' });
            // }
            this.input?.nativeElement?.focus();
        }, 300);
    }

    isAllSelected() {
        const numSelected = this.selectedIndices.length;
        const numItems = this.items.length;
        return numSelected === numItems;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.items.controls.forEach((control) => this.selection.select(this.items.controls.indexOf(control)));
        this.cdr.markForCheck();
    }

    onResourceChanged(created: boolean) {
        if (created) {
            if (this.resource.items) {
                this.initResourceItems(this.resource.items);
            } else if (this.resource.items_attached && this.resource.items$) {
                this.resource.items$.pipe(takeUntil(this.destroy)).subscribe((items) => this.initResourceItems(items));
            } else {
                this.listenToRecorder();
            }
            this.buildSockets();
        } else if (this.resource.items) {
            this.initResourceItems(this.resource.items);
        }
    }

    initResourceItems(items: ResourceItem[]) {
        this.items.clear();
        items.forEach((item) => {
            const itemFormGroup = this.createItemFormGroup(item);
            if (item.isProcessing()) {
                itemFormGroup.disable();
            }
            FormHelper.resetForm(itemFormGroup);
            this.items.push(itemFormGroup);
        });
        this.opened = this.items.controls.findIndex((control) => control.enabled);
        this.listenToRecorder();
        this.cdr.markForCheck();
    }
    private listenToRecorder() {
        this.recordingService.recordedSub
            .asObservable()
            .pipe(
                switchMap((recorderOutput) =>
                    this.recordingService
                        .getUsingRecordSubmitStatus()
                        .pipe(switchMap((status) => (status ? of(recorderOutput) : of(null))))
                ),
                takeUntil(this.destroy),
                filter((output) => !!output)
            )
            .subscribe((output) => {
                this.resourceForm.patchValue({ title: this.resourceForm.value.title || output.title });
                this.addNewItem({ item_type_value: 'doc_material', content: output.file });
                this.recordingService.setUsingRecordSubmitStatus(false);
            });
    }
    public cleanControl() {
        if (this.items) {
            this.resourceForm.removeControl('resource_items');
        }
    }

    public onSubmit(): void {
        if (this.resource) {
            this.loading.emit(true);
            this.checkSubmitValid();
        } else {
            this.performResSubmit({ eager: 'items' });
        }
    }

    checkSubmitValid(): Observable<boolean> {
        if (!this.resourceForm.valid) {
            FormHelper.markForm(this.resourceForm);
            this.openMessage(MessageFormErrorComponent);
            this.loading.emit(false);
            return of(false);
        }
        if (this.publish && this.resourceForm.value.resource_items === '') {
            FormHelper.markForm(this.resourceForm);
            this.openMessage(
                MessageErrorComponent,
                $localize`You can't publish the material before adding the material text`
            );
            this.loading.emit(false);
            return of(false);
        }
        super.performResSubmit();
        return of(true);
    }

    public prepareSubmit(params?: { [key: string]: any }): Observable<Resource> {
        return this.prepareData().pipe(
            switchMap((data) => this.createOrUpdate(data, params)),
            takeUntil(this.destroy)
        );
    }

    protected storeResource(data: ResourceData, params?: { [key: string]: any }): Observable<Resource> {
        return this.resourceService.createResourceTemplate(data, params);
    }

    protected prepareData(): Observable<ResourceData> {
        const data = this.resource
            ? this.resource.getData(this.resourceForm.value)
            : new Resource(this.resourceForm.value).getData();
        if (this.items.dirty) {
            data.resource_items = this.prepareMaterialItems();
        } else {
            delete data.resource_items;
        }

        if (this.publish) {
            if ((data && data.published) || (this.resource && this.resource?.published)) {
                data.last_publish = moment().format('YYYY-MM-DD HH:mm:ss');
            } else {
                data.published = moment().format('YYYY-MM-DD HH:mm:ss');
            }
        }

        if (!this.publish && data) {
            data.last_publish = null;
        }

        if (!this.resource?.id) {
            return combineLatest([this.auth.getCustomer(), this.auth.getUser()]).pipe(
                first(),
                map(([customer, user]) => {
                    data.author_id = user.id;
                    data.customer_id = customer.id;
                    data.type_id = ResourceTypes.GeneralMaterial;
                    return data;
                })
            );
        } else {
            return of(data);
        }
    }
    protected prepareMaterialItems(): ResourceItemData[] {
        return this.items.controls
            .filter((ctl) => ctl.value.id || ctl.value.name || ctl.value.title)
            .map(this.mapFormItem);
    }
    private mapFormItem(item: AbstractControl): ResourceItemData {
        const itemData: ResourceItemData = {};
        if (item.value.id) {
            itemData.id = item.value.id;

            if (item.get('name').dirty) {
                itemData.name = item.value.name;
            }
            if (item.get('title').dirty) {
                itemData.title = item.value.title;
            }
        } else {
            itemData.item_type_value = item.value.item_type_value;
            if (item.get('name').value) {
                itemData.name = item.value.name;
            }
            if (item.get('title').value) {
                itemData.title = item.value.title;
            }

            if (item.get('language_id').value) {
                itemData.language_id = item.value.language_id;
            }

            if (item.get('parent_id').value) {
                itemData.parent_id = item.value.parent_id;
            }
        }
        if (item.get('text_validation').dirty) {
            itemData.text_validation = item.value.text_validation;
        }
        if (item.get('deleted').value) {
            itemData.deleted = item.value.deleted;
        }

        return itemData;
    }

    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`Material title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`Material language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`Material categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            {
                key: 'items',
                title: $localize`Material text`,
                hasValidValue: this.items?.value !== '',
                mandatory: true,
            },
            {
                key: 'media_id',
                title: $localize`Material image`,
                hasValidValue: this.resourceForm.value.media_id,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`Material tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`Material Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: false,
                info: $localize`The short summary of this test that will be displayed udner the title (max 256 characters).`,
            },
            {
                key: 'description',
                title: $localize`Material description`,
                info: $localize`A description of the test that will be visible befor you take the test.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this test.`,
            },
        ];
    }

    get items() {
        return this.resourceForm.get('resource_items') as FormArray;
    }

    drop(event: CdkDragDrop<any[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.items.markAsDirty();
        this.performResSubmit({ eager: 'items' });
    }

    private createItemFormGroup(item: ResourceItem): FormGroup {
        return this.fb.group({
            id: item.id,
            name: item.name,
            title: item.title,
            updated_at: item.updated_at,
            text_validation: item.text_validation || 'empty',
            language_id: item.language_id,
            parent_id: item.parent_id,
            total_tokens: item.total_tokens,
            status: item.status,
            item_type_value: item.item_type_value,
            deleted: item.deleted,
            content: item.content,
        });
    }
}
