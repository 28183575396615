import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { ConfigService } from '@klickdata/core/config';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../token/auth.service';
import { Utils } from '@klickdata/core/util';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private authService: AuthService,
        private router: Router,
        private location: Location,
        private configService: ConfigService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.check();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.check();
    }

    canLoad(route: Route): Observable<boolean> {
        return this.check(true);
    }

    protected check(canLoad = false): Observable<boolean> {
        return this.authService.check().pipe(
            first(),
            switchMap((authenticated) => {
                if (!authenticated) {
                    this.authService.openLoginPage(true, this.router, this.location.path());
                }
                return canLoad ? this.authService.getCustomer().pipe(catchError(() => of(true))) : of(true);
            }),
            map((customer) => {
                if (typeof customer === 'boolean') {
                    return customer;
                }
                // Check customer subdomains
                const currSubdomain = Utils.getSubdomain();
                if (
                    this.configService.config.buildMode === 'production' &&
                    !Utils.isEmpty(customer.domains) &&
                    !Utils.isEmpty(customer.subdomains) &&
                    !customer.subdomains.includes(currSubdomain)
                ) {
                    const currHost = Utils.getDomain();
                    const host = customer.domains.includes(currHost) ? currHost : customer.domains[0];

                    // Redirect to subdomain.
                    const subdomain = customer.subdomains[0];

                    // Token
                    const token = localStorage.getItem('token');
                    const newUrl = `https://${subdomain}.${host}${window.location.pathname}?jwtToken=${token}`;
                    // Manually set the new URL
                    window.location.href = newUrl;
                    return false; // Returning false to indicate that redirection is handled
                }
                return true; // Continue with the normal flow
            })
        );
    }
}
