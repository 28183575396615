import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SectionItemComponent } from '../section-item/section-item.component';

@Component({
    selector: 'app-resources-grid-item',
    templateUrl: './resources-grid-item.component.html',
    styleUrls: ['./resources-grid-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesGridItemComponent extends SectionItemComponent {
    @Input() showOccasion: boolean;
    public goToDetails(): void {
        this.router.navigate(['/home/dashboard/resource-details/' + this.resource.id]);
    }
}
