import { Cast, CastType, IDataModel, Ignore, Model } from '@klickdata/core/application/src/model/model-interface';
import { QuestionOppoMetaData } from '@klickdata/core/question/src/question';
import { Resource } from '@klickdata/core/resource';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import { UserData } from '@klickdata/core/user';
import * as moment from 'moment';

export interface ResourceOpportunityData extends IDataModel {
    resource_id?: number;
    parent_id?: number;
    user_id?: string;
    done?: string;
    diploma?: string; // diploma link
    started_at?: string;
    cancelled?: string;
    updated_at?: string;
    created_at?: string;
    deleted_at?: string;
    signoff_at?: string;
    occasion_end_date?: string;
    approved?: boolean;
    evaluation?: Evaluation;

    // handle route continue..
    continue?: boolean;
    metadata?: QuestionOppoMetaData;
}

export class ResourceOpportunity extends Model<ResourceOpportunityData> {
    id: number;
    resource_id: number;
    parent_id: number;
    user_id: string;
    diploma: string;
    @Cast(CastType.MOMENT)
    done: moment.Moment;
    @Cast(CastType.MOMENT)
    started_at: moment.Moment;
    @Cast(CastType.MOMENT)
    cancelled: moment.Moment;
    @Cast(CastType.MOMENT)
    updated_at: moment.Moment;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    @Cast(CastType.MOMENT)
    deleted_at: moment.Moment;
    @Cast(CastType.MOMENT)
    occasion_end_date: moment.Moment;
    // handle route continue.
    continue: boolean;
    approved: boolean;
    // report, downloads
    @Ignore()
    public downloads?: Downloads;
    metadata?: QuestionOppoMetaData;
    @Ignore()
    evaluations: Evaluation[];
    @Ignore()
    evaluation: Evaluation;
    @Ignore()
    resource: Resource;
    @Ignore()
    user: UserData;
    @Ignore()
    has_surveys: boolean;
    @Ignore()
    has_tests: boolean;

    get oppoHasFeedbackOrAssessment(): boolean {
        return !!this.evaluation.body || !!this.evaluation.title || !!this.evaluation.criteria?.length;
    }
}

export interface Evaluation {
    evaluator_id?: number;
    title: string;
    body: string;
    criteria: Criterion[];
    name: string;
    created_at: string;
    approved?: boolean;
}

export interface Criterion {
    color: string;
    label: string;
    value: number;
}

export class ResourceOpportunityWithStatistics extends ResourceOpportunity {}
