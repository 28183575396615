<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.contextTitle"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>
    <div class="wrapper">
        <div class="toggle-wrapper">
            <div class="red" fxLayoutAlign="flex-start center">
                <mat-icon>priority_high</mat-icon>
                <span i18n>Make mandatory for all academy learners.</span>
            </div>
            <mat-slide-toggle #academyMandatory color="warn" [formControl]="mandatoryForm.get('mandatory') | cast">
                <h4 class="red" *ngIf="academyMandatory.checked" i18n>On</h4>
                <h4 *ngIf="!academyMandatory.checked" i18n>Off</h4>
            </mat-slide-toggle>
        </div>
    </div>
    <mat-tab-group class="user-manage-cp-access" fxFlex="0 0 90%" mat-stretch-tabs ngClass.xs="xs">
        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@users">Users</span>
            </ng-template>
            <app-admin-users-select
                referenceFilter="referenceResource"
                [filtersToBeApplied]="[{ property: 'relationType', items: ['mandatory'] }]"
                [accessForm]="mandatoryForm.get('users') | cast"
                [columns]="['select', 'name', 'email', 'started', 'completed']"
                sync_all_key="sync_all"
                attachKey="attach_ids"
                detachKey="detach_ids"
                class="mt-1"
            ></app-admin-users-select>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@groups">Groups</span>
            </ng-template>
            <app-admin-groups-select
                referenceFilter="referenceResource"
                [filtersToBeApplied]="[{ property: 'relationType', items: ['mandatory'] }]"
                [accessForm]="mandatoryForm.get('groups') | cast"
                sync_all_key="sync_all"
                attachKey="attach_ids"
                detachKey="detach_ids"
                class="mt-1"
            ></app-admin-groups-select>
        </mat-tab>
    </mat-tab-group>
</div>
