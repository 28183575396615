import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Resource } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemTypes } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import { ResourceItemOpportunityService } from '@klickdata/player/src/core/resource-item/resource-item.service';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-course-player-results',
    templateUrl: './course-player-results.component.html',
    styleUrls: ['./course-player-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePlayerResultsComponent implements OnChanges {
    @Input() user: User;
    @Input() resource: Resource;
    @Input() occasionId: number;
    @Input() tabsIndex = 2;
    @Input() tabsVisisbility: boolean[] = [true, true, true];
    @Output() retakeCourse: EventEmitter<Resource> = new EventEmitter<Resource>();
    public courseHasTests: boolean;
    public rewardType: 'Participation' | 'Diploma' | 'Certification' = 'Participation';
    public items$: Observable<ResourceItem[]>;
    public opportunity$: Observable<ResourceOpportunity>;

    constructor(
        protected resourceItemService: ResourceItemOpportunityService,
        protected occasionService: OccasionService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.rewardType = this.resource?.examination?.type ?? 'Participation';
        if (this.resource && this.occasionId) {
            this.items$ = this.resourceItemService
                .getAllItems(this.resource.id, this.occasionId)
                .pipe(
                    tap(
                        (items) =>
                            (this.courseHasTests =
                                items.filter((resourceItem) => resourceItem.item_type_value === ResourceItemTypes.TEST)
                                    .length > 0)
                    )
                );
            this.opportunity$ = this.occasionService.getOccasion(this.occasionId);
        }
    }
}
