import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '@klickdata/core/auth';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { AppScope, Resource } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { ShareService } from '@klickdata/shared-components/src/share/share.service';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { WelcomeMessageDialogComponent } from 'apps/klickdata/src/app/shared/email/welcome-message-dialog/welcome-message-dialog.component';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { GeneralStatsResListingService } from './general-stats-res-listing.service';

@Component({
    selector: 'app-general-stats-res-listing',
    templateUrl: './general-stats-res-listing.component.html',
    styleUrls: ['./general-stats-res-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [GeneralStatsResListingService],
})
export class GeneralStatsResListingComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() type_scope_ids: AppScope[];
    @Input() allowExpandedFilterPanel: boolean;
    @Input() context: string;
    @Input() tableFilterItems: Filter<string | number>[];
    @Output() onBrowse: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onPlay: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onDelete: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onAssign: EventEmitter<Resource> = new EventEmitter<Resource>();
    public dataSource = new TableSource<Resource>();
    public columns: string[];
    public isMobile: boolean;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    selection = new SelectionModel<Resource>(true, []);
    public showActionHeader = false;
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    AppScope = AppScope;
    public resourceStatusOptions: StatusFilterOption[];
    public expandedRow: Resource | null;
    public cacheScope: AppScope | null;
    GlobalFilterProperty = GlobalFilterProperty;

    constructor(
        protected resourceService: GeneralStatsResListingService,
        protected mobile: MobileService,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        public shareService: ShareService,
        protected downloadHelper: DownloadHelperService,
        protected resourceOpportunityService: ResourceOpportunityService,
        protected dialog: MatDialog,
        protected messageService: MessageService
    ) {
        this.resourceStatusOptions = [
            { title: $localize`Done`, value: 'done', color: '#3e5365', icon: 'done_all', selected: true },
            { title: $localize`Ongoing`, value: 'ongoing', color: '#ff9961', icon: 'cached', selected: true },
            { title: $localize`Deleted`, value: 'deleted', color: '#ff9961', icon: 'delete_forever', selected: true },
            {
                title: $localize`Not Started`,
                value: 'not_started',
                color: '#93cbd1',
                icon: 'remove_done',
                selected: true,
            },
            { title: $localize`Overdue`, value: 'overdue', color: '#a7a7a7', icon: 'event_busy', selected: true },
            { title: $localize`Cancelled`, value: 'cancelled', color: '#e44a66', icon: 'cancel', selected: true },
        ];

        this.cacheScope = this.auth.checkPlatform('user') ? null : AppScope.RESOURCES;
    }

    public ngOnInit() {
        combineLatest([this.dataSource.latestData, this.mobile.isMobile().pipe(shareReplay())])
            .pipe(
                takeUntil(this.destroy),
                map(([data, isMobile]) => {
                    const columns = [
                        'title',
                        'occasionStatus',
                        'opportunity_end_date',
                        'assigned_by',
                        'opportunity_assign_date',
                        'done',
                        'done_items',
                        'last_action',
                        'examination',
                    ];
                    if (this.context === 'admin') {
                        columns?.splice(2, 0, 'user');
                        columns.splice(columns.indexOf('done_items'), 1);
                        columns.splice(columns.indexOf('assigned_by'), 1);
                        columns.splice(columns.indexOf('examination'), 1);
                        columns.splice(columns.indexOf('opportunity_assign_date'), 1);
                    }
                    this.columns = Utils.getMatchedItemsFromArrays(
                        columns,
                        Utils.getValuedKeysFromArrayOfObjects(data.data)
                    );
                    if (isMobile) {
                        this.isMobile = isMobile;
                        this.columns?.splice(1, 0, 'summary');
                        this.columns?.push('tools');
                        if (this.columns.indexOf('done_items') !== -1) {
                            this.columns.splice(this.columns.indexOf('done_items'), 1);
                        }
                    } else {
                        this.columns.splice(0, 0, 'select', 'columns');
                        this.columns.push('tools_play');
                    }
                    return data;
                })
            )
            .subscribe(() => this.cdRef.markForCheck());
        this.dataSource.service = this.resourceService;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                this.updatefilters();
            });
    }
    private updatefilters() {
        const appliedFilters = [];
        appliedFilters.push(new Filter('scope', this.type_scope_ids));
        if (this.tableFilterItems) {
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(new Filter(filterItem.property, filterItem.items));
            });
        } else {
            appliedFilters.push(new Filter('userOccasions', [this.authUser.id]));
        }
        this.filter.createOrUpdateWithoutEmitFilter(appliedFilters);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Resource): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    public getPlayBtnTxt(resource: Resource): string {
        return resource.occasionStatus === 'ongoing' ? $localize`:@@continue:Continue` : $localize`:@@play:Play`;
    }
    public mailUser(user: User) {
        const dialogRef = this.dialog.open(WelcomeMessageDialogComponent, {
            width: !this.isMobile ? '70%' : '100%',
            data: { users_attach: [user.id], title: user.name },
            disableClose: true,
            panelClass: 'sheet-wrapper',
        });
    }

    public download(resource?: Resource) {
        if (resource?.downloads) {
            this.downloadHelper.downloadWithOptions(resource.downloads);
        } else if (resource) {
            this.messageService.openMessage(
                MessageErrorComponent,
                !resource.done
                    ? $localize`:@@completeToViewCertificate:Complete the resource to view the certificate`
                    : $localize`:@@noCertificateAvailable:No certificate available for this resource`
            );
        } else {
            // TODO handle multiply selection.
            // this.selection.selected
        }
    }

    public deleteCourseFromList(resource: Resource) {
        if (this.onDelete.observed) return this.onDelete.emit(resource);

        this.resourceOpportunityService
            .destroy(resource.opportunity_id)
            .pipe(takeUntil(this.destroy))
            .subscribe({
                complete: () => {
                    this.dataSource.removeById(resource.id);
                    this.messageService.openMessage(
                        MessageSavedComponent,
                        $localize`:@@resoureHasBeenHidden:The resource has been successfully hidden.`
                    );
                },
                error: () => {
                    this.messageService.openMessage(
                        MessageErrorComponent,
                        $localize`:@@someThingWentWrong:Oops! Something went wrong. Please try again later.`
                    );
                },
            });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
