import { AnswerService } from '@klickdata/player/src/core/answers/answer.service';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { map, switchMap } from 'rxjs/operators';
import { ResourceItem, ResourceItemData } from '@klickdata/core/resource-item/src/resource-item';
import { ResourceService } from '@klickdata/player/src/core/resource/resource.service';

@Injectable({
    providedIn: 'root',
})
export class ResourceItemOpportunityService {
    protected resourceUrl: string;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected resourceService: ResourceService,
        protected occasionService: OccasionService,
        protected answerService: AnswerService
    ) {
        this.resourceUrl = `${this.config.config.resourceUrl}items`;
    }

    public getItem(id: number, opportunityId: number): Observable<ResourceItem> {
        return this.builder
            .get<ResourceItemData>(`${this.resourceUrl}/${id}`)
            .param('opportunity', `${opportunityId}`)
            .request()
            .pipe(map(res => this.createItem(res.data)));
    }

    public getItemBySequence(sequence: number, opportunityId: number): Observable<ResourceItem> {
        return this.builder
            .get<ResourceItemData>(`${this.resourceUrl}/findBySequence/${sequence}`)
            .param('opportunity', `${opportunityId}`)
            .request()
            .pipe(map(res => this.createItem(res.data)));
    }

    public getItemByResource(resource_id: number, opportunityId: number): Observable<ResourceItem> {
        return this.builder
            .get<ResourceItemData>(`${this.resourceUrl}/findByResource/${resource_id}`)
            .param('opportunity', `${opportunityId}`)
            .request()
            .pipe(map(res => this.createItem(res.data)));
    }

    public getNextItem(itemId: number, opportunityId: number): Observable<ResourceItem> {
        return this.builder
            .get<ResourceItemData>(`${this.resourceUrl}/${itemId}/next`)
            .param('opportunity', `${opportunityId}`)
            .request()
            .pipe(map(res => this.createItem(res.data)));
    }

    public getCurrentOccasionItem(occasionId: number): Observable<ResourceItem> {
        return this.answerService.getAllAnswers(occasionId).pipe(
            switchMap(answers => {
                if (answers.length > 0) {
                    const answer = answers.pop();
                    return this.getItem(answer.question_item_id, answer.opportunity_id);
                }

                return of(null);
            })
        );
    }

    public getPreviousItem(itemId: number, opportunityId: number): Observable<ResourceItem> {
        return this.builder
            .get<ResourceItemData>(`${this.resourceUrl}/${itemId}/previous`)
            .param('opportunity', `${opportunityId}`)
            .request()
            .pipe(map(res => this.createItem(res.data)));
    }

    public getAllItems(resource_id: number, opportunityId: number): Observable<ResourceItem[]> {
        return (
            this.builder
                .get<ResourceItemData[]>(this.resourceUrl)
                .param('resource', `${resource_id}`)
                .param('opportunity', `${opportunityId}`)
                // .param('limit', '0')
                .request()
                .pipe(map(res => this.mapItems(res.data)))
        );
    }

    public getResouceItems(resource_id: number): Observable<ResourceItem[]> {
        return (
            this.builder
                .get<ResourceItemData[]>(this.resourceUrl)
                .param('resource', `${resource_id}`)
                // .param('limit', '0')
                .request()
                .pipe(map(res => this.mapItems(res.data)))
        );
    }

    protected createItem(data: ResourceItemData) {
        const item = new ResourceItem(data);

        if (item.child_resource_id) {
            item.child_resource = this.resourceService.getResource(item.child_resource_id);
        }
        if (item.child_opportunity_id) {
            item.child_occasion = this.occasionService.getOccasion(item.child_opportunity_id);
        }

        return item;
    }

    protected mapItems(data: ResourceItemData[]): ResourceItem[] {
        return data.map(item => this.createItem(item));
    }
}
