<div [formGroup]="form" *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>tune</mat-icon>
        <div i18n *ngIf="!navData?.data">Create a new field</div>
        <div i18n *ngIf="navData?.data">Edit custom field</div>
    </div>
    <div class="body" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Enter Field name"
            [control]="form.get('name') | cast"
        >
            <label role="input-label" i18n="@@name">Name*</label>
        </app-user-setting-input>
        <app-user-setting-input
            *ngIf="
                form.value.type != FieldType.SINGLE_CHOICE &&
                form.value.type != FieldType.MULTIPLE_CHOICE &&
                form.value.type != FieldType.SWITCH
            "
            type="text"
            i18n-placeholder
            placeholder="Enter Field placeholder"
            [control]="form.get('placeholder') | cast"
        >
            <label role="input-label" i18n="@@placeholder">Placeholder</label>
        </app-user-setting-input>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Field type*</span>
            <mat-form-field class="role-field">
                <mat-select [disabled]="navData?.data" fxFlex="1 1 100%" formControlName="type">
                    <mat-select-trigger>
                        <mat-icon>{{ (form.value.type | formBuilderItemPipe)?.icon }}</mat-icon
                        >&nbsp;{{ (form.value.type | formBuilderItemPipe)?.label }}
                    </mat-select-trigger>

                    <mat-option *ngFor="let type of formBuilderItemTypes" [value]="type">
                        <mat-icon>{{ (type | formBuilderItemPipe).icon }}</mat-icon
                        >{{ (type | formBuilderItemPipe).label }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>

        <ng-container
            *ngIf="
                form.value.type == FieldType.SINGLE_CHOICE ||
                form.value.type == FieldType.MULTIPLE_CHOICE ||
                form.value.type == FieldType.DROPDOWN
            "
            formArrayName="options"
        >
            <div class="w-100 options-sep">Add related options</div>
            <div
                class="w-100"
                *ngFor="let option of optionsFormArray.controls; let i = index"
                fxLayout="column"
                fxLayoutAlign="center center"
                fxLayoutGap="1em"
            >
                <span class="item-title">Option {{ i + 1 }}</span>
                <div class="w-100" fxLayoutAlign="space-between center">
                    <app-user-setting-input
                        type="text"
                        i18n-placeholder
                        placeholder="Option text"
                        [control]="option | cast"
                    >
                        <!-- <label role="input-label">Item {{ i + 1 }}</label> -->
                    </app-user-setting-input>
                    <button *ngIf="i == optionsFormArray.controls.length - 1" mat-icon-button (click)="addNewOption()">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                    <button *ngIf="i != optionsFormArray.controls.length - 1" mat-icon-button (click)="removeOption(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="form.value.type && form.value.name">
            <div class="divider"></div>
            <div class="output w-100" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start start" class="output-label">
                    <mat-icon>preview</mat-icon>
                    <span i18n>Output</span>
                </div>

                <ng-container [ngSwitch]="form.value.type">
                    <app-user-setting-input
                        *ngSwitchCase="FieldType.TEXT_INPUT"
                        type="text"
                        i18n-placeholder
                        [placeholder]="form.value.placeholder"
                        [control]="output | cast"
                    >
                        <label role="input-label">{{ form.value.name }}</label>
                    </app-user-setting-input>
                    <div
                        *ngSwitchCase="FieldType.DROPDOWN"
                        fxLayoutGap="4px"
                        fxLayout="column"
                        class="w-100 input-field-parent select"
                    >
                        <span>{{ form.value.name }}</span>
                        <mat-form-field class="role-field">
                            <mat-label>{{ form.value.placeholder }}</mat-label>
                            <mat-select fxFlex="1 1 100%" [formControl]="output">
                                <mat-option *ngFor="let option of optionsFormArray.controls" [value]="option.value">{{
                                    option.value
                                }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <ng-container *ngSwitchCase="FieldType.DATE">
                        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
                            <span i18n>{{ form.value.name }}</span>
                            <mat-form-field
                                appearance="outline"
                                floatLabel="never"
                                class="w-100"
                                fxFlex="grow"
                                fxFlex="1 1 100%"
                                fxFlex.lt-md="1 1 100%"
                            >
                                <mat-label i18n>{{ form.value.placeholder }}</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="deadlineDatepicker"
                                    (focus)="deadlineDatepicker.open()"
                                    [formControl]="output"
                                />
                                <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #deadlineDatepicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <app-user-setting-input *ngSwitchCase="FieldType.SWITCH" type="switch" [control]="output | cast">
                        <span class="label" role="switch-label">
                            <ng-container>{{ form.value.name }}</ng-container>
                        </span>
                    </app-user-setting-input>
                    <ng-container *ngSwitchCase="FieldType.SINGLE_CHOICE">
                        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <span>{{ form.value.name }}</span>
                            <mat-radio-group class="course-sett-not" fxLayout="column" fxLayoutGap="10px">
                                <mat-radio-button
                                    *ngFor="let option of optionsFormArray.controls"
                                    [value]="option.value"
                                    >{{ option.value }}</mat-radio-button
                                >
                            </mat-radio-group>
                        </div>
                    </ng-container>
                    <div
                        fxLayoutGap="10px"
                        fxLayout="column"
                        fxLayoutAlign="start start"
                        *ngSwitchCase="FieldType.MULTIPLE_CHOICE"
                    >
                        <span>{{ form.value.name }}</span>
                        <mat-checkbox
                            fxLayoutGap="10px"
                            fxLayout="column"
                            *ngFor="let option of optionsFormArray.controls"
                            (click)="$event.stopPropagation()"
                        >
                            {{ option.value }}
                        </mat-checkbox>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            [disabled]="!form.valid"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="onSubmit()"
            mat-raised-button
        >
            <span i18n *ngIf="!navData?.data">Add field</span>
            <span i18n *ngIf="navData?.data">Edit field</span>
        </button>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
            Cancel
        </button>
    </div>
</div>
