import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Resource, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import { ResourceItemOpportunityService } from '@klickdata/player/src/core/resource-item/resource-item.service';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Observable, filter, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-material-items-display-info',
    templateUrl: './material-items-display-info.component.html',
    styleUrls: ['./material-items-display-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialItemsDisplayInfoComponent extends OnDestroyHandler implements OnChanges {
    @Input() occasionId: number;
    @Input() user: User;
    @Input() resource: Resource;
    public parentOpp$: Observable<ResourceOpportunity>;
    public items$: Observable<ResourceItem[]>;
    ResourceTypes = ResourceTypes;
    constructor(
        protected resourceItemService: ResourceItemOpportunityService,
        protected occasionService: OccasionService,
        protected mobile: MobileService,
        protected router: Router
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.items$ && this.occasionId) {
            this.items$ = this.resourceItemService.getAllItems(this.resource.id, this.occasionId);
        }
        if (!this.parentOpp$ && this.occasionId) {
            this.parentOpp$ = this.occasionService.getOccasion(this.occasionId);
        }
    }
    getItemType(type: string) {
        switch (type) {
            case 'text_material':
                return $localize`Text`;
            case 'url_material':
                return $localize`Link`;
            case 'doc_material':
                return $localize`Document`;
            default:
                return $localize`Material`;
        }
    }
    displayCorrectIcon(type) {
        if (type.indexOf('test') !== -1) {
            return 'kd-icon-test';
        } else if (type.indexOf('course_plan') !== -1) {
            return 'kd-icon-task';
        } else if (type.indexOf('survey') !== -1) {
            return 'kd-icon-survey';
        } else if (type.indexOf('course') !== -1) {
            return 'kd-icon-player';
        } else if (type.indexOf('material') !== -1) {
            return 'kd-icon-document';
        }
    }
    public repeatItem(item: ResourceItem) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'play_circle_outline',
                title: $localize`Study again`,
                contentBody: $localize`Do you want to repeat this part or do you want to enroll to the course again?`,
                positiveBtn: $localize`Repeat Material`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                takeUntil(this.destroy),
                filter((action) => action == SideNaveActionsTypes.POSITIVE)
            )
            .subscribe((action) => {
                // this.router.navigate(['/player/resource', resource.id, ResourceTypes.playerRoute(resource.type_id)], {
                //     queryParams: {
                //         queryParams: item ? { position: item.play_position } : {},
                //         referrer: this.location.path(),
                //     },
                // });

                this.router.navigate(['/player/resource', this.resource.id, 'material'], {
                    queryParams: {
                        queryParams: item ? { position: item.play_position } : {},
                        referrer: '/home/dashboard/main',
                    },
                });
            });
    }
}
