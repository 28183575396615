<form [formGroup]="groupForm" *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>today</mat-icon>
        <span i18n>Create Group</span>
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Name"
            [control]="groupForm.get('name') | cast"
        >
            <label role="input-label" i18n>Group Name</label>
        </app-user-setting-input>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
            <span i18n>Expiration Date</span>
            <mat-form-field
                appearance="outline"
                floatLabel="never"
                class="w-100"
                fxFlex="grow"
                fxFlex="1 1 100%"
                fxFlex.lt-md="1 1 100%"
            >
                <mat-label i18n>Enter a valid date or leave empty</mat-label>
                <input
                    matInput
                    [matDatepicker]="expirationDatepicker"
                    (focus)="expirationDatepicker.open()"
                    formControlName="expiration"
                />
                <mat-datepicker-toggle matIconSuffix [for]="expirationDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #expirationDatepicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="action" fxLayout="row-reverse wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container *ngIf="!(loading$ | async)">
            <button
                [disabled]="groupForm.invalid"
                fxFlex="0 0 auto"
                class="mobile-button custom"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Create</span>
            </button>
            <button
                [disabled]="groupForm.invalid"
                fxFlex="0 0 auto"
                class="mobile-button positive"
                (click)="submit(true)"
                mat-raised-button
            >
                <span i18n>Create and Edit</span>
            </button>
        </ng-container>
        <app-kd-logo-loading *ngIf="loading$ | async" size="small"></app-kd-logo-loading>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit(); groupForm.reset()" mat-button>
            <span i18n>Cancel</span>
        </button>
    </div>
</form>
