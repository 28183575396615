import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { More, ResponseData } from '@klickdata/core/http/src/responce/responce';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MessageSent, UserService } from '@klickdata/core/user/src/user.service';
import { Utils } from '@klickdata/core/util';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, first, map, share, switchMap } from 'rxjs/operators';
import { CustomerCategoryService } from './customer-category/customer-category.service';
import { CustomerLicenseService } from './customer-license/customer-license.service';
import { CustomerPublish } from './customer-publish.model';
import { CustomerServiceService } from './customer-service/customer-service.service';
import { Customer, CustomerData, CustomerLanding } from './customer.model';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    protected resourceUrl: string;
    protected customerLogoTypeUrl: string;
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;
    private customersMap = new Map<number, Observable<Customer>>();

    /**
     * CustomerService constructor
     */
    constructor(
        protected auth: AuthService,
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected serviceService: CustomerServiceService,
        protected userService: UserService,
        protected mediaService: MediaService,
        protected licenseService: CustomerLicenseService,
        protected categoryService: CustomerCategoryService
    ) {
        this.resourceUrl = `${config.config.apiUrl}customers`;
        this.customerLogoTypeUrl = `${config.config.apiUrl}auth/customer/landing`;

        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    /**
     * Create a RequestBuilder for fetching customers.
     */
    public getCustomers(query?: string, limit?: number): Observable<Customer[]> {
        return this.builder
            .get<CustomerData[]>(this.resourceUrl)
            .param('query', query)
            .limit(limit)
            .request()
            .pipe(map((res) => this.createCustomers(res)));
    }

    /**
     * Fetch all contacts
     */
    public getCustomersByIds(customer_ids?: number[]): Observable<Customer[]> {
        const request = this.builder.get<CustomerData[]>(this.resourceUrl);
        if (customer_ids?.length) {
            request.param('ids', customer_ids.join(','));
        }
        return request.request().pipe(map((res) => this.createCustomers(res)));
    }

    public getCustomerLogoType(): Observable<CustomerLanding> {
        const subdomain = Utils.getSubdomain();
        let logotype_id: number;
        return this.auth.check().pipe(
            first(),
            switchMap((auth) =>
                auth
                    ? this.auth.getCustomer().pipe(
                          first(),
                          switchMap((customer) => {
                              if (
                                  customer?.medias?.logotype?.length &&
                                  customer?.medias?.logotype[0] &&
                                  logotype_id !== customer.medias?.logotype[0]
                              ) {
                                  logotype_id = customer.medias.logotype[0];
                                  return this.mediaService.getMedia(customer.medias.logotype[0]).pipe(
                                      map((media) =>
                                          media
                                              ? ({
                                                    logotype_url: media.url,
                                                    title: customer.name,
                                                    logotype_padding: customer.logotype_padding,
                                                } as CustomerLanding)
                                              : this.getDefaultLogoType()
                                      )
                                  );
                              } else {
                                  return of(this.getDefaultLogoType());
                              }
                          })
                      )
                    : subdomain
                    ? this.builder
                          .get<CustomerLanding>(this.customerLogoTypeUrl)
                          .param('short_name', subdomain)
                          .request()
                          .pipe(map((res) => (res.data?.logotype_url ? res.data : this.getDefaultLogoType())))
                    : of(this.getDefaultLogoType())
            )
        );
    }

    private getDefaultLogoType(): CustomerLanding {
        return {
            title: 'Klickdata',
            logotype_padding: 0,
            logotype_url: 'assets/images/klickdata-logo-small.png',
            footerLogotype: 'assets/images/logo_liggande_gra.svg',
        };
    }

    public getOwnerCustomers(): Observable<Customer[]> {
        return this.builder
            .get<CustomerData[]>(this.resourceUrl)
            .param('owner', this.user_id)
            .request()
            .pipe(map((res) => this.createCustomers(res)));
    }

    /**
     * Create a new customer.
     */
    public create(customer: CustomerData, eager?: string[]): Observable<Customer> {
        return this.builder
            .post<CustomerData>(this.resourceUrl, customer)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.createCustomer(res.data)));
    }

    /**
     * Update a customer
     */
    public update(customer: CustomerData, eager?: string[]): Observable<Customer> {
        return this.builder
            .put<CustomerData>(`${this.resourceUrl}/${customer.id}`, customer)
            .putEager(eager)
            .request()
            .pipe(map((res) => new Customer(res.data)));
    }

    /**
     * Send publish request for customer to the server.
     */
    public publish(customer: Customer, publish: CustomerPublish): Observable<MessageSent> {
        return this.builder
            .put<MessageSent>(`${this.resourceUrl}/${customer.id}/publish`, publish)
            .request()
            .pipe(map((res) => res.data));
    }

    /**
     * Delete customer
     */
    public destroy(customer: Customer): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${customer.id}`)
            .request()
            .pipe(map((res) => res.data));
    }

    public checkCustomerExistance(term: string): Observable<any> {
        return this.builder
            .get<{ success: boolean }>(`${this.resourceUrl}/exists/${term}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => {
                    return err && err.error && err.error.error ? of(err.error.error.messages.join('/n')) : EMPTY;
                })
            );
    }

    protected createCustomer(customerData: CustomerData): Customer {
        const customer = new Customer(customerData);
        customer.services = this.serviceService.getServices(customer.id);
        customer.manager$ = !Utils.isEmpty(customer.staff?.manager)
            ? this.userService.getUser(customer.staff.manager)
            : EMPTY;
        customer.license = this.licenseService.getLicense(customer.license_type);
        customer.category = this.categoryService.getCategory(customer.category_value);
        customer.administrators = this.userService.getCustomerAdministrators(customer.id);

        return customer;
    }

    protected createCustomers(res: ResponseData<CustomerData[]>): Customer[] {
        return res.data.map((data) => this.createCustomer(data));
    }

    public getCustomer(customer_id: number, eager?: string[]): Observable<Customer> {
        let customer = this.customersMap.get(customer_id);
        if (customer) {
            return customer;
        }
        customer = this.fetchCustomer(customer_id, eager).pipe(
            map((res) => res.data),
            share(),
            catchError(() => of(new Customer({ name: 'Anonymous' })))
        );
        this.customersMap.set(customer_id, customer);
        return customer;
    }

    public fetchCustomer(
        customerId: number,
        eager?: string[],
        param?: { [key: string]: any }
    ): Observable<{ data: Customer; more?: More }> {
        if (!customerId) {
            return of({ data: new Customer() });
        }
        return this.builder
            .get<CustomerData>(`${this.resourceUrl}/${customerId}`)
            .putEager(eager)
            .putParam(param)
            .request()
            .pipe(
                map((res) => ({
                    data: this.createCustomer(res.data),
                    more: res.more,
                }))
            );
    }

    public listenToCustomerUpdates(customerId: number): Observable<Customer> {
        return this.auth
            .listenPrivate(`customer.${customerId}`, 'CustomerUpdateEvent')
            .pipe(map((res: ResponseData<CustomerData>) => this.createCustomer(res.data)));
    }
}
