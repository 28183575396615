import {AccessCapability} from '../can-access.directive';

export namespace GeneralCapability {
    export const NONE                     = 0;
    export const VIEW_KLICK_DATA          = 1 << 0;
    export const VIEW_ADMIN               = 1 << 1;
    export const VIEW_MASTER              = 1 << 2;
    export const IMPERSONATE_OWN_USERS    = 1 << 3;
    export const IMPERSONATE_ALL_USERS    = 1 << 4;
    export const VIEW_OWN_STATISTICS      = 1 << 5;
    export const VIEW_CUSTOMER_STATISTICS = 1 << 6;
    export const VIEW_PLAYER              = 1 << 7;

    export function getName(): string {
        return 'general';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
