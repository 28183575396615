import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Filter, GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { BehaviorSubject, Observable, Subject, combineLatest, map, startWith, switchMap, tap } from 'rxjs';

@Component({
    selector: 'app-resources-grid-base',
    templateUrl: './resources-grid-base.component.html',
    styleUrls: ['./resources-grid-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesGridBaseComponent implements AfterViewInit {
    @Input() tableFilterItems: Filter<any>[];
    @Input() showOccasion: boolean;
    @Input() hasThumbnailHoverEffect: boolean;
    @Input() label: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    GlobalFilterProperty = GlobalFilterProperty;
    public appliedFilters: Subject<Filter<any>[]> = new Subject<Filter<any>[]>();
    public resBlockWidth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public sectionSortingOptions: SelectFilterOption[];
    public resources$: Observable<Resource[]>;
    public resourcesCountInRow: number;
    public pagPageEvent: PageEvent;
    constructor(protected resourceService: ResourceService) {
        this.sectionSortingOptions = Utils.getSectionsSortingOptions();
    }

    ngAfterViewInit(): void {
        this.resources$ = combineLatest([
            this.appliedFilters.asObservable(),
            this.paginator.page.pipe(
                startWith(<PageEvent>{
                    pageIndex: this.paginator.pageIndex,
                    pageSize: this.resourcesCountInRow,
                })
            ),
        ]).pipe(
            switchMap(([filters, pageEvent]) =>
                this.resourceService.getResourcesByFilters(
                    [...filters, ...this.tableFilterItems, { property: 'page', items: [pageEvent.pageIndex] }],
                    pageEvent.pageSize
                )
            ),
            tap((response) => {
                this.pagPageEvent = this.pagPageEvent || {
                    pageSize: this.resourcesCountInRow,
                    length: response.total_count,
                    pageIndex: this.paginator.pageIndex,
                };
                this.updatePaginator(response.total_count);
            }),
            map((response) => response.data)
        );
    }
    onResize(width: number) {
        let limit = 0;
        if (width < 479) {
            limit = 3;
        } else if (width > 480 && width < 839) {
            limit = 4;
        } else if (width > 840 && width < 1147) {
            limit = 5;
        } else if (width > 1148 && width < 1787) {
            limit = 6;
        } else if (width > 1788 && width < 2386) {
            limit = 7;
        } else {
            limit = 8;
        }
        if (this.resourcesCountInRow != limit) {
            this.resourcesCountInRow = limit;
            this.resBlockWidth.next((width - 10) / this.resourcesCountInRow);
            if (this.paginator) {
                this.paginator.pageSize = this.resourcesCountInRow;
                this.pagPageEvent = {
                    ...{ pageIndex: 0 },
                    ...this.pagPageEvent,
                    ...{ pageSize: this.resourcesCountInRow },
                };
                this.paginator.page.emit(this.pagPageEvent);
            }
        }
    }
    private updatePaginator(max: number) {
        this.paginator.length = max;
        const pageOptions = [];

        for (let i = this.resourcesCountInRow; i <= max; i++) {
            if (i % this.resourcesCountInRow === 0 && pageOptions.length <= 3) {
                pageOptions.push(i);
            }
        }
        if (pageOptions[pageOptions.length - 1] !== max) {
            max > 100 ? pageOptions.push(100) : pageOptions.push(max);
        }

        this.paginator.pageSizeOptions = pageOptions;

        // #161 Hack to fix when current paginator page more then total pages paginator doesn't make correction by default.
        // https://github.com/angular/material2/issues/5812
        // https://github.com/angular/material2/issues/10227
        if (this.paginator.pageIndex !== 0 && this.paginator.pageIndex >= this.paginator.getNumberOfPages()) {
            this.paginator.previousPage();
        }
    }
}
