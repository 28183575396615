import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectFilterOption } from '@klickdata/core/table';

@Component({
    selector: 'app-item-change-on-the-fly',
    templateUrl: './item-change-on-the-fly.component.html',
    styleUrls: ['./item-change-on-the-fly.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemChangeOnTheFlyComponent {
    @Input() type = 'select';
    @Input() toolTip: string;
    @Input() capitalize: boolean;
    @Input() selectionOptions: SelectFilterOption[];
    @Input() set value(val: any) {
        this._value = val;
    }
    private _value: any;
    public get value(): any {
        return this._value;
    }
    @Output() onValueChange: EventEmitter<any> = new EventEmitter<any>();
    constructor() {}
}
