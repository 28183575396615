<div matDialogTitle i18n>
    Already logged in
</div>

<div mat-dialog-content i18n>
    This account is already used on another device or browser.<br>
    Confirm that you want to logout other devices.
</div>

<div mat-dialog-actions class="flex">
    <div class="flex-space"></div>
    <button mat-button (click)="onCancel()" tabindex="-1" i18n>Cancel</button>
    <div class="space"></div>
    <button mat-raised-button color="primary" tabindex="2" (click)="onConfirm()" i18n>Confirm</button>
</div>
