import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
    Optional,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from '@klickdata/core/application';
import { AuthService, Login } from '@klickdata/core/auth';
import { Customer, CustomerLanding } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { ErrorResponse } from '@klickdata/core/http';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { DevicesDialogComponent } from '../devices-dialog/devices-dialog.component';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnDestroy, OnInit {
    error = new Subject<ErrorResponse<null>>();
    loading = false;
    loginForm: FormGroup;
    destroy = new Subject<boolean>();
    academies: Customer[];
    public dialogSignInMode = false;
    public customerLanding$: Observable<CustomerLanding>;

    constructor(
        @Optional() protected dialogRef: MatDialogRef<LoginFormComponent>,
        protected auth: AuthService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected fb: FormBuilder,
        protected logger: LoggerService,
        protected dialog: MatDialog,
        protected cdRef: ChangeDetectorRef,
        protected zone: NgZone
    ) {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            short_name: [Utils.getSubdomain(), this.requiredWithAcademies()],
        });
    }
    public ngOnInit() {
        if (this.dialog.getDialogById('signinDialog')) {
            this.dialogSignInMode = true;
        }
        this.route.queryParams
            .pipe(
                takeUntil(this.destroy),
                filter((params) => params.username),
                map((params) => params.username)
            )
            .subscribe((username) => {
                this.loginForm.patchValue({ username: username });
            });

        this.customerLanding$ = this.auth.getCustomerLanding();
    }
    requiredWithAcademies(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.academies && !control.value ? { academyRequired: true } : null;
        };
    }

    onSubmit() {
        this.doLogin();
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    protected openDialog() {
        this.dialog
            .open(DevicesDialogComponent)
            .afterClosed()
            .pipe(takeUntil(this.destroy))
            .subscribe((result) => {
                if (result) {
                    this.doLogin(result);
                }
            });
    }

    /**
     * Send login request.
     */
    protected doLogin(logoutOthers = false) {
        if (this.loginForm.invalid) {
            FormHelper.markForm(this.loginForm);
            return;
        }
        const login = new Login(this.loginForm.value);
        login.logoutOthers = logoutOthers;
        this.loading = true;
        this.auth
            .login(login.getPayload())
            .pipe(takeUntil(this.destroy))
            .subscribe(
                (res) => {
                    this.loading = false;
                    if (Array.isArray(res.data)) {
                        this.zone.run(() => {
                            this.academies = res.data.map((cusData) => new Customer(cusData));
                            this.cdRef.markForCheck();
                        });
                    } else {
                        // Store UserLogin:
                        this.auth.storeLogin();
                        this.close();
                        if (!this.dialogSignInMode) {
                            this.routeAfterLogin();
                        }
                    }
                },
                (error) => {
                    this.loading = false;
                    if (error && error.error) {
                        this.error.next(error);
                    } else {
                        this.logger.error(
                            $localize`We encountered an error, please check your internet connection and reload this page.`
                        );
                    }
                    if (error?.data?.devices) {
                        this.openDialog();
                    }
                }
            );
    }
    public routeAfterLogin() {
        const uri = this.route.snapshot.queryParamMap.get('redirect_uri') || this.auth.homeUrl;
        this.router.navigateByUrl(uri);
    }
    forgotPassword() {
        this.router.navigateByUrl('/guest/password/forget');
        this.close();
    }

    trial() {
        this.router.navigateByUrl('/guest/trial');
        this.close();
    }

    onCancel() {
        const short_name = this.loginForm.get('short_name');
        this.academies = null;
        short_name.setValue(null);
        short_name.setErrors(null);
    }

    private close() {
        if (this.dialogRef) {
            this.dialog.closeAll();
        }
    }
}
