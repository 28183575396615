<mat-accordion multi="false" fxFlex="0 1 100%" displayMode="flat">
    <mat-expansion-panel [expanded]="true" #mainExpansionPanel class="user-manage-course-expantion main-panel">
        <mat-expansion-panel-header>
            <mat-icon>scatter_plot</mat-icon>
            <div [ngClass]="{ 'main-info-label': mainExpansionPanel.expanded }" i18n="@@courseInfo">Course info</div>
        </mat-expansion-panel-header>

        <div fxLayout="column" class="main-body-wrapper">
            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelImages
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>collections</mat-icon>
                    <div
                        [ngClass]="{ 'main-info-label': mainExpansionPanelImages.expanded }"
                        i18n="@@visualAndbranding"
                    >
                        Visual and Branding
                    </div>
                </mat-expansion-panel-header>

                <div class="panel-body-wrapper">
                    <mat-expansion-panel [expanded]="true" #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>add_photo_alternate</mat-icon>
                                <div i18n="@@coverImg">Cover image</div>
                                <mat-icon class="icon-explain" matTooltip="It's the image of the course" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.media_id }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center">
                            <app-media
                                #app_media
                                *ngIf="!resourceForm.get('media_id')?.value"
                                fxFlex="1 1 50%"
                                fxFlex.lt-md="1 1 100%"
                                formControlName="media_id"
                                [scope]="AppScope.COURSE"
                                [hasAIUploader]="true"
                                dropLabel="Drop course image"
                                i18n-dropLabel="@@dropCourseImage"
                            ></app-media>
                            <app-image-view
                                (click)="
                                    showImgModal(
                                        resourceForm.get('media_id')?.value
                                            ? resourceForm.get('media_id')?.value
                                            : 'assets/images/Default_courses_img.png'
                                    )
                                "
                                class="mt-2 mb-2"
                                *ngIf="resourceForm.get('media_id')?.value"
                                fxFlex="0 1 50%"
                                fxFlex.lt-md="0 1 100%"
                                [media_id]="
                                    resourceForm.get('media_id')?.value
                                        ? resourceForm.get('media_id')?.value
                                        : 'assets/images/Default_courses_img.png'
                                "
                                paddingBottom="50%"
                            >
                                <button
                                    class="remove-image"
                                    mat-icon-button
                                    color="warn"
                                    *ngIf="resourceForm.get('media_id').value as mediaId"
                                    matTooltip="Remove image"
                                    i18n-matTooltip
                                    (click)="resourceForm.get('media_id').setValue(null)"
                                    (click)="resourceForm.get('media_id').markAsDirty()"
                                >
                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                </button>
                            </app-image-view>
                        </div>
                    </mat-expansion-panel>
                    <div formGroupName="medias">
                        <mat-expansion-panel #expansionPanelBG class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>photo_size_select_actual</mat-icon>
                                    <div
                                        [ngClass]="{ 'main-info-label': expansionPanelBG.expanded }"
                                        i18n="@@backgroundImg"
                                    >
                                        Background image
                                    </div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="Background for the course in the course details page."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: !!resourceForm.value.medias?.background
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div fxLayoutAlign="center center" fxLayout="row" fxLayout.lt-md="column">
                                <div class="imgWrapper" fxLayout="column" fxLayoutAlign="center center">
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <ng-container
                                            *ngIf="
                                                !resourceForm.get('medias').value.background ||
                                                !resourceForm.get('medias').value.background.length
                                            "
                                        >
                                            <ng-container
                                                [ngTemplateOutlet]="uploadImg"
                                                [ngTemplateOutletContext]="{
                                                    control: 'background',
                                                    label: 'Drop background image'
                                                }"
                                            ></ng-container>
                                            <!-- <app-media
                                                formControlName="background"
                                                [scope]="AppScope.COURSE"
                                                dropLabel="Drop background image"
                                                i18n-dropLabel="@@dropBGImage"
                                            ></app-media> -->
                                        </ng-container>
                                        <ng-container
                                            *ngIf="
                                                resourceForm.get('medias').value.background &&
                                                resourceForm.get('medias').value.background.length
                                            "
                                        >
                                            <ng-container
                                                *ngFor="let mediaBGId of resourceForm.get('medias').value.background"
                                            >
                                                <app-image-view
                                                    (click)="showImgModal(mediaBGId)"
                                                    [media_id]="mediaBGId"
                                                    [proportional]="false"
                                                >
                                                    <button
                                                        class="remove-image"
                                                        mat-icon-button
                                                        color="warn"
                                                        matTooltip="Remove image"
                                                        i18n-matTooltip
                                                        (click)="
                                                            resourceForm.get('medias').get('background').setValue(null);
                                                            resourceForm.get('medias').get('background').markAsDirty()
                                                        "
                                                    >
                                                        <mat-icon class="md-24">delete_outline</mat-icon>
                                                    </button>
                                                </app-image-view>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel #expansionPanelScreenShot class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>center_focus_strong</mat-icon>
                                    <div
                                        [ngClass]="{ 'main-info-label': expansionPanelScreenShot.expanded }"
                                        i18n="@@screenshotImg"
                                    >
                                        Screenshot image
                                    </div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="A thumbnail from the video (or a homepage) This image is a Screenshot from the creator to be uploaded."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: !!resourceForm.value.medias?.screenshot
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div fxLayoutAlign="center center" fxLayout="row" fxLayout.lt-md="column">
                                <div class="imgWrapper" fxLayout="column" fxLayoutAlign="center center">
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <ng-container
                                            *ngIf="
                                                !resourceForm.get('medias').value.screenshot ||
                                                !resourceForm.get('medias').value.screenshot.length
                                            "
                                        >
                                            <ng-container
                                                [ngTemplateOutlet]="uploadImg"
                                                [ngTemplateOutletContext]="{
                                                    control: 'screenshot',
                                                    label: 'Drop screenshot image'
                                                }"
                                            ></ng-container>
                                            <!-- <app-media
                                                formControlName="screenshot"
                                                [scope]="AppScope.COURSE"
                                                dropLabel="Drop screenshot image"
                                                i18n-dropLabel="@@dropScreenshotImage"
                                            ></app-media> -->
                                        </ng-container>
                                        <ng-container
                                            *ngIf="
                                                resourceForm.get('medias').value.screenshot &&
                                                resourceForm.get('medias').value.screenshot.length
                                            "
                                        >
                                            <ng-container
                                                *ngFor="
                                                    let mediaScreenShotId of resourceForm.get('medias').value.screenshot
                                                "
                                            >
                                                <app-image-view
                                                    (click)="showImgModal(mediaScreenShotId)"
                                                    [media_id]="mediaScreenShotId"
                                                    [proportional]="false"
                                                >
                                                    <button
                                                        class="remove-image"
                                                        mat-icon-button
                                                        color="warn"
                                                        matTooltip="Remove image"
                                                        i18n-matTooltip
                                                        (click)="
                                                            resourceForm.get('medias').get('screenshot').setValue(null);
                                                            resourceForm.get('medias').get('screenshot').markAsDirty()
                                                        "
                                                    >
                                                        <mat-icon class="md-24">delete_outline</mat-icon>
                                                    </button>
                                                </app-image-view>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel #expansionPanelpublisher class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>fitbit</mat-icon>
                                    <div
                                        [ngClass]="{ 'main-info-label': expansionPanelpublisher.expanded }"
                                        i18n="@@publisherLogo"
                                    >
                                        Publisher logo
                                    </div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="The displayed logo in the course registeration page, this will affect when you make the course as public, You academy logo will always appear if you published the course."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: !!resourceForm.value.medias?.publisher
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div
                                fxLayoutAlign="start start"
                                fxFlex="0 1 100%"
                                class="mt-1"
                                fxLayout="column"
                                fxLayoutGap="10px"
                                fxLayout.lt-md="column"
                                *ngIf="expansionPanelpublisher.expanded"
                            >
                                <mat-radio-group
                                    [formControl]="resourceForm.get('show_publisher_logo') | cast"
                                    fxLayoutAlign="start center"
                                    fxLayoutGap="70px"
                                    fxLayoutGap.lt-sm="20px"
                                >
                                    <mat-radio-button [value]="true">
                                        <span i18n>Display publisher logo</span>
                                    </mat-radio-button>

                                    <mat-radio-button [value]="false">
                                        <span i18n>Display KlickData Open Library (KOL) logo</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                                <div
                                    *ngIf="resourceForm.value.show_publisher_logo"
                                    class="mt-1 w-100 publisher-logo-wrapper"
                                    fxLayoutAlign="center center"
                                    fxFlex="0 1 100%"
                                >
                                    <app-media
                                        *ngIf="showPublisherMediaUploader"
                                        formControlName="publisher"
                                        [scope]="AppScope.COURSE"
                                        dropLabel="Drop logo image"
                                        [hasAIUploader]="true"
                                        i18n-dropLabel="@@dropLogoImage"
                                    ></app-media>
                                    <ng-container *ngIf="!showPublisherMediaUploader">
                                        <app-image-view
                                            *ngIf="resourceForm.get('medias').value.publisher as mediaId"
                                            [media_id]="mediaId"
                                            [proportional]="false"
                                        >
                                            <button
                                                class="remove-image"
                                                mat-icon-button
                                                color="warn"
                                                matTooltip="Remove image"
                                                i18n-matTooltip
                                                (click)="
                                                    resourceForm.get('medias').get('publisher').setValue(null);
                                                    resourceForm.get('medias').get('publisher').markAsDirty();
                                                    showPublisherMediaUploader = true
                                                "
                                            >
                                                <mat-icon class="md-24">delete_outline</mat-icon>
                                            </button>
                                        </app-image-view>
                                        <ng-container *ngIf="customer$ | async; let customer">
                                            <app-image-view
                                                *ngIf="!resourceForm.get('medias').value.publisher"
                                                [media_id]="customer.logotype_id"
                                                [proportional]="false"
                                            >
                                                <button
                                                    class="remove-image"
                                                    mat-icon-button
                                                    color="warn"
                                                    matTooltip="Remove image"
                                                    i18n-matTooltip
                                                    (click)="
                                                        resourceForm.get('medias').get('publisher').setValue(null);
                                                        resourceForm.get('medias').get('publisher').markAsDirty();
                                                        showPublisherMediaUploader = true
                                                    "
                                                >
                                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                                </button>
                                            </app-image-view>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelMain
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>settings_applications</mat-icon>
                    <div
                        [ngClass]="{ 'main-info-label': mainExpansionPanelMain.expanded }"
                        i18n="@@GeneralCourseInformation"
                    >
                        General Course Information
                    </div>
                </mat-expansion-panel-header>

                <div class="panel-body-wrapper main-info-parent">
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>group_work</mat-icon>
                                <div i18n="@@courseType">Course type</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Where the course take place. Online in KLMS or onsite, like in a classroom, conference, webinar, or blended learning (mixed)."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-radio-group
                            fxLayoutAlign="start center"
                            fxLayoutGap="70px"
                            fxLayoutGap.lt-sm="20px"
                            value="online"
                            class="mt-1"
                        >
                            <mat-radio-button value="online">
                                <span i18n>Online</span>
                            </mat-radio-button>
                            <mat-radio-button [disabled]="true" (click)="onTypeChange()" value="onsite">
                                <span i18n>Onsite</span>
                            </mat-radio-button>
                            <mat-radio-button [disabled]="true" (click)="onTypeChange()" value="hybrid">
                                <span i18n>Hybrid</span>
                            </mat-radio-button>
                            <mat-radio-button [disabled]="true" (click)="onTypeChange()" value="blended">
                                <span i18n>Blended</span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>description</mat-icon>
                                <div i18n="@@description">Description</div>
                                <mat-icon class="icon-explain" matTooltip="Set course description" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.description }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #desc
                                matInput
                                placeholder="Write a description and list what you will learn in this course. Why the learner shall spend time and what will be the benefit of take the Course."
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultCourseGoalCount"
                                formControlName="description"
                            ></textarea>
                            <span *ngIf="desc.value" class="char-count">
                                {{ desc.value.length }}
                                <span>(</span>
                                {{ defaultCourseGoalCount - desc.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #agendaExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>ballot</mat-icon>
                                <div i18n="@@agenda">Agenda</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="List the subjects covered in the course. The content in bullets for the lessons, modules, and classes. Suitable for onsite teaching, classroom, or webinar audience. In short, a list of what you will learn."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.agenda }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <app-text-editor
                            *ngIf="agendaExpansionPanel.expanded"
                            fxFlex="1 1 100%"
                            title="Course agenda"
                            i18n-title
                            [showTemplates]="false"
                            formControlName="agenda"
                        ></app-text-editor>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>task_alt</mat-icon>
                                <div i18n="@@courseGoal">Course goal</div>
                                <mat-icon class="icon-explain" matTooltip="Set course goal" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.goal }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #goal
                                matInput
                                placeholder="Enter a goal for the course"
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultCourseGoalCount"
                                formControlName="goal"
                            ></textarea>
                            <span *ngIf="goal.value" class="char-count">
                                {{ goal.value.length }}
                                <span>(</span>
                                {{ defaultCourseGoalCount - goal.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>ads_click</mat-icon>
                                <div i18n>Audience</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Who is the target for the Course? The person or group who are the learners who will benefit from the knowledge covered in this course."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.audience }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #audience
                                matInput
                                placeholder="Enter audience for the course"
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultCourseGoalCount"
                                formControlName="audience"
                            ></textarea>
                            <span *ngIf="audience.value" class="char-count">
                                {{ audience.value.length }}
                                <span>(</span>
                                {{ defaultCourseGoalCount - audience.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>tips_and_updates</mat-icon>
                                <div i18n>Pre skills</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="The prerequisite knowledge that would be required from the participant prior to the course or the event."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.pre_skills }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #preSkills
                                matInput
                                placeholder="Enter pre skills for the course"
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultCourseGoalCount"
                                formControlName="pre_skills"
                            ></textarea>
                            <span *ngIf="preSkills.value" class="char-count">
                                {{ preSkills.value.length }}
                                <span>(</span>
                                {{ defaultCourseGoalCount - preSkills.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <!-- Playtime and duration -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>hourglass_top</mat-icon>
                                <div i18n="@@playtimeAndDuration">Playtime and duration</div>
                                <mat-icon class="icon-explain" matTooltip="Playtime and duration" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: !!resourceForm.value.time_limit || !!resourceForm.value.duration
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="mb-2" fxLayout="column" fxFlex="0 1 100%" [fxHide]="false">
                            <div
                                fxFlex="0 0 100%"
                                fxLayout="row"
                                fxLayout.lt-md="column"
                                fxLayoutAlign="space-between"
                                class="duration-picker-wrapper"
                            >
                                <div
                                    fxLayout.lt-md="column"
                                    fxLayoutAlign="flex-start center"
                                    fxLayoutAlign.lt-md="flex-start start"
                                    fxFlex="0 0 48%"
                                    fxFlex.lt-md="0 0 100%"
                                >
                                    <span class="controller-title" i18n>Play time</span>
                                    <ngx-duration-picker
                                        formControlName="time_limit"
                                        [options]="playTimeOptions"
                                    ></ngx-duration-picker>
                                </div>
                                <div
                                    fxLayout.lt-md="column"
                                    fxLayoutAlign="flex-start center"
                                    fxLayoutAlign.lt-md="flex-start start"
                                    fxFlex="0 0 48%"
                                    fxFlex.lt-md="0 0 100%"
                                >
                                    <span class="controller-title" i18n>Course duration</span>
                                    <ngx-duration-picker
                                        formControlName="duration"
                                        [options]="durationOptions"
                                    ></ngx-duration-picker>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <!-- Other information -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>info</mat-icon>
                                <div i18n="@@other_info">Other information</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Here you can add other information needed for the users who will take this course"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.other_info }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field fxFlex="0 1 100%">
                            <textarea
                                matInput
                                placeholder="Enter other information"
                                i18n-placeholder
                                formControlName="other_info"
                            ></textarea>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <!-- Sorting code -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>swap_vert</mat-icon>
                                <div i18n="@@other_info">Sorting Code</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="A numerical code that will be used when the Academy admin sorts the catalogue of resources in a specific order"
                                    i18n-matTooltip="@@sortingCodeExplain"
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.sorting_code }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field fxFlex="0 0 25%" fxFlex.lt-md="0 0 60px">
                            <input matInput type="number" min="0" max="100" formControlName="sorting_code" />
                        </mat-form-field>
                    </mat-expansion-panel>
                    <!-- exercise -->
                    <mat-expansion-panel #expansionPanelScreenShot class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>topic</mat-icon>
                                <div i18n>Exercise Documents</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Upload exercise document that belongs to the course for practice. It can be a documents pre-course, during course or post the course."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.medias?.exercise }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center" fxLayout="row" fxLayout.lt-md="column">
                            <div class="alert-media-attach">
                                <app-media-attachment
                                    #uploader
                                    type="file"
                                    dropLabel="Drop documents"
                                    i18n-dropLabel
                                    [formControl]="resourceForm.get('medias.exercise') | cast"
                                    [mediaIds]="resourceForm.value.medias?.exercise"
                                    (onUploadOrRemoveCompleted)="onUploadComplete($event)"
                                    [isCreateForm]="true"
                                    [isIconBtns]="false"
                                ></app-media-attachment>
                                <div fxLayoutAlign="center center" *ngIf="uploader.saving | async">
                                    <app-kd-logo-loading size="small"></app-kd-logo-loading>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <!-- End reward -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>military_tech</mat-icon>
                                <div i18n="@@endReward">End reward</div>

                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Set the reward for this Course. When you have a Final Test in the Course; the learner will receive either a Participation Certificate, a Diploma or a Certification."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.examination?.final_test_id }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                            <form [formGroup]="$any(resourceForm.get('examination'))" fxLayout="column">
                                <div
                                    class="mb-1"
                                    fxLayoutGap.gt-md="2em"
                                    fxLayoutAlign="start center"
                                    fxLayout="row"
                                    fxLayout.lt-md="column"
                                    fxLayoutAlign.lt-md="center start"
                                    fxFlex="1 0 100%"
                                >
                                    <br fxHide.gt-md fxShow.lt-md />
                                    <div
                                        fxLayoutGap.gt-md="5px"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                        fxFlex="1 0 50%"
                                        fxFlex.lt-md="1 0 100%"
                                    >
                                        <span fxFlex="0 0 auto" class="bold" i18n>Final test : </span>
                                        <mat-select
                                            fxFlex="0 0 60%"
                                            fxFlex.lt-md="0 0 auto"
                                            placeholder="Pls select final test"
                                            i18n-placeholder
                                            formControlName="final_test_id"
                                        >
                                            <mat-option
                                                *ngFor="let test of mandatoryTestResourceItems | async"
                                                [value]="test.child_resource_id"
                                            >
                                                {{ test.name }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <br fxHide.gt-md fxShow.lt-md />
                                    <div
                                        fxLayoutGap.gt-md="5px"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                        fxFlex="1 0 50%"
                                        fxFlex.lt-md="1 0 100%"
                                    >
                                        <span fxFlex="0 0 auto" class="bold" i18n>Reward type : </span>
                                        <mat-select
                                            fxFlex="0 0 60%"
                                            fxFlex.lt-md="0 0 100%"
                                            placeholder="Place choose reward type"
                                            i18n-placeholder
                                            formControlName="type"
                                        >
                                            <mat-option value="Participation" i18n="@@participation">
                                                Participation
                                            </mat-option>
                                            <mat-option *ngIf="itemsHaveTests" value="Diploma" i18n="@@diploma">
                                                Diploma
                                            </mat-option>
                                            <mat-option
                                                *ngIf="itemsHaveTests"
                                                value="Certification"
                                                i18n="@@certification"
                                            >
                                                Certification
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div
                                    class="mb-1"
                                    fxLayoutGap.gt-md="2em"
                                    fxLayoutAlign="start center"
                                    fxLayout="row"
                                    fxLayout.lt-md="column"
                                    fxFlex="1 0 100%"
                                    fxLayoutAlign.lt-md="center start"
                                >
                                    <div
                                        fxLayoutGap.gt-md="5px"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                        fxFlex="1 0 20%"
                                        fxFlex.lt-md="1 0 70%"
                                        *ngIf="resourceForm.get('examination').value.type === 'Diploma'"
                                    >
                                        <span fxFlex="0 0 auto" class="bold">
                                            <span i18n="@@diplomaLevel">Diploma level</span>
                                            <mat-icon
                                                class="icon-reward-explain"
                                                matTooltip="Diploma; either you pass the level set as a percentage and get a Diploma or you don’t and get a Participation Certificate."
                                                i18n-matTooltip
                                                >help_outline</mat-icon
                                            >:
                                        </span>

                                        <mat-form-field fxFlex="0 0 25%" fxFlex.lt-md="0 0 60px">
                                            <input
                                                matInput
                                                type="number"
                                                min="0"
                                                max="100"
                                                formControlName="diploma_level"
                                            />
                                            <span class="percent" fxFlex="0 0 auto">%</span>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        fxLayoutGap.gt-md="5px"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                        fxFlex="1 0 70%"
                                        fxFlex.lt-md="1 0 100%"
                                        class="grade-system-wrapper"
                                        *ngIf="resourceForm.get('examination')?.value.type !== 'Participation'"
                                    >
                                        <span fxFlex="0 0 auto" class="bold" fxHide.lt-md="true">
                                            <span i18n="@@gradeSystem">Grade system</span>
                                            <mat-icon
                                                class="icon-reward-explain"
                                                matTooltip="The Grade system is used for different levels of reward passing the Final test. You can define your own Grade System or use a template."
                                                i18n-matTooltip
                                                >help_outline</mat-icon
                                            >
                                            :
                                        </span>

                                        <app-admin-grade-system-control
                                            fxFlex="0 0 70%"
                                            fxFlex.lt-md="0 0 100%"
                                            [gradeControl]="
                                                resourceForm.get('examination')?.get('grade_system_id') | cast
                                            "
                                            [languageId]="resourceForm.get('language_id').value"
                                        >
                                        </app-admin-grade-system-control>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-expansion-panel>
                    <!-- Occasions -->
                    <mat-expansion-panel #occasionExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>calendar_view_month</mat-icon>
                                <div i18n>Occasions</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="The course time on a calendar when onsite, webinar, classroom, or synchronous teaching."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.episode }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <app-text-editor
                            *ngIf="occasionExpansionPanel.expanded"
                            fxFlex="1 1 100%"
                            title="Course occasions"
                            i18n-title
                            [showTemplates]="false"
                            formControlName="episode"
                        ></app-text-editor>
                    </mat-expansion-panel>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelSpecifications
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>content_paste</mat-icon>
                    <div
                        [ngClass]="{ 'main-info-label': mainExpansionPanelSpecifications.expanded }"
                        i18n="@@CourseDeliveryandContent"
                    >
                        Course Delivery and Content
                    </div>
                </mat-expansion-panel-header>

                <div class="panel-body-wrapper">
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>subject</mat-icon>
                                <div i18n="@@courseInstructions">Course instruction</div>
                                <mat-icon class="icon-explain" matTooltip="Set course instruction" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.instructions }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #instructions
                                matInput
                                placeholder="Enter instructions for the course"
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultCourseGoalCount"
                                formControlName="instructions"
                            ></textarea>
                            <span *ngIf="instructions.value" class="char-count">
                                {{ instructions.value.length }}
                                <span>(</span>
                                {{ defaultCourseGoalCount - instructions.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <ng-container formGroupName="staff">
                        <!-- Instructor -->
                        <mat-expansion-panel #educatorExpansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>engineering</mat-icon>
                                    <div i18n>Educator</div>
                                    <mat-icon class="icon-explain" matTooltip="Manage event educators" i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{ green: !!resourceForm.value.staff?.educator?.length }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <app-resource-educator-handler
                                    [educatorRole]="ResourceStaffRoles.EDUCATOR"
                                    *ngIf="educatorExpansionPanel.expanded"
                                    [resource]="resource"
                                    formControlName="educator"
                                    educatorLabel="Educator"
                                    i18n-educatorLabel
                                >
                                </app-resource-educator-handler>
                            </ng-template>
                        </mat-expansion-panel>
                        <!-- Host -->
                        <mat-expansion-panel #hostExpansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>record_voice_over</mat-icon>
                                    <div i18n>Host</div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="The Host is the one on the stage and he is the show or an event's central figure."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{ green: !!resourceForm.value.staff?.host?.length }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <app-resource-educator-handler
                                    *ngIf="hostExpansionPanel.expanded"
                                    class="publisher-edu-handler"
                                    [resource]="resource"
                                    [maxNumberOfEducatore]="1"
                                    formControlName="host"
                                    searchPlaceholder="Search existing host people"
                                    [setPredefinedEducator]="!resourceForm.value.staff?.host"
                                    i18n-searchPlaceholder
                                    [educatorRole]="ResourceStaffRoles.HOST"
                                    i18n-addingLabel
                                    addingLabel="Add a new host"
                                    educatorLabel="Host"
                                    i18n-educatorLabel
                                ></app-resource-educator-handler>
                            </ng-template>
                        </mat-expansion-panel>
                        <!-- Organizer -->
                        <mat-expansion-panel #organizerExpansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>person_4</mat-icon>
                                    <div i18n="@@organizer">Organizer</div>

                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="It's the academy or the company, or it could be a human who organize the event, attendess and help any one"
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: !!resourceForm.value.staff?.organizer?.length
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <div fxLayoutGap="10px" fxLayout="column" fxFlex="0 1 100%">
                                    <app-resource-educator-handler
                                        *ngIf="organizerExpansionPanel.expanded"
                                        class="publisher-edu-handler"
                                        [resource]="resource"
                                        [maxNumberOfEducatore]="1"
                                        formControlName="organizer"
                                        [setPredefinedEducator]="!resourceForm.value.staff?.organizer"
                                        searchPlaceholder="Search existing organizers"
                                        i18n-searchPlaceholder
                                        [educatorRole]="ResourceStaffRoles.ORGANIZER"
                                        i18n-addingLabel
                                        addingLabel="Add a new organizer"
                                        educatorLabel="Organizer"
                                        i18n-educatorLabel
                                    ></app-resource-educator-handler>
                                </div>
                            </ng-template>
                        </mat-expansion-panel>
                    </ng-container>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>timer</mat-icon>
                                <div i18n="@@courseDate">Course date</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Define the start and end date of the course, or make it available all the time"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !resourceForm.value.always_available }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div
                            class="date-row-wrapper mt-1"
                            fxFlex="100%"
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutAlign="space-between center"
                            fxLayoutAlign.lt-md="center start"
                            fxLayoutGap=".5em"
                        >
                            <mat-checkbox
                                #time_open
                                formControlName="always_available"
                                color="primary"
                                i18n="@@alwaysAvailable"
                                >Always available
                            </mat-checkbox>
                            <div
                                class="date"
                                fxFlex="0 0 40%"
                                fxFlex.lt-md="0 0 40%"
                                fxLayoutAlign.gt-md="end center"
                                *ngIf="!time_open.checked"
                            >
                                <mat-form-field fxFlex="0 0 90%">
                                    <input
                                        matInput
                                        formControlName="start_date"
                                        [matDatepicker]="startDate"
                                        placeholder="Available between"
                                        i18n-placeholder="@@availableBetween"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div
                                class="date"
                                fxFlex="0 0 40%"
                                fxFlex.lt-md="0 0 40%"
                                fxLayoutAlign.gt-md="end center"
                                *ngIf="!time_open.checked"
                            >
                                <mat-form-field fxFlex="0 0 90%">
                                    <input
                                        matInput
                                        formControlName="end_date"
                                        [matDatepicker]="endDate"
                                        placeholder="Until"
                                        i18n-placeholder="@@until"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div
                            class="date-error"
                            *ngIf="
                                !resourceForm.value.always_available &&
                                (!resourceForm.value.start_date || !resourceForm.value.end_date)
                            "
                            i18n
                        >
                            Start date and end date are required.
                        </div>
                    </mat-expansion-panel>
                    <!-- Course seats -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>event_seat</mat-icon>
                                <div i18n="@@courseSeats">Course seats</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Total seats can be unlimited or specified"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: !!resourceForm.value.total_seats
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div
                            fxFlex="0 1 100%"
                            fxLayout.lt-md="column"
                            fxLayoutAlign="space-between center"
                            fxLayoutAlign.lt-md="space-between start"
                        >
                            <mat-checkbox
                                #unlimited
                                fxLayoutAlign="start center"
                                fxFlex="0 0 20%"
                                color="primary"
                                i18n="@@unlimited"
                                [formControl]="unlimited_seats"
                                >Unlimited</mat-checkbox
                            >
                            <mat-form-field fxFlex="0 1 80%">
                                <input
                                    matInput
                                    fxLayoutAlign="end center"
                                    type="number"
                                    placeholder="Please set total seats of the course"
                                    i18n-placeholder
                                    formControlName="total_seats"
                                />
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                    <!-- Extra seats -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>weekend</mat-icon>
                                <div i18n="@@extraSeats">Extra seats</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Extra seats activated when seats are full."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.extra_seats }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field fxFlex="0 1 100%">
                            <input
                                matInput
                                type="number"
                                placeholder="Please set extra seats needed for the course"
                                i18n-placeholder
                                formControlName="extra_seats"
                            />
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="prio-icon">low_priority</mat-icon>
                                <div i18n="@@priority">Priority</div>
                                <mat-icon class="icon-explain" matTooltip="Set course priority" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.priority }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <input
                                matInput
                                fxLayoutAlign="end center"
                                type="number"
                                placeholder="Please set priority"
                                i18n-placeholder="@@plsSetPriority"
                                formControlName="priority"
                            />
                        </mat-form-field> -->
                        <mat-form-field class="role-field">
                            <mat-select fxFlex="1 1 100%" formControlName="priority">
                                <mat-option value="A1" i18n>A1</mat-option>
                                <mat-option value="A2" i18n>A2</mat-option>
                                <mat-option value="A3" i18n>A3</mat-option>
                                <mat-option value="B1" i18n>B1</mat-option>
                                <mat-option value="B2" i18n>B2</mat-option>
                                <mat-option value="B3" i18n>B3</mat-option>
                                <mat-option value="C1" i18n>C1</mat-option>
                                <mat-option value="C2" i18n>C2</mat-option>
                                <mat-option value="C3" i18n>C3</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-expansion-panel>

                    <!-- Subtitle languages -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>subtitles</mat-icon>
                                <div i18n="@@subtitles">Subtitle languages</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="If Subtitles are availible in the video for exampel ; Info Copied from TED or YouTube"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.subtitles }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayoutGap="1em" fxLayoutAlign="start center">
                            <app-title-translation
                                type="description"
                                placeholder="Enter language subtitle"
                                i18n-placeholder
                                formControlName="subtitles"
                                class="icon-resource-builder"
                                fxFlex="0 0 auto"
                            ></app-title-translation>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelSpecifications
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>key</mat-icon>
                    <div
                        [ngClass]="{ 'main-info-label': mainExpansionPanelSpecifications.expanded }"
                        i18n="@@AccessControlandAvailability"
                    >
                        Access Control and Availability
                    </div>
                </mat-expansion-panel-header>

                <form [formGroup]="$any(resourceForm.get('collaboration'))" class="panel-body-wrapper">
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>preview</mat-icon>
                                <div i18n>Visibility</div>
                                <mat-icon class="icon-explain" matTooltip="Make visibility for Academy" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.collaboration?.view }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                fxFlex="grow"
                                formControlName="view"
                                fxLayoutAlign="start center"
                                fxLayoutGap="70px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>
                            <button
                                type="button"
                                matTooltip="Specify authorization"
                                i18n-matTooltip
                                class="specify-btn"
                                mat-icon-button
                                (click)="authorizeUsers()"
                            >
                                <mat-icon>manage_accounts</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>priority_high</mat-icon>
                                <div i18n>Mandatory</div>
                                <mat-icon class="icon-explain" matTooltip="Make Mandatory for Academy" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: !!resourceForm.value.collaboration?.mandatory }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                fxFlex="grow"
                                formControlName="mandatory"
                                fxLayoutAlign="start center"
                                fxLayoutGap="70px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>
                            <button
                                type="button"
                                matTooltip="Specify authorization"
                                i18n-matTooltip
                                class="specify-btn"
                                mat-icon-button
                                (click)="authorizeUsers()"
                            >
                                <mat-icon>manage_accounts</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>play_circle</mat-icon>
                                <div i18n>Approval for enroll</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Allow learners to join/take/enroll with or without permission"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: !!resourceForm.value.collaboration?.enroll_approval_required
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                fxFlex="grow"
                                formControlName="enroll_approval_required"
                                fxLayoutAlign="start center"
                                fxLayoutGap="25px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>

                            <button
                                type="button"
                                matTooltip="Specify authorization"
                                i18n-matTooltip
                                class="specify-btn"
                                mat-icon-button
                                (click)="authorizeUsers()"
                            >
                                <mat-icon>manage_accounts</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>assignment_turned_in</mat-icon>
                                <div i18n>Approval /Sign off</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Learners Signoff and get approved by an authorized administrator"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: !!resourceForm.value.collaboration?.signoff_required
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                fxFlex="grow"
                                formControlName="signoff_required"
                                fxLayoutAlign="start center"
                                fxLayoutGap="25px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>
                            <button
                                type="button"
                                matTooltip="Specify authorization"
                                i18n-matTooltip
                                class="specify-btn"
                                mat-icon-button
                                (click)="authorizeUsers()"
                            >
                                <mat-icon>manage_accounts</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel>
                </form>
            </mat-expansion-panel>
            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelSpecifications
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>admin_panel_settings</mat-icon>
                    <div
                        [ngClass]="{ 'main-info-label': mainExpansionPanelSpecifications.expanded }"
                        i18n="@@AdministrativeDetails"
                    >
                        Administrative Details
                    </div>
                </mat-expansion-panel-header>
                <!-- Course code -->
                <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>fingerprint</mat-icon>
                            <div i18n="@@SKU-ArticleNumber">SKU/ Article number</div>
                            <mat-icon
                                class="icon-explain"
                                matTooltip="Course code is a unique field, autogenerated when empty."
                                i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-panel-title>
                        <mat-panel-description fxLayoutAlign="end center">
                            <span
                                matTooltip="Indicator of field change"
                                i18n-matTooltip
                                class="cell-indicator"
                                [ngClass]="{ green: !!resourceForm.value.article_code }"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-form-field fxFlex="0 1 100%">
                        <input
                            matInput
                            placeholder="Please set course code"
                            i18n-placeholder
                            formControlName="article_code"
                        />
                    </mat-form-field>
                    <span *ngIf="resourceForm.get('article_code').dirty">
                        <p *ngIf="vaildArticleCode | async; let isValid; else: smallLoading">
                            <span class="valid" *ngIf="isValid === 1" i18n="@@courseCodeIsValid"
                                >Course code is valid</span
                            >
                            <span
                                class="error"
                                *ngIf="isValid === -1"
                                i18n="@@courseCodeIsTakenPleaseEnterAnotherCourseCode"
                                >Course code is taken, please enter another course code</span
                            >
                        </p>
                    </span>
                </mat-expansion-panel>
                <!-- Copyrights -->
                <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>copyright</mat-icon>
                            <div i18n="@@copyrights">Copyrights</div>
                            <mat-icon class="icon-explain" matTooltip="Set course copyrights" i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-panel-title>
                        <mat-panel-description fxLayoutAlign="end center">
                            <span
                                matTooltip="Indicator of field change"
                                i18n-matTooltip
                                class="cell-indicator"
                                [ngClass]="{ green: !!resourceForm.value.copyrights }"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-form-field class="summary-field" fxFlex="0 1 100%">
                        <textarea
                            matInput
                            placeholder="Enter copyrights for the course"
                            i18n-placeholder
                            minRows="1"
                            formControlName="copyrights"
                        ></textarea>
                    </mat-form-field>
                </mat-expansion-panel>
                <!-- Promo code -->
                <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>card_giftcard</mat-icon>
                            <div i18n="@@promoCode">Promo code</div>
                            <mat-icon class="icon-explain" matTooltip="Promo code" i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-panel-title>
                        <mat-panel-description fxLayoutAlign="end center">
                            <span
                                matTooltip="Indicator of field change"
                                i18n-matTooltip
                                class="cell-indicator"
                                [ngClass]="{ green: !!resourceForm.value.promo_code }"
                            ></span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-form-field fxFlex="0 1 100%">
                        <input
                            matInput
                            placeholder="Please set a promo code for this course"
                            i18n-placeholder
                            formControlName="promo_code"
                        />
                    </mat-form-field>
                </mat-expansion-panel>
            </mat-expansion-panel>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="start center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #uploadImg let-control="control" let-label="label">
    <div fxFlex="0 0 50%" fxFlex.lt-md="0 0 90%" class="media-area" fxLayout="column" fxLayoutAlign="center center">
        <input
            #uploadInput
            class="media-upload-button"
            type="file"
            (change)="fileHandler($event, control)"
            [disabled]="saving"
            accept="image/png, image/jpeg"
        />
        <p>{{ label }}</p>
        <button mat-raised-button (click)="uploadInput.click()" color="primary" i18n>Upload File</button>
    </div>
    <div class="progress-wrapper mt-1" *ngIf="progress$ | async; let progress">
        <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
        <span class="progress-value">{{ progress }}%</span>
    </div>
</ng-template>
