<div class="w-100" resizeObserver (resize)="onResize($event.contentRect?.width)" fxLayout="column">
    <app-table-filter-expansion-panel [expanded]="false" class="grid-expansion" #filterExpansion [hasTopBorder]="false">
        <app-table-filter
            [filtersTogglesInOnBtn]="true"
            [defaultFilter]="GlobalFilterProperty.SEARCH"
            [inGlobalFilter]="true"
            color="accent"
            (filterChange)="appliedFilters.next($event.filters)"
            #filter
        >
            <app-table-search
                toggleBtnLabel="title"
                i18n-toggleBtnLabel
                [inGlobalFilter]="true"
                class="align-space"
                searchFilterRef
            ></app-table-search>
            <app-language-filter
                [inGlobalFilter]="true"
                #langFilter
                fxFlex="0 0 auto"
                fxLayout="row"
                langFilterRef
            ></app-language-filter>
            <app-tag-chip-search-filter
                [inGlobalFilter]="true"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                fxLayoutAlign="start"
                tagsFilterRef
            ></app-tag-chip-search-filter>
            <app-select-filter
                sectionSortingRef
                class="filter-space select-filter"
                label="Sort by"
                i18n-label="@@sortBy"
                [property]="GlobalFilterProperty.SECTION_SORTING"
                [options]="sectionSortingOptions"
                [inGlobalFilter]="true"
            ></app-select-filter>
        </app-table-filter>
    </app-table-filter-expansion-panel>

    <div class="mt-1" fxLayout="row wrap" *ngIf="resources$ | async; let resources; else: loading">
        <app-resources-grid-item
            [showOccasion]="showOccasion"
            [hasThumbnailHoverEffect]="hasThumbnailHoverEffect"
            *ngFor="let resource of resources"
            mode="icons"
            [resource]="resource"
            fxFlex="0 0 auto"
            class="section-item"
            [ngStyle]="{ width: (resBlockWidth | async) + 'px' }"
        ></app-resources-grid-item>
    </div>

    <div fxLayoutAlign="end center">
        <mat-paginator [pageSize]="resourcesCountInRow" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    </div>
</div>
<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
