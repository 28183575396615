<ng-container *ngIf="!(loading$ | async); else loading">
    <ng-container *ngIf="ticket$ | async; let ticket; else: loading">
        <div class="chat-row left" fxLayout="left" fxLayoutAlign="start start" fxLayoutGap="10px">
            <div class="icon-bubble">
                <mat-icon>warning_amber</mat-icon>
            </div>
            <div fxLayout="column" class="mt-1 w-100 chat-bulk" fxLayoutAlign="left" fxLayoutGap="5px">
                <span fxLayout="row" class="time-right"
                    ><span i18n>Ticket</span> &nbsp;
                    <span>({{ ticket?.created_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }})</span></span
                >
                <div class="body-wrapper">
                    <span class="ticket-id"><span i18n>Ticket id: </span>{{ ticket.id }}</span>
                    <span *ngIf="ticket?.subject" class="subject">{{ ticket?.subject }}</span>
                    <span *ngIf="ticket?.description" class="body">{{ ticket?.description }}</span>
                    <ng-container *ngTemplateOutlet="contentsTemp"></ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="ticket.media_ids">
            <app-media-attachment
                fxLayoutAlign="start start"
                [mediaIds]="ticket.media_ids"
                fxLayout="column"
                [isCreateForm]="false"
                class="mt-1 left-media-dialog"
            ></app-media-attachment>
        </div>
    </ng-container>
</ng-container>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #contentsTemp>
    <mat-chip-list *ngIf="contents.length" class="res-chips mb-1" #chipList aria-label="contents selection">
        <mat-chip *ngFor="let content of contents" [selectable]="false">
            <div class="selct-mat-msg" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <app-image-view
                    [smallSpinner]="true"
                    [media_id]="content.media$"
                    [proportional]="true"
                ></app-image-view>
                <span>{{ content.title }}</span>
            </div>
            <button mat-icon-button [routerLink]="['/home/dashboard/resource-details/' + content.id]">
                <mat-icon>visibility</mat-icon>
            </button>
        </mat-chip></mat-chip-list
    >
</ng-template>
