export enum GlobalFilterProperty {
    SEARCH = 'query',
    CATEGORIES = 'categories',
    FOLDERS = 'folders',
    TAGS = 'tags',
    DATE = 'date_range',
    DEADLINE = 'deadline',
    LANGUAGE = 'language',
    USER_ROLE = 'role',
    GROUPS = 'groups',
    USERS = 'users',
    SELECTED = 'select',
    CUSTOMERS = 'customer',
    SECTION_SORTING = 'sort',
    STATUS = 'status',
    AUTHOR = 'author',
    TIME_SPENT = 'begin',
    CATALOG_SOURCE = 'source',
    MESSAGE_USER = 'user',
    MESSAGE_SCOPEID = 'searchType',
    MESSAGE_SUBJECT = 'subject',
    SCOPE = 'scope',
    LOG_EVENT = 'event',
    EDUCATOR = 'educator',
    TASK_ACTION = 'action',
    TASK_DONE = 'done',
    TASK_TYPE = 'type',
    TASK_PRIO = 'priority',
    REVIEW_STATUS = 'reviewStatus',
    NEWS_TYPE = 'newsType',
    CUSTOMER_CONTACT = 'contact_id',
    CUSTOMER_SIZE = 'customer_size',
    QUOTEE = 'quotee',
    ASSIGNEES = 'assignees',
    MANAGERS = 'managers',
    MY_TASKS = 'mytasks',
    NOTE_VISIBILITY = 'visibility',
    NOTE_CONTEXT = 'context',
    RESOURCS_OCCASION = 'occasionStatus',
    ASSGN_STATUS = 'assignStatus',
}

export enum GlobalFilterPropertyType {
    STATUS_TAG,
    STATUS_USER,
    STATUS_RESOURCE,
    RESOURCE_TYPE,
}

export enum GlobalFilterColor {
    // case GlobalFilterProperty.SEARCH:
    // case GlobalFilterProperty.SELECTED:
    // case GlobalFilterProperty.RESOURCE_STATE:
    // case GlobalFilterProperty.CATALOG_SOURCE:
    // case GlobalFilterProperty.MESSAGE_SUBJECT:
    // C1_W: { bg: '', fg: '#fff' },
    WHITE = '#fff',
    C1 = 'rgb(62, 83, 101)',
    // case GlobalFilterProperty.CATEGORIES:
    // case GlobalFilterProperty.GROUPS:
    // case GlobalFilterProperty.CUSTOMERS:
    // C2_W: { bg: 'rgb(255, 153, 97)', fg: '#fff' },
    C2 = 'rgb(255, 153, 97)',
    // case GlobalFilterProperty.TAGS:
    // case GlobalFilterProperty.USER_ROLE:
    // case GlobalFilterProperty.MESSAGE_USER:
    // C3_C1: { bg: 'rgb(191, 216, 208)', fg: '#3e5365' },
    C3 = 'rgb(191, 216, 208)',
    // case GlobalFilterProperty.DATE:
    // case GlobalFilterProperty.USER_STATUS:
    // case GlobalFilterProperty.SECTION_SORTING:
    // case GlobalFilterProperty.AUTHOR:
    // case GlobalFilterProperty.EDUCATOR:
    // case GlobalFilterProperty.MESSAGE_SCOPEID:
    // case GlobalFilterProperty.TAG_STATUS:
    // C4_C1: { bg: 'rgb(167, 167, 167)', fg: 'rgb(62, 83, 101)' },
    C4 = 'rgb(167, 167, 167)',

    // case GlobalFilterProperty.LANGUAGE:
    // case GlobalFilterProperty.TIME_SPENT:
    // C5_W: { bg: 'rgb(228, 74, 102)', fg: '#fff' },
    C5 = 'rgb(228, 74, 102)',
}
