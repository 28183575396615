import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { Group, GroupService } from '@klickdata/core/group';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import {
    MessageAllScopes,
    MessageContextTypes,
    MessageScopes,
    MsgCase,
    Notification,
    NotificationService,
    Ticket,
} from '@klickdata/core/notification';
import { AppScope } from '@klickdata/core/resource';
import { User, UserService } from '@klickdata/core/user';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    shareReplay,
    switchMap,
    take,
    takeUntil,
} from 'rxjs/operators';
import { MessageCaseComponent } from './message-case/message-case.component';
import { MessageSystemComponent } from './message-system/message-system.component';

@Component({
    selector: 'app-create-message',
    templateUrl: './create-message.component.html',
    styleUrls: ['./create-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateMessageComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    public messageForm: FormGroup;
    public userRecipients: User[] = [];
    public groupRecipients: Group[] = [];
    public messageScopes = [];
    @Input() msg: Notification;
    public isMobile$: Observable<boolean>;
    public msgCase$: Observable<MsgCase>;
    public ticket$: Observable<Ticket>;
    @Input() msgContextType: MessageContextTypes;
    public userSearch$: Observable<User[]>;
    public groupSearch$: Observable<Group[]>;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public isUserChecked: Subject<boolean> = new Subject<boolean>();
    @ViewChild('userRecieverInput') userRecieverInput: ElementRef<HTMLInputElement>;
    @ViewChild('groupRecieverInput') groupRecieverInput: ElementRef<HTMLInputElement>;
    @ViewChild('caseComponent') msgCaseComponent: MessageCaseComponent;
    @ViewChild(MessageSystemComponent) msgSystemComponent: MessageSystemComponent;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    public userCtrl = new FormControl();
    public groupCtrl = new FormControl();
    public selectedRecepientFilter = new FormControl('users');
    AppScope = AppScope;
    public videoUploaderActive: boolean;
    public user$: Observable<User>;
    public userIsAdmin: boolean;
    public deadline;
    MessageContextTypes = MessageContextTypes;
    MessageScopes = MessageScopes;

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected groupService: GroupService,
        protected authService: AuthService,
        protected changeRef: ChangeDetectorRef,
        protected messageService: MessageService,
        protected notificationService: NotificationService,
        protected router: Router,
        protected mobile: MobileService,
        protected route: ActivatedRoute
    ) {
        this.buildForm();
        this.messageScopes = MessageAllScopes.getAllScopes();
        this.user$ = this.authService.getUser().pipe(take(1));
        this.isMobile$ = this.mobile.isMobile().pipe(shareReplay());
    }
    ngOnInit(): void {
        if (this.msg) {
            this.msgCase$ = this.notificationService.getMsgCase(this.msg.id).pipe(shareReplay());
        }
        this.route.queryParams
            .pipe(
                takeUntil(this.destroy),
                filter((params) => params.role || params.groupName)
            )
            .subscribe((params) => this.updateRecipient(params));
    }
    ngAfterViewInit(): void {
        this.userSearch$ = combineLatest([
            this.userCtrl.valueChanges.pipe(
                filter((term) => typeof term === 'string'),
                debounceTime(300),
                distinctUntilChanged()
            ),
            this.user$,
        ]).pipe(
            switchMap(([term, user]) =>
                combineLatest([
                    of(user),
                    term.trim()
                        ? this.userService.getCustomerUsers({ customerId: user.customer_id, term: term })
                        : of(<User[]>[]),
                ])
            ),
            map(([ownUser, users]) =>
                users.filter(
                    (user) =>
                        this.userRecipients.findIndex((recipient) => recipient.id === user.id) === -1 &&
                        user.id !== ownUser.id
                )
            ),
            takeUntil(this.destroy)
        );

        this.groupSearch$ = this.groupCtrl.valueChanges.pipe(
            filter((term) => typeof term === 'string'),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term) => (term.trim() ? this.groupService.getCustomerGroups(term) : of(<Group[]>[]))),
            map((groups) =>
                groups.filter(
                    (group) => this.groupRecipients.findIndex((recipient) => recipient.id === group.id) === -1
                )
            ),
            takeUntil(this.destroy)
        );
    }
    private updateRecipient(params: Params) {
        if (params.role) {
            this.selectedRecepientFilter.setValue('users');
            this.userRecipients.push(
                new User({ id: params.id, fname: params.fname, lname: params.lname, role_value: params.role })
            );
        } else if (params.groupName) {
            this.selectedRecepientFilter.setValue('groups');
            this.groupRecipients.push(new Group({ id: params.id, name: params.groupName }));
        }
        this.updateReciepentIds();
        this.changeRef.markForCheck();
    }
    ngOnChanges() {
        if (this.msg) {
            this.updateForm();
            if (this.msg.draft && this.msg.recipients.length) {
                this.userRecipients = this.msg.recipients;
                this.updateReciepentIds();
            }
            this.changeRef.markForCheck();
        }
    }

    public removeUser(user: User): void {
        const index = this.userRecipients.indexOf(user);
        if (index >= 0) {
            this.userRecipients.splice(index, 1);
            this.updateReciepentIds();
        }
    }
    public removeGroup(group: Group): void {
        const index = this.groupRecipients.indexOf(group);
        if (index >= 0) {
            this.groupRecipients.splice(index, 1);
            this.updateReciepentIds();
        }
    }

    public remove(recipient: User | Group): void {
        if (recipient instanceof User) {
            const index = this.userRecipients.indexOf(recipient);
            if (index >= 0) {
                this.userRecipients.splice(index, 1);
                this.updateReciepentIds();
            }
        } else {
            const index = this.groupRecipients.indexOf(recipient);
            if (index >= 0) {
                this.groupRecipients.splice(index, 1);
                this.updateReciepentIds();
            }
        }
    }

    public selected(auto: MatAutocomplete, event: MatAutocompleteSelectedEvent, isUserSearch: boolean): void {
        if (isUserSearch) {
            this.userRecipients.push(event.option.value);
            this.userRecieverInput.nativeElement.value = '';
            this.userCtrl.setValue('');
        } else {
            this.groupRecipients.push(event.option.value);
            this.groupRecieverInput.nativeElement.value = '';
            this.groupCtrl.setValue('');
        }

        auto.options.reset([]);
        this.updateReciepentIds();
        this.changeRef.markForCheck();
    }

    private updateReciepentIds() {
        this.messageForm.get('recipient_ids').setValue(this.userRecipients.map((user) => user.id));
        this.messageForm.get('group_ids').setValue(this.groupRecipients.map((group) => group.id));
    }

    public buildForm() {
        this.messageForm = this.fb.group(
            {
                id: [],
                scope_id: [MessageAllScopes.getAllScopes()[0].value, Validators.required],
                recipient_ids: [[]],
                group_ids: [[]],
                subject: ['', Validators.required],
                draft: [false],
                body: [''],
                media_ids: [[]],
            },
            { validators: [this.validateRecipients()] }
        );
    }

    public validateRecipients(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.msg) {
                return control.value.recipient_ids?.length || control.value.group_ids?.length
                    ? null
                    : { needAtLeastGroupOrUser: true };
            } else {
                return null;
            }
        };
    }
    public updateForm(): void {
        this.messageForm.patchValue({
            id: this.msg.id,
            scope_id: this.msg.scope_id,
            draft: this.msg.draft,
            recipient_ids: this.msg.recipient_ids ? this.msg.recipient_ids : [],
            group_ids: this.msg.group_ids ? this.msg.group_ids : [],
            subject: this.msg.subject,
            body: this.msg.body,
            media_ids: this.msg.media_ids,
        });
        FormHelper.resetForm(this.messageForm);

        this.changeRef.markForCheck();
    }

    public submitMsg() {
        if (this.validateFormError()) {
            return; // if form error exists stop submit
        }
        this.prepareSubmit().subscribe(
            (message) => {
                this.messageForm.patchValue({
                    id: message.id,
                    recipient_ids: message.recipient_ids ? message.recipient_ids : [],
                    group_ids: message.group_ids ? message.group_ids : [],
                });
                this.updateReciepentIds();
                FormHelper.resetForm(this.messageForm);
                this.saving.emit(false);
                this.router.navigate([`/home/dashboard/messages/center/${message.draft ? 'draft' : 'sent'}`]);
                this.changeRef.markForCheck();
            },
            (err) => {
                if (err && err.error && err.error.error) {
                    this.messageService.openMessage(MessageErrorComponent, err.error.error.messages);
                    this.router.navigate(['/home/dashboard/messages/center/inbox']);
                }
            }
        );
    }

    private validateFormError(): boolean {
        const subjectCtl = <FormControl>this.messageForm.get('subject');
        if (!subjectCtl.valid) {
            FormHelper.markForm(subjectCtl);
            this.messageService.openMessage(MessageFormErrorComponent);
            return true;
        }
        if (this.userRecipients.length === 0 && this.groupRecipients.length === 0 && !this.messageForm.value.draft) {
            this.messageService.openMessage(MessageFormErrorComponent);
            return true;
        }

        if (!this.messageForm.value.draft && !this.messageForm.valid) {
            FormHelper.markForm(this.messageForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            return true;
        }
    }

    public prepareSubmit(): Observable<Notification> {
        this.saving.emit(true);
        const message = new Notification(this.messageForm.value).getData();
        const createOrUpdate = message.id
            ? this.notificationService.update(message)
            : this.notificationService.create(message);
        return createOrUpdate.pipe(
            switchMap((msg) =>
                this.messageForm.value.scope_id === MessageScopes.CASE
                    ? this.prepareCaseSubmit(msg).pipe(map(() => msg))
                    : of(msg)
            ),
            first()
        );
    }

    private prepareCaseSubmit(msg: Notification): Observable<MsgCase> {
        const data = {
            resource_ids: this.msgCaseComponent.contents?.map((res) => res.id) || [],
            end_date: this.msgCaseComponent.endDate.value,
        };
        return this.messageForm.value.id
            ? this.notificationService.updateMsgCase(msg.id, data)
            : this.notificationService.createMsgCase(msg.id, data);
    }

    public alert() {
        this.mobile.updateSideNavSub({ dataType: SideNaveDataTypes.ALERT });
    }

    public onMsgTypeClick(isNotification: boolean) {
        if (isNotification) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.MESSAGE_ACTIONS,
                title: $localize`:@@thisFunctionWillSoonWorkGreat! :This function will soon work great! `,
                desc: $localize`This function will make it possible to send a message to all registered learners and admins in the academy.`,
                actionIcon: 'check_circle',
            });
        }
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
