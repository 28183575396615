<form [formGroup]="form" fxFlex="0 0 100%" fxLayout="row wrap">
    <!-- <div fxFlex="0 0 100%" fxLayout="row" fxFlexAlign="space-between" fxLayoutGap="0.5em">
        <mat-checkbox formControlName="delay" i18n="@@sendEmailAtCourseStart"
            >Send e-mail at course start date</mat-checkbox
        >
         <div fxFlex="1 1 100%"></div>

        <app-table-date-filter
            label="Enter assignment date"
            i18n-label
            [max]="max"
            [required]="true"
            formControlName="date_range"
            fxLayoutAlign="center"
        ></app-table-date-filter>
    </div> -->
    <div class="dates-wrapper" fxLayout="column" fxLayoutAlign.lt-md="start start">
        <div class="start">
            <mat-form-field fxFlex="0 1 350px" fxFlex.lt-sm="0 0 100%">
                <input
                    matInput
                    formControlName="start_date"
                    [matDatepicker]="startDate"
                    placeholder="Start date"
                    i18n-placeholder="@@startDate"
                />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="start">
            <mat-form-field>
                <input
                    matInput
                    formControlName="end_date"
                    [matDatepicker]="endDate"
                    placeholder="End date*"
                    i18n-placeholder="@@endDate"
                />
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <mat-tab-group
        *ngIf="publishMsg; else loading"
        class="user-manage-cp-access cp-publish-tabs"
        fxFlex="0 0 100%"
        ngClass.xs="xs"
    >
        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@users">Users</span>
            </ng-template>
            <app-users-select-has-occasions
                *ngIf="referenceFilter == 'assignedResource' && publishMsg.assign_id"
                [referenceIdKey]="referenceIdKey"
                [referenceFilter]="referenceFilter"
                [accessForm]="form"
                [assignId]="publishMsg.assign_id"
                defaultSorting="disabled"
                (onDismissBottomSheet)="onDismissBottomSheet.emit()"
            ></app-users-select-has-occasions>
            <app-admin-users-select
                *ngIf="!(referenceFilter == 'assignedResource' && publishMsg.assign_id)"
                [referenceIdKey]="referenceIdKey"
                [referenceFilter]="referenceFilter"
                [accessForm]="form"
                defaultSorting="disabled"
            ></app-admin-users-select>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@groups">Groups</span>
            </ng-template>
            <app-admin-groups-select
                [referenceIdKey]="referenceIdKey"
                [referenceFilter]="referenceFilter"
                [accessForm]="form"
                [assignId]="publishMsg.assign_id"
                defaultSorting="disabled"
            ></app-admin-groups-select>
        </mat-tab>
    </mat-tab-group>
    <div class="mt-1 mb-1">
        <mat-checkbox formControlName="delay" i18n="@@sendEmailAtCourseStart"
            >Send e-mail at course start date</mat-checkbox
        >
    </div>

    <mat-form-field fxFlex="0 0 100%">
        <input
            type="text"
            matInput
            formControlName="subject"
            placeholder="Send Course subject"
            i18n-placeholder="@@sendCourseMessageSubject"
        />
    </mat-form-field>

    <app-text-editor
        fxFlex="0 0 100%"
        [subject]="form.value.subject"
        (onSubject)="form.controls['subject'].patchValue($event)"
        fieldName="coursePlanPublishBody"
        title="Send Course invitation"
        i18n-title
        formControlName="body"
    >
        <mat-error i18n="@@messageBodyError">Message body is required!</mat-error>
    </app-text-editor>

    <div *ngIf="!hideActions" class="mt-2" fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center">
        <button
            *ngIf="!(publishing | async)"
            type="button"
            mat-raised-button
            color="primary"
            fxFlex="1 1 300px"
            fxFlex.lt-md="80%"
            i18n
            (click)="publish()"
        >
            Publish
        </button>
        <app-saving-button *ngIf="publishing | async"></app-saving-button>
    </div>
</form>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
