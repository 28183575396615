import { DataSource } from '@angular/cdk/collections';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { IModel } from '@klickdata/core/application';
import { PaginatorResponse } from '@klickdata/core/http/src/responce/paginator-responce';
import { ResourceTableActions } from '@klickdata/core/resource/src/types.enum';
import { TableHttpService } from '@klickdata/core/table/src/table-http-service';
import { Utils } from '@klickdata/core/util';
import { BehaviorSubject, Observable, Subject, Subscription, merge, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { FilterCollection } from './table-filter/filter';
import { TableFilterComponent } from './table-filter/table-filter.component';
export interface TableColumnsData {
    column: string;
    label: string;
}
export interface TableActionsData {
    type: ResourceTableActions;
    icon: string;
    toolTip: string;
    class: string;
    color: string;
}
export interface TableQuery {
    sort?: Sort;
    paginator?: PageEvent;
    filter?: FilterCollection<string | number>;
}

export class TableSource<T extends IModel> extends DataSource<T> {
    get sort(): MatSort | null {
        return this._sort;
    }

    set sort(sort: MatSort | null) {
        this._sort = sort;
        this._updateChangeSubscription();
    }

    protected _sort: MatSort | null;

    get paginator(): MatPaginator | null {
        return this._paginator;
    }

    set paginator(paginator: MatPaginator | null) {
        this._paginator = paginator;
        // if (paginator) {
        //     paginator.pageSize = 25;
        //     paginator.pageSizeOptions = [25, 50, 100]; // default pager options.
        // }
        this._updateChangeSubscription();
    }
    protected _paginator: MatPaginator | null;

    get filter(): TableFilterComponent | null {
        return this._filter;
    }

    set filter(filter: TableFilterComponent | null) {
        this._filter = filter;
        this._updateChangeSubscription();
    }

    protected _filter: TableFilterComponent | null;

    public set service(service: TableHttpService<T>) {
        this._service = service;
    }

    protected _service: TableHttpService<T>;

    protected _renderData: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    protected _renderChangesSubscription: Subscription;

    protected _updateSubject: Subject<boolean> = new Subject<boolean>();

    private query: TableQuery = {};

    get data(): T[] {
        return this._renderData.getValue();
    }

    get status(): Observable<'loading' | 'loaded' | 'empty' | 'error'> {
        return this._status.asObservable();
    }

    get loading(): boolean {
        return this._status.value === 'loading';
    }

    protected _status: BehaviorSubject<'loading' | 'loaded' | 'empty' | 'error'> = new BehaviorSubject<
        'loading' | 'loaded' | 'empty' | 'error'
    >('empty');

    public length: number;
    public latestData: Subject<PaginatorResponse<T[]>> = new Subject<PaginatorResponse<T[]>>();

    public connect(): Observable<T[]> {
        return this._renderData;
    }

    public set(items: T[]) {
        this._renderData.next(items);
    }

    public add(items: T | T[]) {
        this._renderData.next([...(Array.isArray(items) ? items : [items]), ...this._renderData.value]);
    }

    public moveItemInArray(previousIndex: number, currentIndex: number) {
        const data = this._renderData.value;
        moveItemInArray(data, previousIndex, currentIndex);
        this._renderData.next(data);
    }

    public removeById(id: number) {
        const data = this._renderData.value;
        const index = data.findIndex((item) => item.id === id);
        if (index !== -1) {
            data.splice(index, 1);
            this._renderData.next(data);
        }
    }

    public removeByIds(ids: number[]) {
        const data = this._renderData.value;
        ids.forEach((id) => {
            const index = data.findIndex((item) => item.id === id);
            if (index !== -1) {
                data.splice(index, 1);
            }
        });
        this._renderData.next(data);
    }

    public replace(items: T | T[]) {
        const data = this._renderData.value;
        if (Array.isArray(items)) {
            items.forEach((item) => this.replaceItem(data, item));
        } else {
            this.replaceItem(data, items);
        }
        this._renderData.next(data);
    }

    private replaceItem(data: T[], item: T) {
        const index = data.findIndex((el) => el.id === item.id);
        if (index !== -1) {
            data.splice(index, 1, item);
        } else {
            data.splice(0, 0, item);
        }
    }

    updatePaginatorOptions(max: number) {
        if (max <= 0) {
            this.paginator.pageSizeOptions = [];
        } else if (max <= 25) {
            this.paginator.pageSizeOptions = [max];
        } else if (max <= 50) {
            this.paginator.pageSizeOptions = [25, max];
        } else {
            this.paginator.pageSizeOptions = [25, 50, 100];
        }
    }

    public disconnect(): void {
        if (this._renderChangesSubscription) {
            this._renderChangesSubscription.unsubscribe();
            this._renderChangesSubscription = null;
        }
    }

    public refresh(): void {
        this._updateSubject.next(true);
    }

    /**
     * Subscribe to changes that should trigger an update to the table's rendered rows. When the
     * changes occur, process the current state of the filter, sort, and pagination along with
     * the provided base data and send it to the table for rendering.
     */
    protected _updateChangeSubscription() {
        // Sorting and/or pagination should be watched if MatSort and/or MatPaginator are provided.
        // Otherwise, use an empty observable stream to take their place.
        const sortChange = this._sort ? this._sort.sortChange : of(<Sort>null);
        const pageChange = this._paginator ? this._paginator.page : of(<PageEvent>null);
        /**
         * @TODO
         * Debug filter requests emited many time without all required param and cancelled that crash some with required param missing
         * HOT FIX debounceTime, Ignored 240710 KL&AS
         */
        const filterChange = this._filter ? this._filter.filterChange : of(<FilterCollection<string | number>>null);

        this.disconnect();

        // Watch for changes in filters, sort or paginator.
        this._renderChangesSubscription = merge(sortChange, pageChange, filterChange, this._updateSubject)
            .pipe(
                filter((item) => {
                    if (typeof item === 'boolean') return true;
                    if (item === null) return false;

                    // Sort
                    if ('active' in item && !Utils.isEqual(this.query.sort, item)) {
                        this.query.sort = item;
                        return true;
                    }

                    // Paginator
                    if ('pageIndex' in item && !Utils.isEqual(this.query.paginator, item)) {
                        this.query.paginator = item;
                        return true;
                    }

                    // Filters
                    if (
                        item instanceof FilterCollection &&
                        !Utils.filtersEqual(this.query.filter, item)
                    ) {
                        this.query.filter = item.clone();
                        return true;
                    }

                    return false;
                }),
                map((item) => {
                    this._status.next('loading');
                    return item;
                }),
                mergeMap(() => {
                    return this._service.fetchData(this);
                }),
                tap((items: PaginatorResponse<T[]>) => {
                    this._status.next('loaded');

                    if (items.data.length === 0) {
                        this._status.next('empty');
                    }

                    if (this._paginator) {
                        this._paginator.length = items.paginator.total_count;
                        this.updatePaginatorOptions(items.paginator.total_count);

                        // #161 Hack to fix when current paginator page more then total pages paginator doesn't make correction by default.
                        // https://github.com/angular/material2/issues/5812
                        // https://github.com/angular/material2/issues/10227
                        if (
                            this._paginator.pageIndex !== 0 &&
                            this._paginator.pageIndex >= this._paginator.getNumberOfPages()
                        ) {
                            this._paginator.previousPage();
                        }
                    }

                    this.length = this._paginator ? this._paginator.length : items.data.length;
                    this.latestData.next(items);
                })
            )
            .subscribe((data) => this._renderData.next(data.data));
    }
}
