<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>{{ (navData.data.course | resourceCertificate).icon }}</mat-icon>
        <div i18n>Well Done!</div>
    </div>

    <ng-container *ngIf="navData.data.customDesc">
        <div class="desc">{{ navData.data.customDesc }}</div>
    </ng-container>
    <!-- <ng-container *ngIf="!navData.data.isLastItem && !navData.data.customDesc" [ngTemplateOutlet]="desc"></ng-container> -->
    <div class="desc" *ngIf="navData.data.isLastItem && !navData.data.customDesc" i18n>
        Do you want to mark this material as done ?
    </div>
    <div fxLayoutGap="20px" fxLayout="column" class="mt-1">
        <div
            *ngIf="navData.data.isLastItem"
            class="action"
            fxLayout="row-reverse"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
        >
            <button fxFlex="0 0 auto" class="mobile-button pos-btn" (click)="userIsDone = true" mat-button i18n>
                Yes I'm done
            </button>
            <button
                fxFlex="0 0 auto"
                class="mobile-button negative"
                (click)="
                    onClose.emit({
                        data: {
                            type: navData.data.type,
                            response: TakeResActionValue.CANCEL
                        }
                    })
                "
                mat-button
                i18n
            >
                Close
            </button>
        </div>
        <ng-container *ngIf="userIsDone || !navData.data.isLastItem">
            <ng-container [ngTemplateOutlet]="desc"></ng-container>
            <div
                *ngIf="navData.data.course.user_collaboration?.signoff_required"
                class="input-field-parent"
                fxLayout="column"
                fxLayoutGap="5px"
            >
                <mat-form-field cdkFocusInitial appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                    <textarea
                        #taskInput
                        matInput
                        type="text"
                        [formControl]="feedback"
                        placeholder="Write feedback to admin"
                        i18n-placeholder
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="4"
                        cdkAutosizeMaxRows="10"
                    ></textarea>
                </mat-form-field>
            </div>

            <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
                <button
                    fxFlex="0 0 auto"
                    class="mobile-button positive sign-btn"
                    (click)="
                        onClose.emit({
                            data: {
                                type: navData.data.type,
                                response: TakeResActionValue.SIGNOFF,
                                signoff: navData.data.course.user_collaboration?.signoff_required
                                    ? { body: feedback.value }
                                    : null
                            }
                        })
                    "
                    mat-button
                    i18n
                >
                    Signoff
                </button>
                <button
                    fxFlex="0 0 auto"
                    class="mobile-button custom"
                    (click)="
                        onClose.emit({
                            data: {
                                type: navData.data.type,
                                response: TakeResActionValue.CONTINUE_LATER
                            }
                        })
                    "
                    mat-button
                    i18n
                >
                    Continue later
                </button>
                <button
                    fxFlex="0 0 auto"
                    class="mobile-button negative"
                    (click)="
                        onClose.emit({
                            data: {
                                type: navData.data.type,
                                response: TakeResActionValue.CANCEL
                            }
                        })
                    "
                    mat-button
                    i18n
                >
                    Close
                </button>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #desc>
    <div class="desc" *ngIf="navData.data.course.user_collaboration?.signoff_required" i18n>
        Now you have completed all parts. You can signoff now and the admin will need to approve in order for you to
        download your PDF of {{ (navData.data.course | resourceCertificate).certificate }}. Check status in the Activity
        overview. You can send a message to the admin below.
    </div>
    <div class="desc" *ngIf="!navData.data.course.user_collaboration?.signoff_required" i18n>
        Now you have completed all parts. You can signoff now and you will be able to download your PDF of
        {{ (navData.data.course | resourceCertificate).certificate }}.
    </div>
</ng-template>
