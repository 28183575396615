import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { SoftDeleteDialogComponent } from '@klickdata/core/application';
import { InfoDialogComponent } from '@klickdata/core/application/src/info-dialog/info-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import { MessageDeletedUndoComponent, MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, Resource } from '@klickdata/core/resource';
import { ResourceTableActions } from '@klickdata/core/resource/src/types.enum';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { ShareService } from '../../share/share.service';
import { UserListingService } from './../user-listing.service';
import { Customer } from '@klickdata/core/customer';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-listing-general',
    templateUrl: './user-listing-general.component.html',
    styleUrls: ['./user-listing-general.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserListingService],
})
export class UserListingGeneralComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() table_columns: string[];
    @Input() activeSortingCol = 'title';
    @Input() activeSortingDirection: SortDirection = 'desc';
    @Input() toggleFilterButtons = false;
    @Input() filtersAreExpanded = false;
    @Input() selectingStyleClass: string;
    @Input() type_scope_id: number;
    @Input() customer: Customer;
    @Input() allowAddingItem: boolean;
    @Input() areFiltersInAccordionInDesktop: boolean;
    @Input() allowActionHeader: boolean;
    @Input() allowActionRow: boolean;
    @Input() canCreateGroup: boolean;
    @Input() tableToolsAction: ResourceTableActions[];
    @Input() refresh: Subject<User>;
    @Input() resourceId: number;
    @Input() attachedResource: Resource;
    @Input() tableFilterItems: Filter<string | number>[];
    @Output() onBrowse: EventEmitter<User> = new EventEmitter<User>();
    @Output() onAdd: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onRowClicked: EventEmitter<User> = new EventEmitter<User>();
    @Output() onSendEmail: EventEmitter<User> = new EventEmitter<User>();
    @Output() onSendEmails: EventEmitter<User[]> = new EventEmitter<User[]>();
    @Output() onShowTestResults: EventEmitter<User> = new EventEmitter<User>();
    @Output() onUserCollect: EventEmitter<User> = new EventEmitter<User>();
    @Output() onUserRecommend: EventEmitter<User> = new EventEmitter<User>();
    @Output() onUserSpecify: EventEmitter<User> = new EventEmitter<User>();
    @Output() onUserEdit: EventEmitter<User> = new EventEmitter<User>();
    @Output() onUserNote: EventEmitter<User> = new EventEmitter<User>();
    @Output() onCustomerClick: EventEmitter<number> = new EventEmitter<number>();
    public dataSource = new TableSource<User>();
    public columns: string[];
    public isMobile: boolean;
    public canRestoreUser: boolean;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    selection = new SelectionModel<User>(true, []);
    public showActionHeader = false;
    public showColumnsFilter = false;
    public showStatusFilter = false;
    public showLangFilter = false;
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public tableTools: ResourceTableActions[];
    ResourceTableActions = ResourceTableActions;
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public userStatusOptions: SelectFilterOption[];
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public userRoleOptions: SelectFilterOption[];
    public expandedRow: User | null;
    public klmsSpentTimeColTitle = $localize`Login time 30D`;
    public coursesSpentTimeColTitle = $localize`Course time last 30D`;

    constructor(
        protected userService: UserListingService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected bottomSheet: MatBottomSheet,
        protected messageService: MessageService,
        protected router: Router,
        public shareService: ShareService
    ) {
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.userStatusOptions = Utils.getUserStatusOptions();
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
    }
    public ngOnInit() {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });
        this.dataSource.service = this.userService;
        this.columns = this.table_columns;
        if (this.isMobile) {
            if (this.columns.indexOf('tools') === -1) {
                this.columns.push('tools');
            }
            this.columns.splice(this.columns.indexOf('quick_tools'), 1);
        }
        this.tableTools = this.tableToolsAction;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                this.updatefilters(false);
            });
        this.selection.changed.pipe(takeUntil(this.destroy)).subscribe((values) => {
            if (this.allowActionHeader) {
                this.showActionHeader = values.source.selected.length > 0;
            }
            this.cdRef.markForCheck();
        });
        if (this.refresh) {
            this.refresh
                .asObservable()
                .pipe(takeUntil(this.destroy))
                .subscribe((user) => this.dataSource.refresh());
        }
        // handle display users who took resource from list pags (tests, course, surveys or course plans)
        if (this.resourceId) {
            this.filter?.createOrUpdateFilter([new Filter('taken_resource', [this.resourceId])]);
        }
        this.filter.filterChange.pipe(takeUntil(this.destroy)).subscribe((filtersApplied) => {
            const expiredFilterIndex = filtersApplied.filters.findIndex(
                (value: Filter<string | number>) => value.property === 'status' && value.items?.includes('expired')
            );
            if (expiredFilterIndex !== -1 && this.columns.indexOf('expiration') === -1) {
                this.columns.splice(this.columns.length - 1, 0, 'expiration');
            } else if (this.columns.indexOf('expiration') !== -1) {
                this.columns.splice(this.columns.indexOf('expiration'), 1);
            }
            this.canRestoreUser =
                filtersApplied.filters.findIndex(
                    (value: Filter<string | number>) => value.property === 'status' && value.items?.includes('deleted')
                ) !== -1;

            this.cdRef.markForCheck();
        });
    }

    private updatefilters(withEmmiter: boolean) {
        const appliedFilters = [];
        if (this.tableFilterItems) {
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(new Filter(filterItem.property, filterItem.items));
            });
        }
        withEmmiter
            ? this.filter.createOrUpdateFilter(appliedFilters)
            : this.filter.createOrUpdateWithoutEmitFilter(appliedFilters);
    }
    ngOnChanges() {
        if (this.tableFilterItems && this.filter && this.authUser) {
            this.updatefilters(true);
        }
    }

    public sendMsg(user: User) {
        if (user) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.SEND_MESSAGE,
                data: { model: user, isUser: true },
            });
        }
    }

    public functionNotImpl() {
        this.dialog.open(InfoDialogComponent, {
            disableClose: false,
            data: {
                contentBody: $localize`:@@thisFunctionWillSoonWorkGreat! :This function will soon work great! `,
                neutralBtn: $localize`:@@ok:Ok`,
            },
        });
    }

    public browse(user: User): void {
        this.onBrowse.emit(user);
    }

    public add(): void {
        this.onAdd.emit(true);
    }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: User): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }
    public checkValidaty(end_date: moment.Moment): string {
        return !end_date || end_date.isAfter() ? 'event_available' : 'event_busy';
    }

    public remove(users?: User[]): void {
        this.dialog
            .open(SoftDeleteDialogComponent, {
                data: { type: $localize`user` },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result),
                switchMap(() => this.userService.destroyMultipleUsers(users.map((user) => user.id)))
            )
            .subscribe((success) => {
                if (success) {
                    const ref = this.messageService.openMessage(MessageDeletedUndoComponent);
                    ref.instance.snackBarRef = ref;
                    // Check if user want to undo.
                    ref.onAction()
                        .pipe(takeUntil(this.destroy))
                        .subscribe(() => {
                            this.restoreUser(users);
                        });
                    this.dataSource.removeByIds(users.map((user) => user.id));
                }
            });
    }

    /**
     * Restore a removed user
     */
    public restoreUser(users: any) {
        const service =
            users instanceof Array
                ? this.userService.restoreMultipleUsers(users.map((user) => user.id))
                : this.userService.restore(users.id);
        service.pipe(takeUntil(this.destroy)).subscribe(() => {
            this.messageService.openMessage(MessageSavedComponent);
            this.dataSource.refresh();
        });
    }
    public getTooltipLoginStatus(status) {
        return $localize`Offline`;
    }
    public createGroup(users: User[]) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.CREATE_GROUP,
            data: {
                customerId: this.customer?.id || this.authUser.customer_id,
                userIds: users.map((user) => user.id),
                type: 'createGroup',
            },
        });
        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'createGroup' && data.group),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                this.selection.clear();
                this.messageService.openMessage(
                    MessageSavedComponent,
                    $localize`${data.group.name} group created with selected learners.`
                );
                if (data.editGroup) {
                    this.router.navigate(['/admin/accounts/groups', data.group?.id]);
                }
            });
    }

    public isChecked() {}
    public isIndeterminate() {}
    public selectToggle(res: User) {}
    public modelChecked(res: User) {}

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
