import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output,
    Input,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild,
} from '@angular/core';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Resource, ResourceTypes, AppScope } from '@klickdata/core/resource';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { User } from '@klickdata/core/user';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-course-manager-core-items',
    templateUrl: './course-manager-core-items.component.html',
    styleUrls: ['./course-manager-core-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseManagerCoreItemsComponent implements OnDestroy {
    @Input() selection: number[] = [];
    @Input() authUser: User;
    AppScope = AppScope;
    public displayedScope: AppScope;
    public displayTab: ResourceBuildingItems;
    @Output() add: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    public destroy = new Subject<boolean>();
    @ViewChild('compRef') compRef: any;
    public isMobile$: Observable<boolean>;

    constructor(protected mobileService: MobileService, protected cdr: ChangeDetectorRef) {}

    public addmultipleResources(response: { res: Resource[]; isParentSubmission?: boolean }): boolean {
        if (response && response.res?.length && response.res[0] instanceof Resource) {
            for (let i = 0; i < response.res.length; i++) {
                this.add.emit({ res: response.res[i], isParentSubmission: response.isParentSubmission });
            }
            return true;
        }
        return false;
    }
    public addResource(response: { res: Resource; isParentSubmission?: boolean }): boolean {
        if (response && response.res instanceof Resource) {
            this.add.emit(response);
            return true;
        }
        return false;
    }

    public addItem(scope: AppScope) {
        this.authUser.privileges?.publish != 'allowed' ? this.addListingItems(scope) : this.addItemsWithCreation(scope);
    }
    private addListingItems(scope: AppScope) {
        this.displayTab =
            scope === AppScope.MATERIAL
                ? ResourceBuildingItems.MATERIAL_LIBRARY
                : scope === AppScope.TEST
                ? ResourceBuildingItems.TEST_LIBRARY
                : scope === AppScope.SURVEY
                ? ResourceBuildingItems.SURVEY_LIBRARY
                : ResourceBuildingItems.EVENT_LIBRARY;

        this.displayedScope = scope;
        this.cdr.markForCheck();
    }
    private addItemsWithCreation(scope: AppScope) {
        const buildingItems =
            scope === AppScope.MATERIAL
                ? [
                      ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL,
                      ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL,
                      ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL,
                      ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL,
                      ResourceBuildingItems.MATERIAL_LIBRARY,
                  ]
                : scope === AppScope.TEST
                ? [ResourceBuildingItems.CREATE_NEW_TEST, ResourceBuildingItems.TEST_LIBRARY]
                : scope === AppScope.SURVEY
                ? [ResourceBuildingItems.CREATE_NEW_SURVEY, ResourceBuildingItems.SURVEY_LIBRARY]
                : [ResourceBuildingItems.CREATE_NEW_EVENT, ResourceBuildingItems.EVENT_LIBRARY];

        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_QUESTIONS,
            data: {
                type: scope,
                buildingItems: buildingItems,
                icon: 'add_circle_outline',
                context: 'courseCreation',
                title:
                    scope === AppScope.MATERIAL
                        ? $localize`Add Material to Course`
                        : scope === AppScope.TEST
                        ? $localize`Add Test to Course`
                        : scope === AppScope.SURVEY
                        ? $localize`Add Test to Course`
                        : $localize`Add Event to Course`,
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === scope),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                this.displayTab = data.value;
                if (scope === AppScope.MATERIAL && data.resource) {
                    this.addResource(data.resource);
                }
                this.displayedScope = scope;
                this.cdr.markForCheck();
            });
    }
    submitItems() {
        this.compRef?.submit();
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
