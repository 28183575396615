import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Utils } from '@klickdata/core/util';
import { filter, map, startWith, tap } from 'rxjs/operators';
export interface GenericTabsOption {
    label: string;
    url: string;
    value: string;
    index: number;
}
@Component({
    selector: 'app-generic-mobile-tabs',
    templateUrl: './generic-mobile-tabs.component.html',
    styleUrls: ['./generic-mobile-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericMobileTabsComponent implements OnInit {
    private _activeOption: GenericTabsOption;
    @Input() options: GenericTabsOption[];
    @Input() isNavTab = true;
    @Input() fetchActiveOptionFromRoute = false;
    @ContentChildren(TemplateRef) templateRefs: QueryList<TemplateRef<any>>;
    @Output()
    public selectedOption: EventEmitter<GenericTabsOption> = new EventEmitter();
    @Input()
    public set activeOption(activeOption: GenericTabsOption) {
        this._activeOption = activeOption;
    }
    public get activeOption(): GenericTabsOption {
        return this._activeOption;
    }
    @Input() wrapperClass: string;

    constructor(private cdr: ChangeDetectorRef, private router: Router) {}
    public ngOnInit() {
        this.getActiveOption();
        if (this.fetchActiveOptionFromRoute) {
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe(() => this.getActiveOption());
        }
    }
    private getActiveOption() {
        this.activeOption = Utils.getSelectedMobileTabsOption(this.router, this.options);
        this.cdr.markForCheck();
    }
}
