import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { ResourceTypes, AppScope, ResourceTypeService } from '@klickdata/core/resource';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-section-item',
    templateUrl: './section-item.component.html',
    styleUrls: ['./section-item.component.scss'],
})
export class SectionItemComponent implements OnInit, OnDestroy {
    @Input() resource: any;
    @Input() isLinkScopeType: boolean;
    @Input() hasThumbnailHoverEffect: boolean;
    @Input() status: 'started' | 'completed';
    @Input() mode: 'list' | 'icons' | 'searchResults' = 'icons';
    ResourceTypes = ResourceTypes;
    public remainingTime: number;
    AppScope = AppScope;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public moment = moment();
    public hovered: boolean;

    constructor(
        protected router: Router,
        protected auth: AuthService,
        protected resourceTypeService: ResourceTypeService,
        protected cd: ChangeDetectorRef,
        protected location: Location
    ) {}

    ngOnInit() {
        const end = this.resource.opportunity_end_date;
        if (end) {
            const now = moment(); //now date
            this.remainingTime = end.diff(now, 'days');
            this.cd.markForCheck();
        }
    }

    public goToDetails() {
        if (this.resource.opportunity_id) {
            switch (this.resource.type_id) {
                case ResourceTypes.General_E_Course:
                    // this.router.navigate(['/home/courses/' + this.resource.article_code]);
                    this.router.navigate(['/home/dashboard/resource-details/' + this.resource.id]);
                    break;
                case ResourceTypes.GeneralCoursePlan:
                    const extras: NavigationExtras = {
                        queryParams: {
                            referrer: this.location.path(),
                            opportunity: this.resource.opportunity_id,
                        },
                    };
                    this.status === 'completed'
                        ? this.router.navigate([
                              '/player',
                              'occasion',
                              this.resource.opportunity_id,
                              'course',
                              'result',
                              'me',
                          ])
                        : this.router.navigate(['/player/resource/', this.resource.id], extras);
                    break;
                case ResourceTypes.GeneralTest:
                    const completed_extras: NavigationExtras = {
                        queryParams: {
                            referrer: this.location.path(),
                        },
                    };
                    const started_extras: NavigationExtras = {
                        queryParams: {
                            referrer: this.location.path(),
                            opportunity: this.resource.opportunity_id,
                        },
                    };
                    this.status === 'completed'
                        ? this.router.navigate(
                              ['/player/occasion/', this.resource.opportunity_id, 'test', 'result'],
                              completed_extras
                          )
                        : this.router.navigate(['/player/resource/', this.resource.id], started_extras);
                    break;
                case ResourceTypes.GeneralSurvey:
                    const survey_extras: NavigationExtras = {
                        queryParams: {
                            referrer: this.location.path(),
                            opportunity: this.resource.opportunity_id,
                        },
                    };
                    this.status === 'completed'
                        ? this.router.navigate(['/home/dashboard/resource-details/' + this.resource.id])
                        : this.router.navigate(['/player/resource/', this.resource.id], survey_extras);
                    break;
                default:
                    this.router.navigate(['/home/dashboard/resource-details/' + this.resource.id]);
            }
        } else {
            // resource-details
            this.auth
                .check()
                .pipe(first(), takeUntil(this.destroy))
                .subscribe((authorized) => {
                    if (this.resource.scope_id) {
                        switch (this.resource.scope_id) {
                            case AppScope.COURSE:
                            case AppScope.E_COURSE:
                            case AppScope.TEST:
                            case AppScope.SURVEY:
                                if (authorized && this.resource.item$) {
                                    this.router.navigate(['/home/courses/', this.resource.artnr], {
                                        queryParams: { position: this.resource.item$.position },
                                    });
                                } else {
                                    this.router.navigate([
                                        authorized ? '/home/dashboard/resource-details/' : 'guest/resource-details',
                                        this.resource.id,
                                    ]);
                                }
                                break;
                            case AppScope.POST:
                                this.router.navigate(['/home/dashboard/news'], {
                                    queryParams: { postId: this.resource.id },
                                });
                            default:
                                this.router.navigate([
                                    authorized ? '/home/dashboard/resource-details/' : 'guest/resource-details',
                                    this.resource.id,
                                ]);
                        }
                    } else {
                        // from dashboard
                        this.router.navigate([
                            (authorized ? '/home/dashboard/resource-details/' : '/guest/resource-details/') +
                                this.resource.id,
                        ]);
                    }
                });
        }
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
