<div [formGroup]="promptForm" class="record-hint" fxLayout="column">
    <mat-radio-group class="prompter-btn" fxLayoutAlign="space-between center" formControlName="type_id">
        <mat-radio-button [value]="ResourceTypes.TextMaterial">
            <mat-icon matTooltip="Write a text and let ChatGPT give you an answer">text_fields</mat-icon>
        </mat-radio-button>
        <mat-radio-button [value]="ResourceTypes.DocMaterial">
            <mat-icon i18n-matTooltip matTooltip="Write a text and let ChatGPT create an image">image</mat-icon>
        </mat-radio-button>
    </mat-radio-group>
    <div fxLayoutGap="5px" fxLayoutAlign="inherit baseline">
        <mat-form-field class="prompt-input" fxFlex="0 1 100%">
            <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                #input
                matInput
                type="text"
                [placeholder]="placeholder"
                formControlName="prompt"
            >
            </textarea>

            <mat-icon class="disable-icon" matSuffix>mic</mat-icon>

            <!-- <mat-error i18n>Prompter text is required!</mat-error> -->
        </mat-form-field>
    </div>
    <div *ngIf="showTags" fxFlex="grow">
        <app-tag-chip-select
            placeholder="Add Tag"
            i18n-placeholder
            [language_id]="currentLaguageId$ | async"
            formControlName="tag_ids"
        ></app-tag-chip-select>
    </div>
    <div fxLayout="column" *ngIf="showQuiz" class="vtq-wrapper">
        <app-vtq-sliders
            [hasCheckBox]="true"
            (onCheckBoxChange)="$event && (showQuiz = false)"
            (valuesChange)="vtqData = $event"
        ></app-vtq-sliders>
    </div>
    <div fxFlex="grow" fxLayoutAlign="start center" class="mt-1 action-btns-ai">
        <button class="list" mat-button matTooltip="Check materials list" (click)="listingMaterial()">
            <mat-icon>format_list_bulleted</mat-icon>
            <span i18n>List</span>
        </button>
        <button
            *ngIf="promptForm.value.prompt"
            matTooltip="Add quiz or course based on prompt"
            i18n-matTooltip
            class="tag-btn"
            [ngClass]="{
                'quiz-displayed': showQuiz,
                'quiz-hidden': !showQuiz
            }"
            mat-icon-button
            (click)="showQuiz = !showQuiz"
        >
            <mat-icon
                fxFlex="0 0 auto"
                fontSet="klickdata"
                fontIcon="kd-icon-test"
                class="course-plan-icon side-nave-icon no-shadow"
            ></mat-icon>
        </button>
        <button
            matTooltip="Add tags"
            i18n-matTooltip
            class="tag-btn"
            [ngClass]="{
                'tags-displayed': showTags,
                'tags-hidden': !showTags,
                'has-tags': promptForm.value.tag_ids?.length
            }"
            mat-icon-button
            (click)="showTags = !showTags"
        >
            <mat-icon>tag</mat-icon>
        </button>
        <button
            class="edit"
            [disabled]="loading || !(currentResource$ | async)"
            mat-button
            matTooltip="View last created material"
            (click)="editMaterial()"
        >
            <mat-icon>edit</mat-icon>
            <span i18n>View</span>
        </button>
        <button
            [disabled]="!promptForm.value.prompt"
            class="create"
            mat-button
            [matTooltip]="getSubmitBtnTooltip()"
            (click)="prompterLoading.next(true); addMaterial()"
        >
            <mat-icon class="add-ai-btn" [ngClass]="{ 'on-loading': prompterLoading.value }">smart_toy</mat-icon>
            <span i18n>Create</span>
        </button>
    </div>
</div>
