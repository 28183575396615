<div *ngIf="navData" fxLayoutGap="10px" class="data-wrap pt-0" fxLayout="column">
    <div class="title mt-0" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon class="title-icon {{ navData.data.class }}">assignment_turned_in</mat-icon>
        <div class="title-label {{ navData.data.class }}" fxFlex="grow">{{ navData.data.title }}</div>
    </div>
    <!-- <ng-container [ngTemplateOutlet]="actions"></ng-container> -->

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <app-image-view
            [ngStyle]="{ minWidth: '50px' }"
            [isCircularImg]="true"
            [media_id]="navData.data.learner.media"
            paddingBottom="50px"
        ></app-image-view>
        <div fxLayout="column" fxLayoutAlign="center start">
            <span class="small">{{ navData.data.learner.name }}</span>
            <span class="dark-blue small">{{ navData.data.occasion.resource.title }}</span>
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px" class="small signoff-date"
                ><span i18n>Signoff</span> <span>{{ navData.data.occasion.signoff_at }}</span></span
            >
        </div>
    </div>

    <app-add-formative-evaluation
        class="signoff-review"
        [titlesExpansionsStatus]="[true, false]"
        #addFormEval
        [data]="navData.data?.occasion?.evaluation"
    >
        <div feedbackTitle class="title sec" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
            <mat-icon class="title-icon">rate_review</mat-icon>
            <div fxFlex="grow" i18n>1.Feedback</div>
        </div>
        <div assessTitle class="title sec" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
            <mat-icon class="title-icon">assessment</mat-icon>
            <div fxFlex="grow" i18n>2.Formative evaluation</div>
        </div>
    </app-add-formative-evaluation>
</div>
<ng-container [ngTemplateOutlet]="actions"></ng-container>
<ng-template #actions>
    <div class="mb-1" fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="5px">
        <button fxFlex="0 0 auto" class="sub-btn rej" (click)="approved = false; submitEvaluation()" mat-button>
            <mat-icon>cancel</mat-icon>
            <span i18n>Reject</span>
        </button>
        <button fxFlex="0 0 auto" class="sub-btn" (click)="approved = true; submitEvaluation()" mat-button>
            <mat-icon>assignment_turned_in</mat-icon>
            <span i18n>Approve</span>
        </button>
    </div>
</ng-template>
