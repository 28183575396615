import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

const entriesMap = new WeakMap();

const ro = new ResizeObserver((entries) => {
    for (const entry of entries) {
        if (entriesMap.has(entry.target)) {
            const comp = entriesMap.get(entry.target);
            comp._resizeCallback(entry);
        }
    }
});

@Directive({ selector: '[resizeObserver]' })
export class ResizeObserverDirective implements OnDestroy {
    @Output() resize = new EventEmitter();
    private resizeSubject = new Subject<any>();

    constructor(private el: ElementRef) {
        const target = this.el.nativeElement;
        entriesMap.set(target, this);
        ro.observe(target);
        this.resizeSubject.pipe(debounceTime(300)).subscribe((entry) => {
            this.resize.emit(entry);
        });
    }

    _resizeCallback(entry) {
        this.resizeSubject.next(entry);
    }

    ngOnDestroy() {
        const target = this.el.nativeElement;
        ro.unobserve(target);
        entriesMap.delete(target);
        this.resizeSubject.complete();
    }
}
