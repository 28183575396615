<div class="sticky-in-sheet">
    <div class="wrapper">
        <h3>
            <mat-icon *ngIf="contextIcon" class="context">{{ contextIcon }}</mat-icon>
            <span *ngIf="context" class="context">{{ context }} <ng-container *ngIf="title">:</ng-container> </span>
            <span class="sticky-title">{{ title }}</span>
        </h3>
        <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap=".5em">
            <button mat-button (click)="onCancel.emit(true)">
                {{ cancelTitle }}
            </button>
            <button *ngIf="!savingStatus && showSubmit" mat-raised-button color="primary" (click)="onSubmit.emit(true)">
                {{ savingTitle }}
            </button>
            <app-saving-button *ngIf="savingStatus"></app-saving-button>
        </div>
    </div>
</div>
