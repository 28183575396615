import { Cast, CastType, IDataModel, Ignore, InitialValue, Model } from '@klickdata/core/application';
import { CustomerData } from '@klickdata/core/customer';
import { ResourceData, Staff } from '@klickdata/core/resource/src/resource.model';
import { TaskData } from '@klickdata/core/task';
import { User, UserData } from '@klickdata/core/user/src/user.model';

export interface HrNotesData extends IDataModel {
    id?: number;
    created_at?: string;
    author?: UserData;
    creator?: User;
    body?: string;
    contact_type?: string;
    comments?: HrNotes[];
    parent_id?: number;
    user_id?: number;
    task_id?: number;
    customer_id?: number;
    resource_id?: number;
    tag_ids?: number[];
    media_ids?: number[];
    staff?: Staff;
    visibility?: string;
    context?: NotesContext;
}
export class HrNotes extends Model<HrNotesData> {
    id: number;
    body: string;
    contact_type: string;
    author: UserData;
    creator: User;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    @InitialValue([])
    comments: HrNotesData[];
    user_id: number;
    customer_id: number;
    resource_id: number;
    media_ids: number[];
    managers: any[];
    visibility: string;
    context: NotesContext;
}
export interface NotesContext {
    user?: UserData;
    customer?: CustomerData;
    resource?: ResourceData;
    task?: TaskData;
}
