import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, Observable, Subject, timer } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApplicationService implements OnDestroy {
    protected notificationSubject: BehaviorSubject<string[]>;
    protected notifications: string[] = [];
    protected version: BehaviorSubject<{ version: string; date: string }>;
    protected nVersion: BehaviorSubject<{ version: string; date: string }>;
    protected destroy: Subject<boolean> = new Subject();
    private _headerVisibilitySubj: Subject<boolean> = new Subject();

    constructor(protected http: HttpClient, updates: SwUpdate) {
        this.notificationSubject = new BehaviorSubject<string[]>([]);
        this.nVersion = new BehaviorSubject(null);
        try {
            this.version = new BehaviorSubject(JSON.parse(localStorage.getItem('nk3-version')));
        } catch (e) {
            this.version = new BehaviorSubject(null);
        }

        // updates.versionUpdates.subscribe((evt) => {
        //     switch (evt.type) {
        //         case 'VERSION_DETECTED':
        //             console.log(`Downloading new app version: ${evt.version.hash}`);
        //             break;
        //         case 'VERSION_READY':
        //             console.log(`Current app version: ${evt.currentVersion.hash}`);
        //             console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
        //             break;
        //         case 'VERSION_INSTALLATION_FAILED':
        //             console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
        //             break;
        //     }
        // });

        // updates.versionUpdates
        //     .pipe(
        //         filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        //         switchMap(() =>
        //             this.http.get<{ version: string; date: string }>(`version.json?${moment().format('DDMMYYHHmmSS')}`)
        //         ),
        //         catchError(() => EMPTY),
        //         takeUntil(this.destroy)
        //     )
        //     .subscribe((data) => this.handleVersionUpdate(data));

        this.startVersionCheck();
    }

    /**
     *
     * @returns {Observable<string[]>}
     */
    getNotifications(): Observable<string[]> {
        return this.notificationSubject.asObservable();
    }

    public notify(notificationName: string, data?: any) {
        this.notifications.push(notificationName);
        this.notificationSubject.next(this.notifications);
    }

    public removeNotification(notificationName: string) {
        this.notifications = this.notifications.filter((item) => item !== notificationName);
        this.notificationSubject.next(this.notifications);
    }

    public reload() {
        // window.location.reload();
        // location.reload();
        // Reload the page to update to the latest version.
        document.location.reload();
        localStorage.removeItem('nk3-version');
    }

    public getVersion(): Observable<{ version: string; date: string }> {
        return this.version.asObservable();
    }

    public getNewVersion(): Observable<{ version: string; date: string }> {
        return this.nVersion.asObservable();
    }

    public getVersionValue(): string {
        return this.version?.value?.version || this.nVersion?.value?.version || 'None';
    }

    protected notificationIsActive(notificationName): boolean {
        return this.notifications.indexOf(notificationName) > -1;
    }

    private startVersionCheck(): void {
        timer(0, 100000)
            .pipe(
                switchMap(() =>
                    this.http.get<{ version: string; date: string }>(`version.json?${moment().format('DDMMYYHHmmSS')}`)
                ),
                catchError(() => EMPTY),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data && !this.version.value?.version) {
                    localStorage.setItem('nk3-version', JSON.stringify(data));
                    this.version.next(data);
                }
                if (
                    this.version.value?.version &&
                    this.version.value.version !== data?.version &&
                    !this.notificationIsActive('version-alert')
                ) {
                    this.nVersion.next(data);
                    this.notify('version-alert');
                }
            });
    }

    public setHeaderVisisbility(visible: boolean) {
        this._headerVisibilitySubj.next(visible);
    }

    public getHeaderVisibility(): Observable<boolean> {
        return this._headerVisibilitySubj.asObservable();
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
