<div class="course-results-content-wrapper">
    <mat-accordion displayMode="flat">
        <mat-expansion-panel *ngFor="let item of items">
            <mat-expansion-panel-header>
                <div fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon
                        fxLayoutAlign="center center"
                        [matTooltip]="item.child_opportunity_done ? 'Done' : 'Not done'"
                        fontSet="klickdata"
                        [fontIcon]="displayCorrectIcon(item.item_type_value)"
                        class="done"
                        [ngClass]="{ 'not-done': !item.child_opportunity_done }"
                    ></mat-icon>
                    <span> {{ item.name }}</span>
                </div>
                <div class="required {{ getItemSpecs(item).class }}">
                    <mat-icon [matTooltip]="getItemSpecs(item).tooltip">bookmark_border</mat-icon>
                </div>
                <button
                    class="repeat-item mand-item"
                    (click)="$event.stopPropagation(); repeatItem(item)"
                    matTooltip="Study again. No records of your practice will be displayed in the course"
                    mat-icon-button
                    i18n-matTooltip
                >
                    <mat-icon>play_circle_outline</mat-icon>
                </button>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div
                    class="body-wrapper"
                    fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="space-between start"
                    fxLayoutAlign="space-between center"
                >
                    <div *ngIf="item.child_opportunity_started_at" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span class="dark-blue-color" i18n="@@firstAction">First action</span>:
                        <span class="ml-5">{{
                            item.child_opportunity_started_at | kdDateFormat : 'YYYY-MM-DD HH:mm'
                        }}</span>
                    </div>
                    <div *ngIf="item.child_opportunity_last_action" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span class="dark-blue-color" i18n="@@lastAction">Last action</span>:
                        <span class="ml-5">{{
                            item.child_opportunity_last_action | kdDateFormat : 'YYYY-MM-DD HH:mm'
                        }}</span>
                    </div>

                    <div *ngIf="item.created_at" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span class="dark-blue-color" i18n="@@createdAt">Created at</span>:
                        <span class="ml-5">{{ item.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                    </div>
                    <div *ngIf="item.updated_at" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span class="dark-blue-color" i18n="@@updatedAt">Updated at</span>:
                        <span class="ml-5">{{ item.updated_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                    </div>
                </div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>
