<ng-container *ngIf="type === 'select'">
    <span
        class="item-value"
        [ngClass]="{ capitalize: capitalize }"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="itemsMenu"
        >{{ value }}</span
    >
    <mat-menu #itemsMenu="matMenu">
        <button (click)="onValueChange.emit(option.value)" *ngFor="let option of selectionOptions" mat-menu-item>
            <span>{{ option.title }}</span>
        </button>
    </mat-menu>
</ng-container>
