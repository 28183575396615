import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Question } from '@klickdata/core/question';
import { QuestionOppoMetaData } from '@klickdata/core/question/src/question';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import { PlayerService } from '@klickdata/player/src/core/player/player.service';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { BehaviorSubject, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'app-questions-with-no-alts-results',
    templateUrl: './questions-with-no-alts-results.component.html',
    styleUrls: ['./questions-with-no-alts-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsWithNoAltsResultsComponent extends OnDestroyHandler implements OnInit {
    @Input() public question: Question;
    @Input() authUser: User;
    @Input() occasion: ResourceOpportunity;
    @Output() onQuestionUpdate: EventEmitter<Question> = new EventEmitter<Question>();
    public onLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(
        protected playerService: PlayerService,
        protected changeRef: ChangeDetectorRef,
        protected occasionService: OccasionService
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildSocket();
    }

    public updateQsReviewByAA() {
        this.playerService.updateSliderData({
            dataType: SideNaveDataTypes.QUESTION_REVIEW,
            data: {
                question: this.question,
                type: 'QsReview',
            },
        });
        this.playerService.changeSliderStatus(true);
        this.playerService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.playerService.getSideNavResponseData()),
                filter((response) => response.type === 'QsReview'),
                distinctUntilChanged((q1, q2) => q1 != q2),
                map((response) => response.data),
                switchMap((metadata) =>
                    this.occasionService.updateOccasion(this.occasion.id, {
                        metadata: { ...metadata, verifier: this.authUser.id, question_id: this.question.id },
                    })
                ),
                map((occ) => occ.metadata),
                takeUntil(this.destroy)
            )
            .subscribe((metadata: QuestionOppoMetaData) => {
                this.updateQsReview(metadata);
            });
    }

    public updateQsReviewByAI() {
        this.onLoading.next(true);
        this.occasionService
            .updateOccasion(this.occasion.id, {
                metadata: { verifier: 'ai', question_id: this.question.id },
            })
            .pipe(takeUntil(this.destroy))
            .subscribe();
    }

    private buildSocket() {
        this.occasionService
            .listenToOccasionUpdate(this.occasion.id, this.question.id)
            .pipe(
                takeUntil(this.destroy),
                filter((occ) => occ.metadata.question_id == this.question.id)
            )
            .subscribe((occ) => {
                this.updateQsReview(occ.metadata);
            });
    }
    updateQsReview(metadata: QuestionOppoMetaData) {
        this.onLoading.next(false);
        this.question.metadata = metadata;
        this.question.user_points = metadata.user_points;
        this.question.resultStyle = Utils.getQsStyle(this.question);
        this.onQuestionUpdate.emit(this.question);
        this.changeRef.markForCheck();
    }
}
