<mat-accordion multi="false" fxFlex="0 1 100%" displayMode="flat">
    <mat-expansion-panel [expanded]="true" #mainExpansionPanel class="user-manage-course-expantion main-panel">
        <mat-expansion-panel-header>
            <mat-icon>scatter_plot</mat-icon>
            <div [ngClass]="{ 'main-info-label': mainExpansionPanel.expanded }" i18n="@@eventInfo">Event info</div>
        </mat-expansion-panel-header>

        <div fxLayout="column" class="main-body-wrapper">
            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelMain
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>settings_applications</mat-icon>
                    <div [ngClass]="{ 'main-info-label': mainExpansionPanelMain.expanded }" i18n="@@mainInfo">
                        Main info
                    </div>
                </mat-expansion-panel-header>

                <div class="panel-body-wrapper">
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>description</mat-icon>
                                <div i18n="@@description">Description</div>
                                <mat-icon class="icon-explain" matTooltip="Set event description" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('description').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #desc
                                matInput
                                placeholder="Write a description and list what you will learn in this event. Why the learner shall spend time and what will be the benefit of take the Event."
                                i18n-placeholder
                                [maxlength]="defaultEventGoalCount"
                                minRows="3"
                                formControlName="description"
                            ></textarea>
                            <span *ngIf="desc.value" class="char-count">
                                {{ desc.value.length }}
                                <span>(</span>
                                {{ defaultEventGoalCount - desc.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>task_alt</mat-icon>
                                <div i18n="@@eventGoal">Event goal</div>
                                <mat-icon class="icon-explain" matTooltip="Set event goal" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('goal').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #goal
                                matInput
                                placeholder="Enter a goal for the event"
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultEventGoalCount"
                                formControlName="goal"
                            ></textarea>
                            <span *ngIf="goal.value" class="char-count">
                                {{ goal.value.length }}
                                <span>(</span>
                                {{ defaultEventGoalCount - goal.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>ads_click</mat-icon>
                                <div i18n>Audience</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Who is the target for the Event? The person or group who are the learners who will benefit from the knowledge covered in this event."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('audience').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #audience
                                matInput
                                placeholder="Enter audience for the event"
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultEventGoalCount"
                                formControlName="audience"
                            ></textarea>
                            <span *ngIf="audience.value" class="char-count">
                                {{ audience.value.length }}
                                <span>(</span>
                                {{ defaultEventGoalCount - audience.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>tips_and_updates</mat-icon>
                                <div i18n>Pre skills</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="The prerequisite knowledge that would be required from the participant prior to the course or the event."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('pre_skills').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #preSkills
                                matInput
                                placeholder="Enter pre skills for the event"
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultEventGoalCount"
                                formControlName="pre_skills"
                            ></textarea>
                            <span *ngIf="preSkills.value" class="char-count">
                                {{ preSkills.value.length }}
                                <span>(</span>
                                {{ defaultEventGoalCount - preSkills.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>subject</mat-icon>
                                <div i18n="@@eventInstructions">Event instruction</div>
                                <mat-icon class="icon-explain" matTooltip="Set event instruction" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('instructions').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                #instructions
                                matInput
                                placeholder="Enter instructions for the event"
                                i18n-placeholder
                                minRows="3"
                                [maxlength]="defaultEventGoalCount"
                                formControlName="instructions"
                            ></textarea>
                            <span *ngIf="instructions.value" class="char-count">
                                {{ instructions.value.length }}
                                <span>(</span>
                                {{ defaultEventGoalCount - instructions.value.length }}
                                <span i18n="@@left">left</span>
                                <span>)</span>
                            </span>
                        </mat-form-field>
                    </mat-expansion-panel>

                    <!-- End reward -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>military_tech</mat-icon>
                                <div i18n="@@endReward">End reward</div>

                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Set the reward for this Event. When you have a Final Test in the Event; the learner will receive either a Participation Certificate, a Diploma or a Certification."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('examination').get('type')?.dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                            <form [formGroup]="$any(resourceForm.get('examination'))" fxLayout="column">
                                <div
                                    class="mb-1"
                                    fxLayoutGap.gt-md="2em"
                                    fxLayoutAlign="start center"
                                    fxLayout="row"
                                    fxLayout.lt-md="column"
                                    fxLayoutAlign.lt-md="center start"
                                    fxFlex="1 0 100%"
                                >
                                    <br fxHide.gt-md fxShow.lt-md />
                                    <div
                                        fxLayoutGap.gt-md="5px"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                        fxFlex="1 0 50%"
                                        fxFlex.lt-md="1 0 100%"
                                    >
                                        <span fxFlex="0 0 auto" class="bold" i18n>Final test : </span>
                                        <mat-select
                                            fxFlex="0 0 60%"
                                            fxFlex.lt-md="0 0 auto"
                                            placeholder="Pls select final test"
                                            i18n-placeholder
                                            formControlName="final_test_id"
                                        >
                                            <mat-option
                                                *ngFor="let test of mandatoryTestResourceItems | async"
                                                [value]="test.child_resource_id"
                                            >
                                                {{ test.name }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <br fxHide.gt-md fxShow.lt-md />
                                    <div
                                        fxLayoutGap.gt-md="5px"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                        fxFlex="1 0 50%"
                                        fxFlex.lt-md="1 0 100%"
                                    >
                                        <span fxFlex="0 0 auto" class="bold" i18n>Reward type : </span>
                                        <mat-select
                                            fxFlex="0 0 60%"
                                            fxFlex.lt-md="0 0 100%"
                                            placeholder="Place choose reward type"
                                            i18n-placeholder
                                            formControlName="type"
                                        >
                                            <mat-option value="Participation" i18n="@@participation">
                                                Participation
                                            </mat-option>
                                            <mat-option *ngIf="itemsHaveTests" value="Diploma" i18n="@@diploma">
                                                Diploma
                                            </mat-option>
                                            <mat-option
                                                *ngIf="itemsHaveTests"
                                                value="Certification"
                                                i18n="@@certification"
                                            >
                                                Certification
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div
                                    class="mb-1"
                                    fxLayoutGap.gt-md="2em"
                                    fxLayoutAlign="start center"
                                    fxLayout="row"
                                    fxLayout.lt-md="column"
                                    fxFlex="1 0 100%"
                                    fxLayoutAlign.lt-md="center start"
                                >
                                    <div
                                        fxLayoutGap.gt-md="5px"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                        fxFlex="1 0 20%"
                                        fxFlex.lt-md="1 0 70%"
                                        *ngIf="resourceForm.get('examination').value.type === 'Diploma'"
                                    >
                                        <span fxFlex="0 0 auto" class="bold">
                                            <span i18n="@@diplomaLevel">Diploma level</span>
                                            <mat-icon
                                                class="icon-reward-explain"
                                                matTooltip="Diploma; either you pass the level set as a percentage and get a Diploma or you don’t and get a Participation Certificate."
                                                i18n-matTooltip
                                                >help_outline</mat-icon
                                            >:
                                        </span>

                                        <mat-form-field fxFlex="0 0 25%" fxFlex.lt-md="0 0 60px">
                                            <input
                                                matInput
                                                type="number"
                                                min="0"
                                                max="100"
                                                formControlName="diploma_level"
                                            />
                                            <span class="percent" fxFlex="0 0 auto">%</span>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        fxLayoutGap.gt-md="5px"
                                        fxLayoutAlign="start center"
                                        fxLayout="row"
                                        fxFlex="1 0 70%"
                                        fxFlex.lt-md="1 0 100%"
                                        class="grade-system-wrapper"
                                        *ngIf="resourceForm.get('examination')?.value.type !== 'Participation'"
                                    >
                                        <span fxFlex="0 0 auto" class="bold" fxHide.lt-md="true">
                                            <span i18n="@@gradeSystem">Grade system</span>
                                            <mat-icon
                                                class="icon-reward-explain"
                                                matTooltip="The Grade system is used for different levels of reward passing the Final test. You can define your own Grade System or use a template."
                                                i18n-matTooltip
                                                >help_outline</mat-icon
                                            >
                                            :
                                        </span>

                                        <app-admin-grade-system-control
                                            fxFlex="0 0 70%"
                                            fxFlex.lt-md="0 0 100%"
                                            [gradeControl]="
                                                resourceForm.get('examination')?.get('grade_system_id') | cast
                                            "
                                            [languageId]="resourceForm.get('language_id').value"
                                        >
                                        </app-admin-grade-system-control>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-expansion-panel>
                    <!-- Playtime and duration -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>hourglass_top</mat-icon>
                                <div i18n="@@playtimeAndDuration">Playtime and duration</div>
                                <mat-icon class="icon-explain" matTooltip="Playtime and duration" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green:
                                            resourceForm.get('time_limit').dirty || resourceForm.get('duration').dirty
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="mb-2" fxLayout="column" fxFlex="0 1 100%" [fxHide]="false">
                            <div
                                fxFlex="0 0 100%"
                                fxLayout="row"
                                fxLayout.lt-md="column"
                                fxLayoutAlign="space-between"
                                class="duration-picker-wrapper"
                            >
                                <div
                                    fxLayout.lt-md="column"
                                    fxLayoutAlign="flex-start center"
                                    fxLayoutAlign.lt-md="flex-start start"
                                    fxFlex="0 0 48%"
                                    fxFlex.lt-md="0 0 100%"
                                >
                                    <span class="controller-title" i18n>Play time</span>
                                    <ngx-duration-picker
                                        formControlName="time_limit"
                                        [options]="playTimeOptions"
                                    ></ngx-duration-picker>
                                </div>
                                <div
                                    fxLayout.lt-md="column"
                                    fxLayoutAlign="flex-start center"
                                    fxLayoutAlign.lt-md="flex-start start"
                                    fxFlex="0 0 48%"
                                    fxFlex.lt-md="0 0 100%"
                                >
                                    <span class="controller-title" i18n>Event duration</span>
                                    <ngx-duration-picker
                                        formControlName="duration"
                                        [options]="durationOptions"
                                    ></ngx-duration-picker>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #occasionExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>calendar_view_month</mat-icon>
                                <div i18n>Occasions</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="The event time on a calendar when onsite, webinar, classroom, or synchronous teaching."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('episode').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <app-text-editor
                            *ngIf="occasionExpansionPanel.expanded"
                            fxFlex="1 1 100%"
                            title="Event occasions"
                            i18n-title
                            [showTemplates]="false"
                            formControlName="episode"
                        ></app-text-editor>
                    </mat-expansion-panel>
                    <!-- Other information -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>info</mat-icon>
                                <div i18n="@@other_info">Other information</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Here you can add other information needed for the users who will take this event"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('other_info').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field fxFlex="0 1 100%">
                            <textarea
                                matInput
                                placeholder="Enter other information"
                                i18n-placeholder
                                formControlName="other_info"
                            ></textarea>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <!-- Reply message  -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>mark_chat_read</mat-icon>
                                <div i18n>Reply message</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="The message to be displayed after learners finish registeration"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('reply_msg').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-form-field fxFlex="0 1 100%">
                            <input
                                matInput
                                placeholder="Set reply message after registerations"
                                i18n-placeholder
                                formControlName="reply_msg"
                            />
                        </mat-form-field>
                    </mat-expansion-panel>
                    <!-- External presentation  -->
                    <ng-container formGroupName="medias">
                        <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>picture_as_pdf</mat-icon>
                                    <div i18n>External presentation</div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="Add a document about this resource that explains it for download, typically in PDF with the profile of your organization in marketing this course/ event/ test/ survey .Add a document about this resource that explains it for download, typically in PDF with the profile of your organization in marketing this course/ event/ test/ survey."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{ green: resourceForm.get('medias').get('brochure').dirty }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <!-- <app-media-attachment
                                #uploaderBrochure
                                class="mt-1 res-media-attach-select brochure"
                                [maxImgCount]="1"
                                formControlName="brochure"
                                [scope]="AppScope.EVENT"
                                [mediaIds]="resourceForm.value.medias?.brochure"
                                [isCreateForm]="true"
                            ></app-media-attachment>
                            <div fxLayoutAlign="center center" *ngIf="uploaderBrochure.saving | async">
                                <app-kd-logo-loading></app-kd-logo-loading> -->
                            <ng-container
                                *ngIf="!resourceForm.value?.medias?.brochure?.length"
                                [ngTemplateOutlet]="mediaFileUploader"
                                [ngTemplateOutletContext]="{ controlName: 'brochure' }"
                            >
                            </ng-container>
                            <app-media-view
                                *ngIf="resourceForm.value?.medias?.brochure?.length"
                                fxLayoutAlign="center center"
                                fxFlex="0 0 90%"
                                [media_id]="resourceForm.value?.medias?.brochure[0].toString()"
                            >
                                <button
                                    class="remove-file"
                                    color="warn"
                                    type="button"
                                    fxFlex="0 1 auto"
                                    mat-icon-button
                                    matTooltip="Remove file"
                                    i18n-matTooltip
                                    (click)="resourceForm.get('medias').patchValue({ brochure: '' })"
                                >
                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                </button>
                            </app-media-view>
                            <!-- </div> -->
                        </mat-expansion-panel>
                        <!-- Video document  -->
                        <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>video_library</mat-icon>
                                    <div i18n>Video presentation</div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="Video presentation of this course presenting it with a trailer"
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{ green: resourceForm.get('medias').get('trailer').dirty }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-container
                                *ngIf="!resourceForm.value?.medias?.trailer?.length"
                                [ngTemplateOutlet]="mediaFileUploader"
                                [ngTemplateOutletContext]="{ controlName: 'trailer' }"
                            >
                            </ng-container>
                            <app-media-view
                                *ngIf="resourceForm.value?.medias?.trailer?.length"
                                fxLayoutAlign="center center"
                                fxFlex="0 0 90%"
                                [media_id]="resourceForm.value?.medias?.trailer[0].toString()"
                            >
                                <button
                                    class="remove-file"
                                    color="warn"
                                    type="button"
                                    fxFlex="0 1 auto"
                                    mat-icon-button
                                    matTooltip="Remove file"
                                    i18n-matTooltip
                                    (click)="resourceForm.get('medias').patchValue({ trailer: '' })"
                                >
                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                </button>
                            </app-media-view>
                        </mat-expansion-panel>
                    </ng-container>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelImages
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>collections</mat-icon>
                    <div [ngClass]="{ 'main-info-label': mainExpansionPanelImages.expanded }" i18n="@@eventImages">
                        Event images
                    </div>
                </mat-expansion-panel-header>

                <div class="panel-body-wrapper">
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>add_photo_alternate</mat-icon>
                                <div i18n="@@coverImg">Cover image</div>
                                <mat-icon class="icon-explain" matTooltip="It's the image of the event" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('media_id').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center">
                            <app-media
                                #app_media
                                *ngIf="!resourceForm.get('media_id')?.value"
                                fxFlex="1 1 50%"
                                fxFlex.lt-md="1 1 100%"
                                formControlName="media_id"
                                [scope]="AppScope.COURSE"
                                dropLabel="Drop event image"
                                i18n-dropLabel="@@dropEventImage"
                            ></app-media>
                            <app-image-view
                                (click)="
                                    showImgModal(
                                        resourceForm.get('media_id')?.value
                                            ? resourceForm.get('media_id')?.value
                                            : 'assets/images/Default_courses_img.png'
                                    )
                                "
                                class="mt-2 mb-2"
                                *ngIf="resourceForm.get('media_id')?.value"
                                fxFlex="0 1 50%"
                                fxFlex.lt-md="0 1 100%"
                                [media_id]="
                                    resourceForm.get('media_id')?.value
                                        ? resourceForm.get('media_id')?.value
                                        : 'assets/images/Default_courses_img.png'
                                "
                                paddingBottom="50%"
                            >
                                <button
                                    class="remove-image"
                                    mat-icon-button
                                    color="warn"
                                    *ngIf="resourceForm.get('media_id').value as mediaId"
                                    matTooltip="Remove image"
                                    i18n-matTooltip
                                    (click)="resourceForm.get('media_id').setValue(null)"
                                >
                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                </button>
                            </app-image-view>
                        </div>
                    </mat-expansion-panel>
                    <div formGroupName="medias">
                        <mat-expansion-panel #expansionPanelBG class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>photo_size_select_actual</mat-icon>
                                    <div
                                        [ngClass]="{ 'main-info-label': expansionPanelBG.expanded }"
                                        i18n="@@backgroundImg"
                                    >
                                        Background image
                                    </div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="Background for the event in the event details page."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: resourceForm.get('medias')?.get('background')?.dirty
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div fxLayoutAlign="center center" fxLayout="row" fxLayout.lt-md="column">
                                <div class="imgWrapper" fxLayout="column" fxLayoutAlign="center center">
                                    <div fxLayoutAlign="center center">
                                        <app-media
                                            *ngIf="!resourceForm.get('medias').value.background"
                                            formControlName="background"
                                            [scope]="AppScope.EVENT"
                                            dropLabel="Drop background image"
                                            i18n-dropLabel="@@dropBGImage"
                                        ></app-media>
                                        <app-image-view
                                            (click)="showImgModal(mediaId)"
                                            *ngIf="resourceForm.get('medias').value.background as mediaId"
                                            [media_id]="mediaId"
                                            [proportional]="false"
                                        >
                                            <button
                                                *ngIf="resourceForm.get('medias').value.background"
                                                class="remove-image"
                                                mat-icon-button
                                                color="warn"
                                                matTooltip="Remove image"
                                                i18n-matTooltip
                                                (click)="resourceForm.get('medias').get('background').setValue(null)"
                                            >
                                                <mat-icon class="md-24">delete_outline</mat-icon>
                                            </button>
                                        </app-image-view>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel #expansionPanelScreenShot class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>center_focus_strong</mat-icon>
                                    <div
                                        [ngClass]="{ 'main-info-label': expansionPanelScreenShot.expanded }"
                                        i18n="@@screenshotImg"
                                    >
                                        Screenshot image
                                    </div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="A thumbnail from the video (or a homepage) This image is a Screenshot from the creator to be uploaded."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: resourceForm.get('medias')?.get('screenshot').dirty
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div fxLayoutAlign="center center" fxLayout="row" fxLayout.lt-md="column">
                                <div class="imgWrapper" fxLayout="column" fxLayoutAlign="center center">
                                    <div fxLayoutAlign="center center">
                                        <app-media
                                            *ngIf="!resourceForm.get('medias').value.screenshot"
                                            formControlName="screenshot"
                                            [scope]="AppScope.EVENT"
                                            dropLabel="Drop screenshot image"
                                            i18n-dropLabel="@@dropScreenshotImage"
                                        ></app-media>
                                        <app-image-view
                                            (click)="showImgModal(mediaId)"
                                            *ngIf="resourceForm.get('medias').value.screenshot as mediaId"
                                            [media_id]="mediaId"
                                            [proportional]="false"
                                        >
                                            <button
                                                *ngIf="resourceForm.get('medias').value.screenshot"
                                                class="remove-image"
                                                mat-icon-button
                                                color="warn"
                                                matTooltip="Remove image"
                                                i18n-matTooltip
                                                (click)="resourceForm.get('medias').get('screenshot').setValue(null)"
                                            >
                                                <mat-icon class="md-24">delete_outline</mat-icon>
                                            </button>
                                        </app-image-view>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel #expansionPanelpublisher class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>fitbit</mat-icon>
                                    <div
                                        [ngClass]="{ 'main-info-label': expansionPanelpublisher.expanded }"
                                        i18n="@@publisherLogo"
                                    >
                                        Publisher logo
                                    </div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="The displayed logo in the event registeration page, this will affect when you make the event as public, You academy logo will always appear if you published the event."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: resourceForm.get('medias')?.get('publisher').dirty
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div
                                fxLayoutAlign="start start"
                                fxFlex="0 1 100%"
                                class="mt-1"
                                fxLayout="column"
                                fxLayoutGap="10px"
                                fxLayout.lt-md="column"
                                *ngIf="expansionPanelpublisher.expanded"
                            >
                                <mat-radio-group
                                    [formControl]="resourceForm.get('show_publisher_logo') | cast"
                                    fxLayoutAlign="start center"
                                    fxLayoutGap="70px"
                                    fxLayoutGap.lt-sm="20px"
                                >
                                    <mat-radio-button [value]="0">
                                        <span i18n>Display KlickData Open Library (KOL) logo</span>
                                    </mat-radio-button>
                                    <mat-radio-button [value]="1">
                                        <span i18n>Display academy logo</span>
                                    </mat-radio-button>
                                    <mat-radio-button [value]="-1">
                                        <span i18n>Upload logo</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                                <div
                                    *ngIf="resourceForm.value.show_publisher_logo === -1"
                                    class="mt-1 w-100"
                                    fxLayoutAlign="center center"
                                    fxFlex="0 1 100%"
                                >
                                    <app-media
                                        *ngIf="!resourceForm.get('medias').value.publisher"
                                        formControlName="publisher"
                                        [scope]="AppScope.EVENT"
                                        dropLabel="Drop logo image"
                                        i18n-dropLabel="@@dropLogoImage"
                                    ></app-media>
                                    <app-image-view
                                        (click)="showImgModal(mediaId)"
                                        *ngIf="resourceForm.get('medias').value.publisher as mediaId"
                                        [media_id]="mediaId"
                                        [proportional]="false"
                                    >
                                        <button
                                            *ngIf="resourceForm.get('medias').value.publisher"
                                            class="remove-image"
                                            mat-icon-button
                                            color="warn"
                                            matTooltip="Remove image"
                                            i18n-matTooltip
                                            (click)="resourceForm.get('medias').get('publisher').setValue(null)"
                                        >
                                            <mat-icon class="md-24">delete_outline</mat-icon>
                                        </button>
                                    </app-image-view>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelSpecifications
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>announcement</mat-icon>
                    <div
                        [ngClass]="{ 'main-info-label': mainExpansionPanelSpecifications.expanded }"
                        i18n="@@eventSpecifications"
                    >
                        Event specifications
                    </div>
                </mat-expansion-panel-header>

                <div class="panel-body-wrapper">
                    <ng-container formGroupName="staff">
                        <!-- Instructor -->
                        <mat-expansion-panel #educatorExpansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>engineering</mat-icon>
                                    <div i18n>Educator</div>
                                    <mat-icon class="icon-explain" matTooltip="Manage event educators" i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{ green: resourceForm.get('staff').get('educator')?.dirty }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <app-resource-educator-handler
                                    [educatorRole]="ResourceStaffRoles.EDUCATOR"
                                    *ngIf="educatorExpansionPanel.expanded"
                                    [resource]="resource"
                                    formControlName="educator"
                                    educatorLabel="Educator"
                                    i18n-educatorLabel
                                >
                                </app-resource-educator-handler>
                            </ng-template>
                        </mat-expansion-panel>
                        <!-- Host -->
                        <mat-expansion-panel #hostExpansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>record_voice_over</mat-icon>
                                    <div i18n>Host</div>
                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="The Host is the one on the stage and he is the show or an event's central figure."
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{ green: resourceForm.get('staff').get('host')?.dirty }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <app-resource-educator-handler
                                    *ngIf="hostExpansionPanel.expanded"
                                    class="publisher-edu-handler"
                                    [resource]="resource"
                                    [maxNumberOfEducatore]="1"
                                    [setPredefinedEducator]="!resourceForm.value.staff?.host"
                                    formControlName="host"
                                    searchPlaceholder="Search existing host people"
                                    i18n-searchPlaceholder
                                    [educatorRole]="ResourceStaffRoles.HOST"
                                    i18n-addingLabel
                                    addingLabel="Add a new host"
                                    educatorLabel="Host"
                                    i18n-educatorLabel
                                ></app-resource-educator-handler>
                            </ng-template>
                        </mat-expansion-panel>
                        <!-- Organizer -->
                        <mat-expansion-panel #organizerExpansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>person_4</mat-icon>
                                    <div i18n="@@organizer">Organizer</div>

                                    <mat-icon
                                        class="icon-explain"
                                        matTooltip="It's the academy or the company, or it could be a human who organize the event, attendess and help any one"
                                        i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: resourceForm.get('staff').get('organizer').dirty
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <div fxLayoutGap="10px" fxLayout="column" fxFlex="0 1 100%">
                                    <app-resource-educator-handler
                                        *ngIf="organizerExpansionPanel.expanded"
                                        class="publisher-edu-handler"
                                        [resource]="resource"
                                        [maxNumberOfEducatore]="1"
                                        [setPredefinedEducator]="!resourceForm.value.staff?.organizer"
                                        formControlName="organizer"
                                        searchPlaceholder="Search existing organizers"
                                        i18n-searchPlaceholder
                                        [educatorRole]="ResourceStaffRoles.ORGANIZER"
                                        i18n-addingLabel
                                        addingLabel="Add a new organizer"
                                        educatorLabel="Organizer"
                                        i18n-educatorLabel
                                    ></app-resource-educator-handler>
                                </div>
                            </ng-template>
                        </mat-expansion-panel>
                        <!-- Publisher -->
                        <mat-expansion-panel #publisherExpansionPanel class="user-manage-course-expantion">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>attribution</mat-icon>
                                    <div i18n="@@publisher">Publisher</div>

                                    <mat-icon class="icon-explain" matTooltip="Add event publisher" i18n-matTooltip
                                        >help_outline</mat-icon
                                    >
                                </mat-panel-title>
                                <mat-panel-description fxLayoutAlign="end center">
                                    <span
                                        matTooltip="Indicator of field change"
                                        i18n-matTooltip
                                        class="cell-indicator"
                                        [ngClass]="{
                                            green: resourceForm.get('staff').get('publisher').dirty
                                        }"
                                    ></span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <app-resource-publisher-handler fxFlex="0 0 100%" formControlName="publisher">
                                </app-resource-publisher-handler>
                                <!-- <mat-form-field fxFlex="0 1 100%">
                                    <input
                                        matInput
                                        type="number"
                                        placeholder="Please set publisher name"
                                        i18n-placeholder
                                        [formControl]="publisherName"
                                    />
                                </mat-form-field> -->
                            </ng-template>
                        </mat-expansion-panel>
                    </ng-container>
                    <!-- Price -->
                    <mat-expansion-panel #priceExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>local_offer</mat-icon>
                                <div i18n="@@price">Price</div>

                                <mat-icon class="icon-explain" matTooltip="Price" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('price').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div
                            class="mb-2"
                            fxLayoutGap.gt-md="2em"
                            fxLayout.lt-md="column"
                            fxFlex="0 1 100%"
                            [fxHide]="false"
                        >
                            <mat-form-field fxFlex="0 0 40%" fxLayout.lt-md="0 0 90%">
                                <input
                                    matInput
                                    placeholder="Please set event price"
                                    i18n-placeholder
                                    formControlName="price"
                                />
                            </mat-form-field>
                            <mat-select
                                class="select-curr"
                                fxFlex="0 0 40%"
                                fxLayout.lt-md="0 0 90%"
                                placeholder="Place choose currency"
                                i18n-placeholder
                                formControlName="currency"
                            >
                                <mat-option *ngFor="let currency of currencies" [value]="currency.code">
                                    {{ currency.label }} - {{ currency.sign }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </mat-expansion-panel>
                    <!-- Promo code -->
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>card_giftcard</mat-icon>
                                <div i18n="@@promoCode">Promo code</div>
                                <mat-icon class="icon-explain" matTooltip="Promo code" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('promo_code').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field fxFlex="0 1 100%">
                            <input
                                matInput
                                placeholder="Please set a promo code for this event"
                                i18n-placeholder
                                formControlName="promo_code"
                            />
                        </mat-form-field>
                    </mat-expansion-panel>
                    <!-- Event code -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>fingerprint</mat-icon>
                                <div i18n="@@SKU-ArticleNumber">SKU/ Article number</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Event code is a unique field, autogenerated when empty."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('article_code').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field fxFlex="0 1 100%">
                            <input
                                matInput
                                placeholder="Please set event code"
                                i18n-placeholder
                                formControlName="article_code"
                            />
                        </mat-form-field>
                        <span *ngIf="resourceForm.get('article_code').dirty">
                            <p *ngIf="vaildArticleCode | async; let isValid; else: smallLoading">
                                <span class="valid" *ngIf="isValid === 1" i18n="@@eventCodeIsValid"
                                    >Event code is valid</span
                                >
                                <span
                                    class="error"
                                    *ngIf="isValid === -1"
                                    i18n="@@eventCodeIsTakenPleaseEnterAnotherEventCode"
                                    >Event code is taken, please enter another event code</span
                                >
                            </p>
                        </span>
                    </mat-expansion-panel>
                    <!-- Subtitle languages -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>subtitles</mat-icon>
                                <div i18n="@@subtitles">Subtitle languages</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="If Subtitles are availible in the video for exampel ; Info Copied from TED or YouTube"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('subtitles').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayoutGap="1em" fxLayoutAlign="start center">
                            <app-title-translation
                                type="description"
                                placeholder="Enter language subtitle"
                                i18n-placeholder
                                formControlName="subtitles"
                                class="icon-resource-builder"
                                fxFlex="0 0 auto"
                            ></app-title-translation>
                        </div>
                    </mat-expansion-panel>
                    <!-- Event seats -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>event_seat</mat-icon>
                                <div i18n="@@eventSeats">Event seats</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Total seats can be unlimited or specified"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: unlimited_seats.dirty || resourceForm.get('total_seats').dirty
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div
                            fxFlex="0 1 100%"
                            fxLayout.lt-md="column"
                            fxLayoutAlign="space-between center"
                            fxLayoutAlign.lt-md="space-between start"
                        >
                            <mat-checkbox
                                #unlimited
                                fxLayoutAlign="start center"
                                fxFlex="0 0 20%"
                                color="primary"
                                i18n="@@unlimited"
                                [formControl]="unlimited_seats"
                                >Unlimited</mat-checkbox
                            >
                            <mat-form-field fxFlex="0 1 80%">
                                <input
                                    matInput
                                    fxLayoutAlign="end center"
                                    type="number"
                                    placeholder="Please set total seats of the event"
                                    i18n-placeholder
                                    formControlName="total_seats"
                                />
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                    <!-- Extra seats -->
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>weekend</mat-icon>
                                <div i18n="@@extraSeats">Extra seats</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Extra seats activated when seats are full."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('extra_seats').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field fxFlex="0 1 100%">
                            <input
                                matInput
                                type="number"
                                placeholder="Please set extra seats needed for the event"
                                i18n-placeholder
                                formControlName="extra_seats"
                            />
                        </mat-form-field>
                    </mat-expansion-panel>
                    <!-- Copyrights -->
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>copyright</mat-icon>
                                <div i18n="@@copyrights">Copyrights</div>
                                <mat-icon class="icon-explain" matTooltip="Set event copyrights" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('copyrights').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <textarea
                                matInput
                                placeholder="Enter copyrights for the event"
                                i18n-placeholder
                                minRows="1"
                                formControlName="copyrights"
                            ></textarea>
                        </mat-form-field>
                    </mat-expansion-panel>
                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="prio-icon">low_priority</mat-icon>
                                <div i18n="@@priority">Priority</div>
                                <mat-icon class="icon-explain" matTooltip="Set event priority" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('priority').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- <mat-form-field class="summary-field" fxFlex="0 1 100%">
                            <input
                                matInput
                                fxLayoutAlign="end center"
                                type="number"
                                placeholder="Please set priority"
                                i18n-placeholder="@@plsSetPriority"
                                formControlName="priority"
                            />
                        </mat-form-field> -->
                        <mat-form-field class="role-field">
                            <mat-select fxFlex="1 1 100%" formControlName="priority">
                                <mat-option value="a1" i18n>A1</mat-option>
                                <mat-option value="a2" i18n>A2</mat-option>
                                <mat-option value="a3" i18n>A3</mat-option>
                                <mat-option value="b1" i18n>B1</mat-option>
                                <mat-option value="b2" i18n>B2</mat-option>
                                <mat-option value="b3" i18n>B3</mat-option>
                                <mat-option value="c1" i18n>C1</mat-option>
                                <mat-option value="c2" i18n>C2</mat-option>
                                <mat-option value="c3" i18n>C3</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-expansion-panel>
                </div>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelSpecifications
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>key</mat-icon>
                    <div [ngClass]="{ 'main-info-label': mainExpansionPanelSpecifications.expanded }" i18n>
                        Access control
                    </div>
                </mat-expansion-panel-header>

                <form [formGroup]="$any(resourceForm.get('collaboration'))" class="panel-body-wrapper">
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>preview</mat-icon>
                                <div i18n>Visibility</div>
                                <mat-icon class="icon-explain" matTooltip="Make visibility for Academy" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('collaboration').get('view').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-radio-group
                            formControlName="view"
                            fxLayoutAlign="start center"
                            fxLayoutGap="70px"
                            fxLayoutGap.lt-sm="20px"
                            class="mt-1"
                        >
                            <mat-radio-button [value]="true">
                                <span i18n>Yes</span>
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                                <span i18n>No</span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>priority_high</mat-icon>
                                <div i18n>Mandatory</div>
                                <mat-icon class="icon-explain" matTooltip="Make Mandatory for Academy" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('collaboration').get('mandatory').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-radio-group
                            formControlName="mandatory"
                            fxLayoutAlign="start center"
                            fxLayoutGap="70px"
                            fxLayoutGap.lt-sm="20px"
                            class="mt-1"
                        >
                            <mat-radio-button [value]="true">
                                <span i18n>Yes</span>
                            </mat-radio-button>
                            <mat-radio-button [value]="false">
                                <span i18n>No</span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>play_circle</mat-icon>
                                <div i18n>Approval for enroll</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Allow learners to join/take/enroll with or without permission"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: resourceForm.get('collaboration').get('enroll_approval_required').dirty
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                formControlName="enroll_approval_required"
                                fxLayoutAlign="start center"
                                fxLayoutGap="70px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>assignment_turned_in</mat-icon>
                                <div i18n>Approval /Sign off</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Learners Signoff and get approved by an authorized administrator"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: resourceForm.get('collaboration').get('signoff_required').dirty
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                formControlName="signoff_required"
                                fxLayoutAlign="start center"
                                fxLayoutGap="70px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </mat-expansion-panel>
                </form>
            </mat-expansion-panel> -->
            <mat-expansion-panel
                [expanded]="true"
                #mainExpansionPanelSpecifications
                class="user-manage-course-expantion main-panel"
            >
                <mat-expansion-panel-header>
                    <mat-icon>key</mat-icon>
                    <div [ngClass]="{ 'main-info-label': mainExpansionPanelSpecifications.expanded }" i18n>
                        Access control
                    </div>
                </mat-expansion-panel-header>

                <form [formGroup]="$any(resourceForm.get('collaboration'))" class="panel-body-wrapper">
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>preview</mat-icon>
                                <div i18n>Visibility</div>
                                <mat-icon class="icon-explain" matTooltip="Make visibility for Academy" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('collaboration').get('view').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                fxFlex="grow"
                                formControlName="view"
                                fxLayoutAlign="start center"
                                fxLayoutGap="70px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>
                            <button
                                type="button"
                                matTooltip="Specify authorization"
                                i18n-matTooltip
                                class="specify-btn"
                                mat-icon-button
                                (click)="authorizeUsers()"
                            >
                                <mat-icon>manage_accounts</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>priority_high</mat-icon>
                                <div i18n>Mandatory</div>
                                <mat-icon class="icon-explain" matTooltip="Make Mandatory for Academy" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: resourceForm.get('collaboration').get('mandatory').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                fxFlex="grow"
                                formControlName="mandatory"
                                fxLayoutAlign="start center"
                                fxLayoutGap="70px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>
                            <button
                                type="button"
                                matTooltip="Specify authorization"
                                i18n-matTooltip
                                class="specify-btn"
                                mat-icon-button
                                (click)="authorizeUsers()"
                            >
                                <mat-icon>manage_accounts</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>play_circle</mat-icon>
                                <div i18n>Approval for enroll</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Allow learners to join/take/enroll with or without permission"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: resourceForm.get('collaboration').get('enroll_approval_required').dirty
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                fxFlex="grow"
                                formControlName="enroll_approval_required"
                                fxLayoutAlign="start center"
                                fxLayoutGap="25px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>

                            <button
                                type="button"
                                matTooltip="Specify authorization"
                                i18n-matTooltip
                                class="specify-btn"
                                mat-icon-button
                                (click)="authorizeUsers()"
                            >
                                <mat-icon>manage_accounts</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>assignment_turned_in</mat-icon>
                                <div i18n>Approval /Sign off</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Learners Signoff and get approved by an authorized administrator"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field is mandatory"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{
                                        green: resourceForm.get('collaboration').get('signoff_required').dirty
                                    }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
                            <mat-radio-group
                                fxFlex="grow"
                                formControlName="signoff_required"
                                fxLayoutAlign="start center"
                                fxLayoutGap="25px"
                                fxLayoutGap.lt-sm="20px"
                                class="mt-1"
                            >
                                <mat-radio-button [value]="true">
                                    <span i18n>Yes</span>
                                </mat-radio-button>
                                <mat-radio-button [value]="false">
                                    <span i18n>No</span>
                                </mat-radio-button>
                            </mat-radio-group>
                            <button
                                type="button"
                                matTooltip="Specify authorization"
                                i18n-matTooltip
                                class="specify-btn"
                                mat-icon-button
                                (click)="authorizeUsers()"
                            >
                                <mat-icon>manage_accounts</mat-icon>
                            </button>
                        </div>
                    </mat-expansion-panel>
                </form>
            </mat-expansion-panel>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="start center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #fileUploader let-controlName="controlName">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div
            [ngStyle]="dropStyle"
            fxFlex="0 0 50%"
            fxFlex.lt-md="0 0 90%"
            class="media-dropzone"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
        >
            <input
                class="media-upload-button"
                type="file"
                (change)="prepareFile($event, controlName)"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                        text/plain, application/pdf,.xlsx,.xls, image/*, video/3gpp, video/mp4,
                        video/webm,audio/*, application/vnd.apple.keynote"
            />
        </div>

        <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
            <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
            <span class="progress-value">{{ progress }}%</span>
        </div>
    </div>
</ng-template>
<ng-template #mediaFileUploader let-controlName="controlName">
    <div class="media-file-uploaded-wrapper">
        <span i18n>Drop Image</span>
        <div fxLayoutAlign="center center" fxLayoutGap="10px">
            <button color="primary" mat-raised-button (click)="uploader.click()">
                <mat-icon>attach_file</mat-icon>
                <span i18n>Upload file</span>
            </button>
            <input
                fxHide
                #uploader
                class="media-upload"
                type="file"
                type="file"
                (change)="prepareFile($event, controlName)"
                [multiple]="false"
            />
            <button color="primary" mat-raised-button (click)="addLink()">
                <mat-icon>link</mat-icon>
                <span i18n>Add link</span>
            </button>
        </div>
    </div>

    <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
        <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
        <span class="progress-value">{{ progress }}%</span>
    </div>
</ng-template>
