<div *ngIf="items$ | async; let items; else: loading">
    <mat-tab-group [selectedIndex]="tabsIndex" class="midddle-part-tabs">
        <mat-tab *ngIf="tabsVisisbility[0]" fxFlex label="Content" i18n-label="@@content">
            <div fxFlex="0 1 100%" fxLayout="column">
                <app-course-player-results-content
                    [items]="items"
                    (repeatCourse)="retakeCourse.emit(resource)"
                ></app-course-player-results-content>
            </div>
        </mat-tab>
        <mat-tab *ngIf="courseHasTests && tabsVisisbility[1]" fxFlex label="Questions" i18n-label="@@questions">
            <div fxFlex="0 1 100%" class="course-result-qs" fxLayout="column">
                <app-course-player-results-questions
                    [user]="user"
                    [items]="items"
                ></app-course-player-results-questions>
            </div>
        </mat-tab>
        <mat-tab *ngIf="tabsVisisbility[2]" fxFlex label="Results" i18n-label="@@results">
            <div fxFlex="0 1 100%" fxLayout="column">
                <app-course-player-results-result
                    *ngIf="opportunity$ | async; let opp; else: loading"
                    [user]="user"
                    [rewardType]="rewardType"
                    [opportunity]="opp"
                    (repeatCourse)="retakeCourse.emit(resource)"
                ></app-course-player-results-result>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #loading>
    <div class="mt-2" fxLayout="row" fxLayoutAlign="center center">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
