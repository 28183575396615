<ng-container *ngIf="customerLanding$ | async; let customerLanding">
    <ng-container *ngIf="user$ | async">
        <footer class="footer main" fxLayout="row" fxLayout="row wrap">
            <div
                fxFlex="1 1 auto"
                fxFlexOrder="0"
                fxFlexOrder.lt-sm="1"
                fxFlex.lt-sm="100%"
                fxLayout="row"
                fxLayoutAlign="start"
                fxLayoutAlign.lt-sm="center"
                class="copyright"
            >
                <div fxFlex="0 0 300px" fxFlex.lt-sm="100%" fxLayoutAlign.lt-sm="center" class="logo">
                    <img
                        [src]="customerLanding.footerLogotype"
                        style="width: inherit"
                        (click)="redirectTo()"
                        [alt]="customerLanding.title"
                    />
                </div>
                <div
                    fxFlex="0 0 200px"
                    fxShow
                    fxHide.sm="true"
                    *ngIf="showAcademy"
                    fxHide.lt-sm="true"
                    fxLayout="column"
                    class="link-container"
                >
                    <h4>{{ academyLink.title }}</h4>
                    <a *ngFor="let subLink of academyLink.subLinks" [href]="subLink.link"> {{ subLink.title }}</a>
                </div>
            </div>
            <div fxFlex="auto" fxFlexOrder="1" fxFlexOrder.lt-sm="0" fxLayout="row wrap" fxLayoutAlign="start">
                <div fxFlex fxHide fxShow.lt-md="true" *ngIf="showAcademy" fxLayout="column" class="link-container">
                    <h4>{{ academyLink.title }}</h4>
                    <a *ngFor="let subLink of academyLink.subLinks" [href]="subLink.link"> {{ subLink.title }}</a>
                </div>
                <div fxFlex fxShow fxHide.lt-sm="true" fxLayout="column" class="link-container">
                    <h4>Klick Data</h4>
                    <a i18n href="https://klickdata.se/produkter-tj%C3%A4nster/lms">Our Services</a>
                    <a i18n href="https://klickdata.se/english/journal">Journal</a>
                    <a i18n href="https://klickdata.se/english/the-company">Our Story</a>
                    <a
                        i18n
                        href="https://klickdata.se/english/journal/klick-data-awarded-gfel-top-50-organizations-in-education-award"
                        >Awards</a
                    >
                </div>
                <div fxFlex fxLayout="column" class="link-container">
                    <h4 i18n>Support</h4>
                    <a i18n href="https://klickdata.se/omoss/faq-klms">FAQ (Swe)</a>
                    <a i18n href="https://klickdata.se/english/faq">FAQ (Eng)</a>
                    <a i18n href="https://klickdata.se/english/contact-us-for-your-inquiry">Contact Us</a>
                    <a i18n href="https://klickdata.se/faq-klms/terminology-of-klms-what-does-all-terms-in-an-lms-mean"
                        >Terminology</a
                    >
                </div>
                <div fxFlex fxLayout="column" class="link-container">
                    <h4>KLMS</h4>
                    <a i18n href="https://klickdata.se/english">About Klick Data</a>
                    <a i18n href="https://klickdata.se/distributors">Distributors</a>
                    <a i18n href="https://klickdata.se/faq-klms/legal-links-for-klick-data">Legal</a>
                    <a i18n href="https://kunskap.klickdata.se/sv/changelog" *ngIf="version$ | async; let ver"
                        >Version:
                        {{ ver.version.split('-').slice(0, 2).join('-') }}
                    </a>
                </div>
                <div fxFlex fxShow fxHide.lt-lg="true" fxLayout="column" class="link-container">
                    <h4 i18n>Other</h4>
                    <a i18n href="https://klickdata.se/omoss/karri%C3%A4r"> Carrier </a>
                    <a i18n href="https://www.klick-data.com">Arabic</a>
                    <a i18n href="https://klickdata.se/espa%C3%B1ol">Espanol</a>
                    <a i18n href="https://klickdata.se/omoss/investor-relations">Investor relations</a>
                </div>
            </div>
            <div fxFlex="0 0 100%" fxFlexOrder="2" class="footer-content" fxLayoutAlign="center center">
                <span (click)="redirectTo()"
                    >&copy; {{ date | date : 'y' }} KlickData AB (publ), <span i18n> All rights reserved</span>
                </span>
            </div>
        </footer>
    </ng-container>
    <footer *ngIf="!(user$ | async)" class="footer" fxLayout="row wrap">
        <div fxFlex="0 0 260px" fxFlex.lt-md="0 0 40%" fxLayout="row wrap" fxLayoutAlign="center" class="copyright">
            <div fxFlex="0 0 80%" class="logo not-user">
                <img [src]="customerLanding.footerLogotype" (click)="redirectTo()" [alt]="customerLanding.title" />
            </div>
            <p class="footer-info" fxFlex="0 0 100%">
                &copy; {{ date | date : 'y' }} KlickData AB (publ), <span i18n> All rights reserved </span><br />

                <span *ngIf="version$ | async; let ver">
                    {{ ver.version.split('-').slice(0, 2).join('-') }}
                    {{ ver.date }}
                </span>
                <br />
            </p>
        </div>
        <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center">
            <a i18n mat-button href="https://klickdata.se/faq-klms/legal-links-for-klick-data" fxFlex="0 0 auto">
                Privacy Policy
            </a>
        </div>
    </footer>
</ng-container>
