import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MobileService } from '@klickdata/core/mobile';
import { TableSource } from '@klickdata/core/table';
import { MatTableBase } from 'apps/klickdata/src/app/shared/table/mat-table-base';
import { K3FilesListingService } from '../k3-files-listing.service';
import { File } from '@klickdata/core/file';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-files-names-listing',
    templateUrl: './files-names-listing.component.html',
    styleUrls: ['./files-names-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [K3FilesListingService],
})
export class FilesNamesListingComponent extends MatTableBase {
    public dataSource = new TableSource<File>();
    public columns = ['title', 'tools'];
    @Output() onFileClick: EventEmitter<File> = new EventEmitter<File>();

    constructor(
        protected listService: K3FilesListingService,
        protected mobileService: MobileService,
        protected cdr: ChangeDetectorRef,
        protected dialog: MatDialog
    ) {
        super(cdr, mobileService);
    }

    view(file: File) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '60%',
            data: file.id,
        });
    }
    getListingService() {
        return this.listService;
    }
}
