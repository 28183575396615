<ng-container *ngIf="media$ | async; let item; else: loading">
    <div
        *ngIf="proportional"
        class="imagewrapper"
        [ngStyle]="{
            'background-image': 'url(' + getItemImg(item) + ')',
            'padding-bottom': paddingBottom,
            'background-position': item.position
        }"
        [class.circle-img]="isCircularImg"
    >
        <div class="overlay-layer" [ngStyle]="{ 'padding-bottom': paddingBottom }" *ngIf="activateOverLay"></div>
        <img
            #image
            (load)="srcLoaded.emit(image.src)"
            [src]="getItemImg(item)"
            [alt]="item.alt"
            (error)="onError(item); onSrcError.emit(item)"
        />
    </div>
    <div class="unProp-img-wrapper" *ngIf="!proportional">
        <img
            #image
            class="proportional-image"
            (load)="srcLoaded.emit(image.src)"
            [src]="getItemImg(item)"
            [alt]="item.alt"
            (error)="onError(item); onSrcError.emit(item)"
        />
    </div>
</ng-container>
<ng-content></ng-content>

<ng-template #loading>
    <div
        fxLayout="row"
        [ngStyle]="{ 'padding-bottom': paddingBottom }"
        [class.propo-img]="proportional"
        fxLayoutAlign="center center"
        class="spinner-container mt-2"
    >
        <app-kd-logo-loading *ngIf="!smallSpinner"></app-kd-logo-loading>
        <app-kd-logo-loading *ngIf="smallSpinner" size="small"></app-kd-logo-loading>
    </div>
</ng-template>
