<div fxLayout="column" fxFlex="0 0 100%" class="hr-notes-wrapper">
    <button
        (click)="$event.stopPropagation(); addNote()"
        matTooltip="Add New note"
        mat-icon-button
        i18n-matTooltip
        class="note-add"
    >
        <mat-icon>library_add</mat-icon>
    </button>
    <!-- <mat-accordion displayMode="flat">
        <mat-expansion-panel expanded="true" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header> -->
    <app-table-filter
        [cacheScope]="scope"
        [inGlobalFilter]="true"
        color="accent"
        [filtersTogglesInOnBtn]="true"
        #filter
    >
        <app-table-search
            toggleBtnLabel="Note"
            i18n-toggleBtnLabel
            [inGlobalFilter]="true"
            class="align-space"
            searchFilterRef
        ></app-table-search>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="All Time"
            i18n-label
            [property]="GlobalFilterProperty.TIME_SPENT"
            [options]="predefinedTimeSpentOptions"
            fixedTimeSpentFilterRef
        >
        </app-select-filter>
        <app-tag-chip-search-filter
            [inGlobalFilter]="true"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="start"
            tagsFilterRef
        ></app-tag-chip-search-filter>
        <app-select-filter
            *ngIf="!customerId"
            [inGlobalFilter]="true"
            class="filter-space"
            label="Academy Roles"
            i18n-label="@@academyRoles"
            [property]="GlobalFilterProperty.USER_ROLE"
            [options]="userRoleOptions"
            userRolesRef
        >
        </app-select-filter>
    </app-table-filter>
    <!-- </mat-expansion-panel>
    </mat-accordion> -->
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        matSortActive=""
        matSortDirection=""
        matSort
        [class.mobileView]="isMobile"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Notes</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Notes:</span>
                <span class="notes">{{ hrNote.body }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@date">Date</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <!-- <span class="mobile-label" i18n="@@date">Date:</span>
                <span class="time-label">
                    {{ hrNote.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                </span> -->

                <app-date-time-picker-item
                    (click)="$event.stopPropagation()"
                    *ngIf="!hrNote.loading"
                    [value]="hrNote.created_at"
                    (onDateTimeChanged)="updateCreatedAt($event, hrNote)"
                ></app-date-time-picker-item>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Tags</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <app-table-tags-on-the-fly
                    (click)="$event.stopPropagation()"
                    [tags]="hrNote.tags"
                    (onUpdateTags)="hrNote?.id && updateNoteTags(hrNote, $event)"
                    (onTagClick)="onTagClick($event)"
                ></app-table-tags-on-the-fly>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Signature</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Signature:</span>
                <!-- <span class="pointer notes-name" [matTooltip]="hrNote.author?.fname + ' ' + hrNote.author?.lname">
                    {{ hrNote.author?.fname | nameSign }}{{ hrNote.author?.lname | nameSign }}
                </span> -->
                <ng-container *ngIf="allManagersList$ | async; let allManagers">
                    <app-signature-change-on-the-fly
                        (click)="$event.stopPropagation()"
                        (onSelectSignature)="onSelectSignature($event, hrNote)"
                        [manager]="!!hrNote?.managers?.length && hrNote?.managers[0]"
                        [allManagersList]="allManagers"
                    ></app-signature-change-on-the-fly>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="contact_type">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Contact type</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Type:</span>
                <app-item-change-on-the-fly
                    [capitalize]="true"
                    [selectionOptions]="contactTypeOptions"
                    [value]="hrNote.contact_type"
                    (onValueChange)="updateNotContatType(hrNote, $event)"
                ></app-item-change-on-the-fly>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let hrNote">
                <button
                    *ngIf="hrNote.media_ids && hrNote.media_ids.length"
                    (click)="$event.stopPropagation(); showNoteMedia(hrNote)"
                    matTooltip="View attachment"
                    mat-icon-button
                    i18n-matTooltip="@@viewAttachment"
                    class="comment-add"
                >
                    <mat-icon>attachment</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); editNote(hrNote)"
                    matTooltip="Edit"
                    mat-icon-button
                    i18n-matTooltip="@@edit"
                    class="comment-add"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); deleteNote(hrNote)"
                    matTooltip="Delete"
                    mat-icon-button
                    i18n-matTooltip="@@delete"
                    class="comment-add"
                >
                    <mat-icon>delete</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); addComment(hrNote)"
                    matTooltip="Add comments"
                    mat-icon-button
                    i18n-matTooltip="@@addComments"
                    class="comment-add"
                >
                    <mat-icon>maps_ugc</mat-icon>
                </button>
                <button
                    *ngIf="hrNote.comments?.length"
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === hrNote ? null : hrNote"
                    matTooltip="View comments"
                    mat-icon-button
                    i18n-matTooltip="@@viewComments"
                    class="expand-btn"
                >
                    <mat-icon>{{ expandedRow === hrNote ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let hrNote" [attr.colspan]="columns.length">
                <div class="example-element-detail" [@detailExpand]="hrNote == expandedRow ? 'expanded' : 'collapsed'">
                    <ng-container *ngIf="hrNote.comments">
                        <app-shared-hr-notes-comments [comments]="hrNote.comments"></app-shared-hr-notes-comments>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let hrNote; columns: columns"
            [class.example-expanded-row]="expandedRow === hrNote"
            class="example-element-row"
            (click)="!isMobile && hrNote.comments && (expandedRow = expandedRow === hrNote ? null : hrNote)"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <mat-spinner fxFlex="0 0 auto"></mat-spinner>
    </div>
</ng-template>
