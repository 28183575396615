import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { ShareService } from '@klickdata/shared-components/src/share/share.service';
import { BehaviorSubject } from 'rxjs';
import { ResourceCompetenceListService } from './resource-competence-list.service';

@Component({
    selector: 'app-resources-competence-list',
    templateUrl: './resources-competence-list.component.html',
    styleUrls: ['./resources-competence-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceCompetenceListService],
})
export class ResourcesCompetenceListComponent implements OnInit, AfterViewInit {
    @Input() user: User;
    @Input() tableFilterItems: Filter<string | number>[];
    public dataSource = new TableSource<Resource>();
    @Input() columns = ['columns', 'title', 'occasionStatus', 'done', 'last_action'];
    public isMobile: boolean;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    public resourceStatusOptions: StatusFilterOption[];
    public type_scope_ids = [AppScope.MATERIAL, AppScope.TEST, AppScope.COURSE];

    constructor(
        protected resourceService: ResourceCompetenceListService,
        protected mobile: MobileService,
        protected cdRef: ChangeDetectorRef,
        public shareService: ShareService
    ) {
        this.resourceStatusOptions = [
            { title: $localize`Done`, value: 'done', color: '#3e5365', icon: 'done_all', selected: true },
            { title: $localize`Ongoing`, value: 'ongoing', color: '#ff9961', icon: 'cached', selected: true },
            { title: $localize`Deleted`, value: 'deleted', color: '#ff9961', icon: 'delete_forever', selected: true },
            {
                title: $localize`Not Started`,
                value: 'not_started',
                color: '#93cbd1',
                icon: 'remove_done',
                selected: true,
            },
            { title: $localize`Overdue`, value: 'overdue', color: '#a7a7a7', icon: 'event_busy', selected: true },
            { title: $localize`Cancelled`, value: 'cancelled', color: '#e44a66', icon: 'cancel', selected: true },
        ];
    }

    public ngOnInit() {
        this.dataSource.service = this.resourceService;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        const appliedFilters = [];
        if (!!this.tableFilterItems?.length) {
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(new Filter(filterItem.property, filterItem.items));
            });
        }
        this.filter.createOrUpdateWithoutEmitFilter(appliedFilters);
    }
}
