import { ResourceStaffRoles } from './../../../core/resource/src/types.enum';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatRadioChange } from '@angular/material/radio';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Educator } from '@klickdata/core/resource/src/resource.model';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-resource-educator-handler',
    templateUrl: './resource-educator-handler.component.html',
    styleUrls: ['./resource-educator-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ResourceEducatorHandlerComponent),
            multi: true,
        },
    ],
})
export class ResourceEducatorHandlerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    public educatorSearch$: Observable<Educator[]>;
    public educators: Educator[] = [];
    public loading = true;
    public educatorCtrl = new FormControl();
    @Input() resource: Resource;
    @Input() maxNumberOfEducatore: number;
    @Input() setDefault = false;
    @Input() canAddEdu = true;
    @Input() educatorLabel = $localize`educator`;
    @Input() searchPlaceholder = $localize`Search existing educators`;
    @Input() addingLabel = $localize`Add new educator`;
    @Input() setDefaultTooltip = $localize`Set default educator`;
    @Input() mainEducatorTooltip = $localize`Main educator`;
    @Input() educatorRole: ResourceStaffRoles;
    @Input() setPredefinedEducator: boolean;
    @Input() removeBtn = 'cancel';
    @Output() onUpdateEducators: EventEmitter<Educator[]> = new EventEmitter<Educator[]>();
    @Output() onEducatorClick: EventEmitter<Educator> = new EventEmitter<Educator>();
    private destroy = new Subject<boolean>();
    public defaultEdu: Educator;
    @ViewChild('educatorInput') educatorInput: ElementRef<HTMLInputElement>;
    @ViewChild('toggleGroup') matButtonToggleGroup: MatButtonToggleGroup;

    constructor(
        protected resourceService: ResourceService,
        protected cdr: ChangeDetectorRef,
        protected mobileService: MobileService,
        protected message: MessageService
    ) {}

    ngAfterViewInit(): void {
        if (this.setPredefinedEducator && this.educators.length === 0) {
            this.resourceService
                .getDefaultEducatorsByRole(this.educatorRole)
                .pipe(takeUntil(this.destroy))
                .subscribe((educators) => {
                    this.educators = educators;
                    this.cdr.markForCheck();
                    this.notify();
                });
        }
        this.educatorSearch$ = this.educatorCtrl.valueChanges.pipe(
            filter((term) => typeof term === 'string'),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((query) => this.resourceService.getResourceEducators({ query: query, role: this.educatorRole })),
            takeUntil(this.destroy)
        );
    }

    addEdu() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_EDUCATOR,
            data: {
                type: 'addEducator',
                role: this.educatorRole,
                title: $localize`Add new ${this.educatorLabel}`,
                educatorLabel: this.educatorLabel,
                resource_id: this.resource?.id,
                positiveBtnText: $localize`Add ${this.educatorLabel}`,
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === 'addEducator'),
                takeUntil(this.destroy),
                map((data) => data.value)
            )
            .subscribe((educator: Educator) => {
                this.addEducator(educator);
            });
    }
    showEduInfo(edu: Educator) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.RESOURCE_MANAGERS,
            data: {
                title: edu.name,
                managers: [edu],
            },
        });
    }

    public selected(auto: MatAutocomplete, event: MatAutocompleteSelectedEvent): void {
        const educator = event.option.value;
        if (this.educators.some((edu) => edu.id === educator.id)) {
            this.message.openMessage(MessageErrorComponent, $localize`Already exist`);
            this.educatorInput.nativeElement.value = '';
            this.educatorCtrl.setValue('');
            auto.options.reset([]);
            return;
        }
        this.educatorInput.nativeElement.value = '';
        this.educatorCtrl.setValue('');
        auto.options.reset([]);
        this.addEducator(educator);
    }

    private addEducator(educator: Educator) {
        const index = this.educators.findIndex((edu) => edu.id === educator.id);
        if (index !== -1) {
            this.educators.splice(index, 1, educator);
        } else {
            this.educators.push(educator);
        }
        this.notify();
        this.cdr.markForCheck();
    }

    setDefaultEdu(ev: MatRadioChange) {
        this.defaultEdu = ev.value;
        this.notify();
        this.cdr.markForCheck();
    }
    removeIns(educator: Educator) {
        if (this.educators.length === 1 && this.setDefault) {
            this.message.openMessage(MessageErrorComponent, $localize`You need at least one educator.`);
            return;
        }
        const index = this.educators.findIndex((edu) => edu.id === educator.id);
        if (index !== -1) {
            this.educators.splice(index, 1);
            this.defaultEdu = this.educators[0];
            this.notify();
        }
    }

    writeValue(educatorsIds: any): void {
        if (educatorsIds && educatorsIds.length) {
            this.resourceService
                .getResourceEducators({ ids: educatorsIds })
                .pipe(takeUntil(this.destroy))
                .subscribe((educators) => {
                    this.educators = educators;
                    this.onUpdateEducators.emit(this.educators);
                    this.defaultEdu = educators[0];
                    this.cdr.markForCheck();
                });
        }
    }
    private notify() {
        const educatorsIds = this.educators.map((educator) => educator.id);
        this.onUpdateEducators.emit(this.educators);
        if (this.setDefault && !!this.defaultEdu.id) {
            educatorsIds.splice(educatorsIds.indexOf(this.defaultEdu.id), 1);
        }

        this.propagateChange(
            this.setDefault && !!this.defaultEdu.id ? [this.defaultEdu.id, ...educatorsIds] : educatorsIds
        );
    }
    propagateChange = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
