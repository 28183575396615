import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-custom-fields-input',
    templateUrl: './custom-fields-input.component.html',
    styleUrls: ['./custom-fields-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsInputComponent {
    @Input() customFieldForm: FormGroup;
    constructor() {}
}
