import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { AbstractFormGroup, AfterParentAttached, FormGeneratorService, FormHelper } from '@klickdata/core/form';
import { PublishMessage, PublishMessageData, PublishMessageService, Resource } from '@klickdata/core/resource';
import * as moment from 'moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { finalize, first, map, takeUntil } from 'rxjs/operators';
import { CoursePlanForm } from '../course-plan.form';

@Injectable()
export class PublishForm extends AbstractFormGroup<PublishMessage> implements AfterParentAttached, OnDestroy {
    public onParentUpdated: Subject<Resource> = new Subject<Resource>();
    constructor(
        protected generator: FormGeneratorService,
        protected auth: AuthService,
        protected publishService: PublishMessageService
    ) {
        super(PublishMessage, generator);
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public create(data: Resource): Observable<PublishMessage> {
        return combineLatest([
            this.auth.getUser(),
            data.assign_id ? this.publishService.getAssignedPublishMessages(data.assign_id) : of([]),
        ]).pipe(
            first(),
            map(([user, messages]) => {
                if (messages?.length) {
                    const message = messages[0];
                    /**
                     * When no assign_id => creation case use last publish message as template and drop id.
                     * And create new publish message for new assign relation.
                     */
                    if (!data.assign_id) {
                        message.id = null;
                    }
                    this.import(message);
                } else {
                    this.initDatePeriod();
                    const subject = $localize`Send Course invitation`;
                    this.model.author_id = user.id;
                    this.model.customer_id = user.customer_id;
                    // this.model.recipients_ids = [];
                    this.model.delay = false;
                    // this.model.date_range = { begin: moment(), end: moment().add(1, 'M') };
                    this.model.subject = `${subject} ${data.title}`;
                    this.model.body = `Hi ${'«first-name»'}, <br/> This is the link to ${
                        data.title
                    } <br/> ${'«access-link»'}`;
                    this.form.reset(this.model);
                }
                this.form.patchValue({ resource_id: data.id });
                FormHelper.resetForm(this.form);
                return this.model;
            }),
            takeUntil(this.destroy)
        );
    }

    public import(data: PublishMessage): void {
        this.model = new PublishMessage(data.getData());
        if (!(this.model.start_date && this.model.end_date)) {
            this.initDatePeriod();
        }
        this.form.patchValue(this.model);
    }

    private initDatePeriod() {
        this.model.start_date = moment().hour(0).minute(0).second(0);
        this.model.end_date = moment().hour(23).minute(59).second(0).add(1, 'M');
    }

    public export(): PublishMessageData {
        this.commit();
        return new PublishMessage({ ...this.model, ...this.form.value }).getData();
    }

    public submit(): Observable<PublishMessage> {
        return this.publishService.store(this.export()).pipe(
            finalize(() => {
                FormHelper.resetForm(this.form);
            })
        );
    }

    public afterParentAttached(): void {
        const parent = this.parent as CoursePlanForm;
        // Subscribe for updates
        parent.modelUpdate.pipe(takeUntil(this.destroy)).subscribe((coursePlan) => {
            this.onParentUpdated.next(coursePlan);
        });
    }
}
