import { AfterViewInit, ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    TableFilterComponent,
    TableHttpService,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { TableFilterExpansionPanelComponent } from '@klickdata/shared-components/src/table-filter-expansion-panel/table-filter-expansion-panel.component';
import { Subject, shareReplay, takeUntil } from 'rxjs';
@Directive()
export abstract class MatTableBase implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    @ViewChild('filterExpansion') filterExpansion: TableFilterExpansionPanelComponent;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public destroy: Subject<boolean> = new Subject<boolean>();
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    @Input() abstract columns: string[];
    // Filters which will not removed at all
    @Input() public fixedFilters: Filter<string | number>[] = [];
    @Input() authUser: User;
    public filtersTogglesInOnBtn = true;
    public dataSource = new TableSource<any>();
    public isMobile: boolean;

    constructor(protected cdr: ChangeDetectorRef, protected mobileService: MobileService) {}
    ngOnInit(): void {
        this.mobileService
            .isMobile()
            .pipe(shareReplay(), takeUntil(this.destroy))
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdr.markForCheck();
            });
        this.dataSource.service = this.getListingService();
    }
    protected abstract getListingService(): TableHttpService<any>;
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        /**
         * TODO Handle when table has language filter which will emit and no need for refresh
         */
        if (!this.fixedFilters.length) {
            this.dataSource.refresh();
        } else {
            this.filter.createOrUpdateFilter(this.fixedFilters);
        }
    }

    updateSort(sort: Sort): void {
        this.sort.sort({ id: sort.active, start: sort.direction, disableClear: false });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
