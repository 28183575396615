import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupData, GroupService } from '@klickdata/core/group';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Observable, of, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-create-group',
    templateUrl: './menu-side-create-group.component.html',
    styleUrls: ['./menu-side-create-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCreateGroupComponent extends OnDestroyHandler {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public groupForm: FormGroup;
    public loading$: Observable<boolean>;

    constructor(protected fb: FormBuilder, protected groupService: GroupService) {
        super();
        this.buildForm();
    }
    buildForm() {
        this.groupForm = this.fb.group({
            name: ['', Validators.required],
            expiration: [null],
        });
    }

    public submit(editGroup?: boolean): void {
        this.loading$ = of(true);
        this.groupService
            .create(this.prepareGroupData())
            .pipe(takeUntil(this.destroy))
            .subscribe((group) => {
                this.loading$ = of(false);
                this.groupForm.reset();
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: {
                        group: group,
                        editGroup: editGroup,
                        type: this.navData.data.type,
                    },
                });
            });
    }
    private prepareGroupData(): GroupData {
        const data = this.groupForm.value;
        data.customer_id = this.navData.data.customerId;
        if (!!this.navData.data.userIds?.length) {
            data.users_attach = this.navData.data.userIds;
        }
        return data;
    }
}
