import { ChangeDetectionStrategy, Component, Host, Input, OnInit, Optional } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';

@Component({
    selector: 'app-inline-edit',
    templateUrl: './inline-edit.component.html',
    styleUrls: ['./inline-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineEditComponent implements OnInit {
    @Input() title: string;
    @Input() value: string | number;
    @Input() type: 'number' | 'text' | 'email';
    @Input() placeholder: string;
    @Input() min = 0;
    @Input() step = 1;
    @Input() max = 10000;
    constructor(@Optional() @Host() public popover: SatPopover) {}

    ngOnInit(): void {}

    onSubmit() {
        if (this.popover) {
            this.popover.close(this.value);
        }
    }

    onCancel() {
        if (this.popover) {
            this.popover.close();
        }
    }
}
