<div class="mat-subheading-2">{{ title }}</div>

<mat-form-field>
    <input *ngIf="type !== 'number'" matInput [type]="type" [placeholder]="placeholder" [(ngModel)]="value"/>
    <input
        *ngIf="type === 'number'"
        matInput
        type="number"
        [placeholder]="placeholder"
        [min]="min"
        [step]="step"
        [max]="max"
        [(ngModel)]="value"
/></mat-form-field>

<div class="actions">
    <button mat-button type="button" color="accent" (click)="onCancel()" i18n="@@cancel">Cancel</button>
    <button mat-raised-button type="submit" (click)="onSubmit()" color="primary" i18n="@@save">Save</button>
</div>
